import { BehaviorSubject, Observable, firstValueFrom, lastValueFrom, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { ITenantInformationDTO } from '@app/core/models/tenantInformationDTO.model';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { OptinexUserDTO } from './user.service';
import { Parameter } from '@app/core/models/parameter.model';
import { environment } from '../../../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserQueryService extends BehaviorSubject<Array<OptinexUserDTO>>{

  loading = true;

  constructor(private http: HttpClient) {
    super([]);
  }

  public async query(): Promise<void> {

    this.loading = true;

    var users = await firstValueFrom(this.http.get<Array<OptinexUserDTO>>(`${environment.apiUrl}User/query`));

    for(let  u of users){
     u.changeDate = new Date(u.changeDate);
    }


    this.loading = false;

    super.next(users);
  }
}

<div class="k-w-full k-d-flex k-flex-col">
<app-gluing-plans [isOperator]="isOperator"></app-gluing-plans>
</div>
<div class="section-header k-d-flex">
  <h3 translate>GluePlan.GlueSets</h3>
</div>
<div class="glue-set-container">
<app-glue-set-view class="k-d-flex"
[gluesets]="glusets"
[cols]="6"
[isOperator]="true"
></app-glue-set-view>
</div>

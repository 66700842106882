import { HttpClient } from '@angular/common/http';
import { IPlaneProfile } from '@app/core/models/plane-profile';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})

export class PlaneProfileService {
  constructor(private readonly http: HttpClient
  ) { }

  query(): Observable<IPlaneProfile[]> {
    return this.http.get<IPlaneProfile[]>(`${environment.apiUrl}PlaneProfile`);
  }
}

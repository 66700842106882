import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { UserService, UserSiteDTO } from '@app/core/services/http-services/common/user.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('App.Site');

@Component({
  selector: 'app-site-selector',
  templateUrl: './site-selector.component.html',
  styleUrls: ['./site-selector.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SiteSelectorComponent,
      multi: true
    }
  ]
})
export class SiteSelectorComponent implements OnInit, ControlValueAccessor {
  @Input() label:string = 'App.Site';
  @Input() showLable:boolean = false;
  @Output() onSiteSelected = new EventEmitter();
  @Input() disableSite:boolean = false;
  private onChanged!: Function;
  private onTouched!: Function;

  selectedSiteId: string;
  sites: UserSiteDTO[] = [];
  loading:boolean = true;

  constructor(private localStorageService: LocalStorageService
    , private userService: UserService) { }

  ngOnInit(): void {
    this.setSites();
    this.get();
  }

  ngAfterViewInit(): void {
    this.get();
  }

  writeValue(selectedSiteId: string): void {
    this.selectedSiteId = selectedSiteId;
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  get() {
    const selectedSite = this.getSelectedSite();
    if (!this.sites.some(x => x.siteId === selectedSite)) {
      const defaultSite = this.userService.getDefaultSite(this.sites);
      this.onChangedVal(defaultSite);
    } else {
      this.setSelectedSite(selectedSite);
    }
  }

  private setSites() {
    this.sites = this.getSites();
    this.loading = false;
  }

  private getSites() : UserSiteDTO[] {
    return JSON.parse(this.localStorageService.getItem(LocalStorageService.userSites)) as UserSiteDTO[];
  }

  private getSelectedSite() : string {
    return this.localStorageService.getItem(LocalStorageService.selectedSiteId);
  }

  onChangedVal(selectedSite: UserSiteDTO) {
    this.localStorageService.setItem(LocalStorageService.selectedSiteId, selectedSite.siteId);
    this.setSelectedSite(selectedSite.siteId);
    this.onSiteSelected.emit();
  }

  private setSelectedSite(siteId: string) {
    this.selectedSiteId = siteId;
    this.onTouched(siteId);
    this.onChanged(siteId);
  }
}

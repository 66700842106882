import { Component, OnInit, ViewChild } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { DataStateChangeEvent, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { RegexHelper } from '@app/shared/helpers/regex-helper';
import { CommonHelper } from '@app/shared/helpers/common-helper';
import { ScheduleService } from '@app/core/services/http-services/operative/schedule.service';
import { ShiftService } from '@app/core/services/http-services/operative/shift.service';
import { IScheduleDTO } from '@app/core/models/scheduleDTO.model';
import { IScheduleLineDTO } from '@app/core/models/scheduleLineDTO.model';
import { IShiftDTO, IshiftSearchCriteriaDTO } from '@app/core/models/shift';
import { ICalendarLinesCriteriaInputDTO } from '@app/core/models/CalendarLinesCriteriaInputDTO';
import { DateHelper } from '@app/shared/helpers/date-helper';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { CreateShiftForm } from '@app/core/models/forms/operative/shift/create-shift-form.model';
import { SiteService } from '@app/core/services/http-services/app/site.service';


marker('Shift.SaveShiftMsg');
marker('Shift.ShiftNameAlreadyExistsMsg');
marker('Shift.UpdateShiftMsg');
marker('Shift.ShiftDeleteMsg');

@Component({
  selector: 'shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.css']
})
export class ShiftComponent implements OnInit {
  public schedules:IScheduleDTO[] = [];
  selectedStockLocationText:string;
  shifts : GridDataResult;
  notAppliedText:string;
  public rowIndexN:any;
  itemToRemove: IShiftDTO;
  public isNew: boolean = false;
  public loadshiftdata: boolean = false;
  state: State = {
    skip: 0,
    take: 30,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  shiftSearchCriteriaDTO: IshiftSearchCriteriaDTO = { machines: [] };
  gridShiftData : GridDataResult;

  public formGroup: FormGroup;
  private editedRowIndex: number;

  isEdit:boolean = false;
  disableSite:boolean;

  @ViewChild('shiftEditorGrid')
  private shiftEditorGrid: GridComponent;

  constructor(private shiftService: ShiftService
    , private notificationService: AppNotificationService
    , private spinner: NgxSpinnerService
    , private translateService: TranslateService
    , private scheduleService: ScheduleService
    , private siteService: SiteService
  ) {  }

  ngOnInit(): void {
    this.setDefaultTranslation();
    this.loadShedulers();
    this.loadData();
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.loadData();
  }

  loadData() {
    this.loadshiftdata = true;
    this.shiftService.getshifts(this.state, this.shiftSearchCriteriaDTO)
      .subscribe({
        next: async (d) => {
          this.shifts = await this.siteService.mapSiteIdsToNames(d);
          this.shifts.data.forEach(element => {
            element.machineGroup = element.shiftAllocations.length === 0 ? this.notAppliedText : element.shiftAllocations.map(a => a.machineGroupName).join(',');
          });
          this.loadshiftdata = false;
        },
        error: () => {
          this.loadshiftdata = false;
        }
      });
  }

  loadShedulers() {
    this.shiftService.getshchedules().subscribe(d => {
      this.schedules = d;
    });
  }

  editHandler({ sender, rowIndex, dataItem }) {
    this.formGroup = this.createShiftForm(dataItem);
    this.disableSite = this.formGroup.get('machineGroup').value !== this.notAppliedText
    sender.editRow(rowIndex, this.formGroup);
  }

  cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  saveHandler({ sender, rowIndex, formGroup, isNew, dataItem }): void {
    this.spinner.show();
    const formData = formGroup.value;
    const shiftdata:IShiftDTO = <IShiftDTO> {
      siteId: formData.siteId,
      shiftId: formData.shiftId,
      scheduleId: formData.scheduleId,
      name: formData.name,
      fte: CommonHelper.replaceCommawithDot(formData.fte),
      code: formData.code,
      overtime: formData.overtime,
      validFrom: DateHelper.convertDateToString(formData.validFrom),
      validTo: DateHelper.convertDateToString(formData.validTo),
      externalReference: formData.externalReference
    };

    if (isNew) {
      this.shiftService.insert(shiftdata).subscribe({
        next: (m) => {
          this.shifts.data.push(m);
          sender.closeRow(rowIndex);
          this.loadData();
          this.notificationService.notifySucsessAppChanel('Shift.SaveShiftMsg');
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        }
      });
    } else {
      this.shiftService.update(shiftdata).subscribe({
        next: () => {
          dataItem.shiftId = shiftdata.shiftId;
          dataItem.scheduleId = shiftdata.scheduleId;
          dataItem.name = shiftdata.name;
          dataItem.fte = shiftdata.fte;
          dataItem.code = shiftdata.code;
          dataItem.overtime = shiftdata.overtime;
          dataItem.validFrom = shiftdata.validFrom;
          dataItem.validTo = shiftdata.validTo;
          dataItem.externalReference = shiftdata.externalReference;

          sender.closeRow(rowIndex);
          this.notificationService.notifySucsessAppChanel('Shift.UpdateShiftMsg');
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        }
      });
    }
  }

  removeHandler({ dataItem, isNew, rowIndex }): void {
    this.itemToRemove = dataItem;
    this.rowIndexN = rowIndex;
  }

  confirmRemove(isRemove:boolean) {
    if (isRemove) {
      this.shiftService.remove(this.itemToRemove).subscribe(() => {
        this.shifts.data.splice(this.rowIndexN, 1);
        this.loadData();
        this.notificationService.notifyInfoAppChanel('Shift.ShiftDeleteMsg');
      });
    }
    this.itemToRemove = null;
  }

  openCalender(scheduleLines: IScheduleLineDTO[], shiftId:number, validFrom:string, validTo:string) {
    scheduleLines.forEach(scheduleLine => {
      scheduleLine.validFrom = validFrom;
      scheduleLine.validTo = validTo;
    });
    const criteria = <ICalendarLinesCriteriaInputDTO> {
      scheduleLines,
      shiftIds: [shiftId]
    };
    this.scheduleService.showCalender(criteria, 'App.ShiftCalendar');
  };

  addShiftRow() {
    this.isEdit = false;
    this.disableSite = false;
    this.closeEditor(this.shiftEditorGrid);
    this.formGroup = this.createShiftForm(<IShiftDTO> {
      shiftId: 0,
      name: '',
      scheduleId: 0,
      fte: 0,
      validFrom: new Date().toString(),
      validTo: new Date().toString(),
      overtime: false,
      externalReference: '',
      code: ''
    });
    this.shiftEditorGrid.addRow(this.formGroup);
  }

  private setDefaultTranslation() {
    this.translateService.get('Shift.NotApplied').subscribe(d => {
      this.notAppliedText = d;
    });
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  private createShiftForm = (p:IShiftDTO) => new FormGroup<CreateShiftForm>({
    siteId: new FormControl<Guid>(p.siteId, [Validators.required]),
    shiftId: new FormControl<number>(p.shiftId),
    scheduleId: new FormControl<number>(p.scheduleId, [Validators.required]),
    name: new FormControl<string>(p.name, [Validators.required]),
    fte: new FormControl<number>(p.fte, Validators.compose([Validators.required, Validators.pattern(RegexHelper.positiveNegativeWithDotComma)])),
    machineGroup: new FormControl<string>(p.machineGroup),
    code: new FormControl<string>(p.code, [Validators.required]),
    overtime: new FormControl<boolean>(p.overtime),
    validFrom: new FormControl<Date>(p.validFrom ? new Date(p.validFrom) : new Date()),
    validTo: new FormControl<Date>(p.validTo ? new Date(p.validTo) : new Date()),
    externalReference: new FormControl<string>(p.externalReference)
  });
}

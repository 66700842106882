
<h1>{{ 'UserAdmin.Users' | translate }}</h1>
<div class="row" style="margin-bottom: 10px;">
    <button kendoButton (click)="heal()" themeColor="primary" [svgIcon]="heartIcon"></button>
</div>
<kendo-grid
    [data]="users | async"
    [loading]="userQueryService.loading"
    [pageable]="false"
    size="small"
    (edit)="onEdit($event)"
    (remove)="onRemove($event)"
>
    <kendo-grid-column
        field="userId"
        title="{{'UserAdmin.UserId' | translate }}"
        readonly="true"
        [width]="200"
    >
    </kendo-grid-column>
    <kendo-grid-column
        field="userName"
        title="{{'UserAdmin.Name' | translate }}"
        [width]="200"
    >
    </kendo-grid-column>
    <kendo-grid-column
        field="email"
        title="{{'UserAdmin.Email' | translate }}"
        [width]="300"
    ></kendo-grid-column>
    <kendo-grid-column
        field="languageCode"
        title="{{'UserAdmin.Language' | translate }}"
        [width]="150"
    ></kendo-grid-column>
    <kendo-grid-column
        field="entraLanguage"
        title="{{'UserAdmin.EntraLanguageCode' | translate }}"
        [width]="150"
    ></kendo-grid-column>
    <kendo-grid-column
        field="changeDate"
        format="g"
        title="{{'UserAdmin.ChangeDate' | translate }}"
        [width]="300"
    ></kendo-grid-column>
    <kendo-grid-command-column [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button kendoGridEditCommand [disabled]="dataItem.userId" themeColor="primary" [svgIcon]="plusIcon"></button>
        <button kendoGridEditCommand [disabled]="!dataItem.userId" themeColor="primary" [svgIcon]="pencilIcon"></button>
        <button kendoButton (click)="clearUserSettings(dataItem)" [disabled]="!dataItem.userId" themeColor="error" [svgIcon]="codeIcon"></button>
        <button kendoGridRemoveCommand themeColor="error" [svgIcon]="trashIcon"></button>
     </ng-template>
    </kendo-grid-command-column>
</kendo-grid>

<app-confirmation-dialog
    *ngIf="userToRevokeTennatAcsess"
    [msg]="'Revoke user acsess for: ' + userToRevokeTennatAcsess.email + '('+ userToRevokeTennatAcsess.userId + ')'"
    (conformationDialogCloseEvent)="onRevokeTennatAcsess()">
</app-confirmation-dialog>

import { HttpClient, HttpParams } from '@angular/common/http';
import { DateHelper } from '@app/shared/helpers/date-helper';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Injectable } from '@angular/core';
import { KendoGridService } from '@app/core/services/custom-services/kendo-grid.service';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { UnitConversionService } from '@app/core/services/custom-services/unit-conversion.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IBeamMaterialGlueSet } from '@app/core/services/http-services/gluelam/glue-set.service';
import { IProdOrderBatchDTO } from '@app/core/models/prod-order-batch.model';
import { IProdOrderDTO, IProdOrderStateDTO, IProdOrderLineDTO, IProdOrderDeleteResponseDTO, ProdOrderSequenceReturnDTO, CreateChainProdOrderInput, ProductActivityDetails, IProdOrderLinkedToGluesetDTO, IProdOrderLineReportedQuantityDTO } from '@app/core/models/prod-order';
import { EntityRelationDirection, IEntity, IEntityRelation } from '@app/core/services/http-services/model/entity.service';
import { NewProdOrderInputDTO } from '@app/core/models/new-prod-order-input-dto';
import { ProdorderBulkEditComponent } from '@app/modules/operative/controls/prodorder-bulk-edit/prodorder-bulk-edit.component';
import { CreateMultiStepData, CreateMultiStepProdOrderComponent } from '@app/modules/operative/controls/create-multi-step-prod-order/create-multi-step-prod-order.component';
import { Guid } from 'guid-typescript';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { Router } from '@angular/router';

marker('Operative.ProdOrderSchedulerDateOverlappingError');

@Injectable({
  providedIn: 'root'
})
export class ProdOrderService {
  constructor(private http: HttpClient,
    private readonly kendoGridService: KendoGridService,
    private readonly unitConversionService: UnitConversionService,
    private dialogService: DialogService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router) {
  }

  query(state: State): Observable<GridDataResult> {
    const stateFilter = this.kendoGridService.getStateFilter(state);

    let params = new HttpParams()
      .set('skip', stateFilter.skip.toString())
      .set('take', stateFilter.take.toString());

    stateFilter.filter.forEach(element => {
      if (element.code === 'stateId') {
        const stateIds = element.value.split(',');
        stateIds.forEach(stateId => {
          params = params.append('stateId', stateId);
        });
      } else if (element.code === 'machineId') {
        const machineIds = element.value.split(',');
        machineIds.forEach(machineId => {
          params = params.append('machineIds', machineId);
        });
      } else if (element.code === 'startDate') {
        params = params.append('plannedExecutionStartDate', DateHelper.fromDateISOFormat(new Date(element.value)));
      } else if (element.code === 'endDate') {
        params = params.append('plannedExecutionEndDate', DateHelper.toDateISOFormat(new Date(element.value)));
      } else {
        params = params.append(element.code, element.value);
      }
    });

    if (stateFilter.sort) {
      params = params.append('sortColumn', stateFilter.sort.sortColumn);
      params = params.append('sortDirection', stateFilter.sort.sortDirection);
    };

    params = params.append('siteId', this.localStorageService.getItem(LocalStorageService.selectedSiteId));

    return this.http
      .get<GridDataResult>(`${environment.apiUrl}ProdOrder/getProdOrders`, { params });
  }

  getProdOrder(id: number): Observable<IProdOrderDTO> {
    return this.http.get<IProdOrderDTO>(`${environment.apiUrl}ProdOrder/${id}`);
  }

  getPOLinesWithReportedQty(prodOrderId: number): Observable<IProdOrderLineReportedQuantityDTO[]> {
    return this.http.get<IProdOrderLineReportedQuantityDTO[]>(`${environment.apiUrl}ProdOrderLine/getPOLines/${prodOrderId}`);
  }

  getProdOrderStates(prodOrderId: number = null, isNew = false): Observable<IProdOrderStateDTO[]> {
    let params = new HttpParams();

    if (prodOrderId) {
      params = params.append('prodOrderId', prodOrderId);
    }

    if (isNew) {
      params = params.append('isNew', true);
    }

    return this.http.get<IProdOrderStateDTO[]>(`${environment.apiUrl}ProdOrder/getProdOrderStates`, { params });
  }

  createProdOrder(machineId: number, activityIndex: number, productIndex: number, startDateTime: string, endDateTime: string, quantity: number, duration: number, isEmptyStock: boolean, instruction: string, siteId: Guid): Observable<IProdOrderDTO> {
    const params = new HttpParams().set('machineId', machineId.toString())
      .set('activityIndex', activityIndex.toString())
      .set('productIndex', productIndex.toString())
      .set('startDateTime', new Date(startDateTime).toISOString())
      .set('endDateTime', new Date(endDateTime).toISOString())
      .set('quantity', quantity.toString())
      .set('duration', duration.toString())
      .set('isEmptyStock', isEmptyStock)
      .set('instruction', instruction)
      .set('siteId', siteId.toString());

    return this.http.get<IProdOrderDTO>(`${environment.apiUrl}ProdOrder/create`, { params });
  }

  copyProdOrder(copyPOId: number): Observable<IProdOrderDTO> {
    return this.http.get<IProdOrderDTO>(`${environment.apiUrl}ProdOrder/copy/${copyPOId}`);
  }

  // This function is for prod order detail page to calculate Qty of each ProdOrder line.
  // This method will call when Main ProdOrderLine qty changes and ActivityRelationLocked = True
  calculateProdOrderLineQuantity(inputQty: number, prodOrderLines: IProdOrderLineDTO[]): IProdOrderLineDTO[] {
    const isMainProdOrderLineRel = prodOrderLines.find(x => x.mainReportingLine);
    inputQty = ((inputQty < 0) ? (-1 * inputQty) : inputQty);
    if (isMainProdOrderLineRel.material.baseUnitCode !== isMainProdOrderLineRel.planedQtyUOM) {
      inputQty = this.getConvertedInput(isMainProdOrderLineRel.material, isMainProdOrderLineRel.material.baseUnitCode, isMainProdOrderLineRel.planedQtyUOM, inputQty);
    }
    // Note - Same logic is used to calculate Qty  of each ProdOrder line while Creating ProdOrder deatail on ProdOrder.cs constructor
    const activityFactor = ((isMainProdOrderLineRel.direction === EntityRelationDirection.CONSUMES ? -1 : 1) * (inputQty / isMainProdOrderLineRel.materialRelationSum));
    prodOrderLines.forEach(d => {
      if (d.prodOrderLineActivityRel.length > 0 && !d.mainReportingLine) {
        d.planedQty = Number((activityFactor * d.materialRelationSum).toFixed(4));
      }
    });
    // --

    return prodOrderLines;
  }

  calculateDuration(activityIndex: number, materialIndex: number, qty: number): Observable<number> {
    const params = new HttpParams()
      .set('activityIndex', activityIndex.toString())
      .set('materialIndex', materialIndex.toString())
      .set('qty', (qty ?? '0').toString());

    return this.http.get<number>(`${environment.apiUrl}ProdOrder/calculateDuration`, { params });
  }

  get(mashineId: number, skip: number = 0, take: number = 20) {
    const params = new HttpParams().set('mashineId', mashineId.toString())
      .set('skip', skip.toString())
      .set('take', take.toString());

    return this.http.get<GridDataResult>(`${environment.apiUrl}ProdOrder`, { params });
  }

  saveProdOrder(prodOrder: IProdOrderDTO): Observable<number> {
    return this.http.post<number>(`${environment.apiUrl}ProdOrder`, prodOrder);
  }

  updateProdOrder(prodOrder: IProdOrderDTO): Observable<boolean> {
    return this.http.put<boolean>(`${environment.apiUrl}ProdOrder`, prodOrder);
  }

  updateProdOrderOnStateChange(prodOrder: IProdOrderDTO): Observable<boolean> {
    return this.http.put<boolean>(`${environment.apiUrl}ProdOrder/UpdateProdOrderState`, prodOrder);
  }

  rescheduleProdOrder(prodOrder: IProdOrderDTO): Observable<number[]> {
    return this.http.put<number[]>(`${environment.apiUrl}ProdOrder/rescheduleProdOrder`, prodOrder);
  }

  removeProdOrder(prodOrderId: number, removeAllInSequence: boolean): Observable<IProdOrderDeleteResponseDTO> {
    return this.http.delete<IProdOrderDeleteResponseDTO>(`${environment.apiUrl}ProdOrder/${prodOrderId}`, {
      params: (new HttpParams()).set('removeAllInSequence', removeAllInSequence)
    });
  }

  getProdOrderEventDetails(states: State, prodOrderId: number): Observable<GridDataResult> {
    const stateFilter = this.kendoGridService.getStateFilter(states);

    let params = new HttpParams()
      .set('prodOrderID', prodOrderId)
      .set('skip', stateFilter.skip.toString())
      .set('take', stateFilter.take.toString());

    stateFilter.filter.forEach(element => {
      if (element.code === 'eventDate') {
        params = params.append(element.code, DateHelper.toDateISOFormat(new Date(element.value)));
      } else {
        params = params.append(element.code, element.value);
      }
    });

    return this.http.get<GridDataResult>(`${environment.apiUrl}ProdOrder/GetProdOrderEventDetails`, { params });
  }

  validateQuantityAsPerDirection(qty: number, direction: number): number {
    if ((direction === EntityRelationDirection.CONSUMES && qty > 0) ||
      (direction === EntityRelationDirection.PRODUCE && qty < 0)) {
      return qty * -1;
    }
    return qty;
  }

  getGluesetByProdOrderId(id: number): Observable<IBeamMaterialGlueSet> {
    return this.http.get<IBeamMaterialGlueSet>(`${environment.apiUrl}ProdOrder/GetGluesetByProdOrderId/${id}`);
  }

  checkGluesetByProdOrderId(id: number): Observable<number> {
    return this.http.get<number>(`${environment.apiUrl}ProdOrder/CheckGluesetByProdOrderId/${id}`);
  }

  getProdOrderSequence(sequenceId: string): Observable<ProdOrderSequenceReturnDTO> {
    return this.http.get<ProdOrderSequenceReturnDTO>(`${environment.apiUrl}ProdOrder/getProdOrderDemandDetail/${sequenceId}`);
  }

  getProdOrdersLinkedToGlueset(gluesetId: number): Observable<IProdOrderLinkedToGluesetDTO[]> {
    return this.http.get<IProdOrderLinkedToGluesetDTO[]>(`${environment.apiUrl}ProdOrder/getProdOrdersLinkedToGlueset/${gluesetId}`);
  }

  static GetActivityText(line: IProdOrderLineDTO): string {
    return (line?.prodOrderLineActivityRel !== undefined) ? line?.prodOrderLineActivityRel[0]?.activity?.description : '';
  }

  // this function is used to show planned qty in prodorder create popup
  // This function is same as we used in prod order detail page to calculate Qty of each ProdOrder line but here we used entityrelation
  // instead of prodorderlines.
  calculateEntityRelationQuantity(inputQty: number, entityrelations: IEntityRelation[], product: IEntity, updatedUOM: string = null): IEntityRelation[] {
    const isMainProdOrderLineRel = this.getMainPOLine(entityrelations, product);
    const convertedInputQty = this.getQtyOnBaseQtyChange(inputQty, entityrelations, product, updatedUOM);
    const activityFactor = ((isMainProdOrderLineRel.direction === EntityRelationDirection.CONSUMES ? -1 : 1) * (convertedInputQty / isMainProdOrderLineRel.relation));
    return this.getEntityRelations(entityrelations, activityFactor);
  }

  getQtyOnBaseQtyChange(inputQty: number, entityrelations: IEntityRelation[], product: IEntity, updatedUOM: string = null): number {
    const isMainProdOrderLineRel = this.getMainPOLine(entityrelations, product);
    let convertedInputQty = 0;
    inputQty = ((inputQty < 0) ? (-1 * inputQty) : inputQty);
    if (updatedUOM != null) {
      convertedInputQty = this.getConvertedInput(product, isMainProdOrderLineRel.unitCode, updatedUOM, inputQty);
    } else {
      convertedInputQty = this.getConvertedInput(product, isMainProdOrderLineRel.unitCode, product.baseUnitCode, inputQty);
    }
    return convertedInputQty;
  }

  getPOShiftStartTime(machineId: number, date: Date): Observable<string> {
    const params = new HttpParams()
      .set('machineId', machineId)
      .set('date', date.toDateString());
    return this.http.get<string>(`${environment.apiUrl}ProdOrder/getPOShiftStartTime`, { params });
  }

  getConvertedInput(product: IEntity, fromUOM: string, toUOM: string, inputQty: number) {
    return this.unitConversionService.qtyConversionByUOM(product
      , fromUOM
      , toUOM
      , inputQty
      , inputQty);
  }

  getEntityRelations(entityrelations: IEntityRelation[], activityFactor: number): IEntityRelation[] {
    entityrelations.forEach(d => {
      if (d.glulamSpec === null || d.glulamSpec.length === 0) {
        d.plannedQty = Number((activityFactor * d.relation).toFixed(1));
      } else {
        let sumlamellas: number = 0;
        d.glulamSpec.forEach(x => {
          sumlamellas += x.numberOfLammellas;
        });
        d.plannedQty = Number((activityFactor * sumlamellas * -1).toFixed(1));
      }
    });
    return entityrelations;
  }

  getProdOrderSequenceFromDemand(demandId: number): Observable<ProdOrderSequenceReturnDTO> {
    return this.http.get<ProdOrderSequenceReturnDTO>(`${environment.apiUrl}ProdOrder/GetSiblingDetailFromDemandId/${demandId}`)
      .pipe(map(ret => {
        for (const s of ret.prodOrderSequence) {
          s.poStartDate = new Date(s.poStartDate);
          s.poEndDate = new Date(s.poEndDate);
        }
        return ret;
      }));
  }

  bulkDelete(prodOrderIds: number[]): Observable<IProdOrderDeleteResponseDTO[]> {
    let params = new HttpParams();
    prodOrderIds.forEach(element => {
      params = params.append('ids', element?.toString());
    });
    return this.http.delete<IProdOrderDeleteResponseDTO[]>(`${environment.apiUrl}ProdOrder`, { params });
  }

  bulkUpdate(prodOrderBatchInput: IProdOrderBatchDTO): Observable<number> {
    return this.http.put<number>(`${environment.apiUrl}ProdOrder/BulkUpdate`, prodOrderBatchInput);
  }

  openBatchEditDialog(prodOrderIds: number[]): DialogRef {
    const dialogRef = this.dialogService.open({
      content: ProdorderBulkEditComponent,
      width: 500,
      height: 'auto',
    });

    const content = dialogRef.content.instance as ProdorderBulkEditComponent;
    content.data = { prodOrderIds };

    return dialogRef;
  }

  getActivityForMaterials(materialIndexes: Set<number>, searchDirection: EntityRelationDirection): Observable<ProductActivityDetails[]> {
    let params = new HttpParams();
    materialIndexes.forEach(element => {
      params = params.append('materialIndexes', element.toString());
    });
    params = params.append('searchDirection', searchDirection);
    return this.http.get<ProductActivityDetails[]>(`${environment.apiUrl}ProdOrder/GetActivityForMaterials`, { params });
  }

  openDialogForMultiStepPO(siteId: Guid, prodOrder: NewProdOrderInputDTO, entityRelations: IEntityRelation[]): DialogRef {
    const dialogRef = this.dialogService.open({
      content: CreateMultiStepProdOrderComponent,
      width: 1000,
      height: 'auto',
    });

    const content = dialogRef.content.instance as CreateMultiStepProdOrderComponent;
    content.data = <CreateMultiStepData>{
      siteId,
      prodOrder,
      entityRelations
    };

    return dialogRef;
  }

  openDialogForMultiStepExistingPO(prodOrderId: number, entityRelations: IEntityRelation[]): DialogRef {
    const dialogRef = this.dialogService.open({
      content: CreateMultiStepProdOrderComponent,
      width: 1000,
      height: 'auto',
    });

    const content = dialogRef.content.instance as CreateMultiStepProdOrderComponent;
    content.data = <CreateMultiStepData>{
      prodOrderId,
      entityRelations
    };

    return dialogRef;
  }

  createPOChain(criteria: CreateChainProdOrderInput): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}ProdOrder/CreatePOChain`, criteria);
  }

  navigateToProdOrderGrid() {
    this.router.navigate(['/prod-order']);
  }

  private getMainPOLine(entityrelations: IEntityRelation[], product: IEntity) {
    return entityrelations.find(x => x.productIndex === product.index);
  }
}

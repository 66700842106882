import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormGroup,
  AbstractControl,
  ValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { CreateGlulamSpecForm } from '@app/core/models/forms/gluelam/glulam-spec/create-glulam-spec-form.model';
import {
  GlulamSpecFormModel,
  IProductListItem
} from '@app/core/models/gulam-spec.model';
import { getGlueSpecLayerTypesValues, IDropDownDataItem } from '@app/core/models/model-glue-layer-type.enum';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { EntitySimpleService, IEntitySimpleDTO } from '@app/core/services/http-services/model/entity-simple.service';
import { EntityRelationDirection, EntityService, IEntity, IGlulamSpec } from '@app/core/services/http-services/model/entity.service';
import { EntityFormComponent } from '@app/modules/entity-admin/entity-form/entity-form.component';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { SVGIcon, minusIcon, plusIcon, calculatorIcon } from '@progress/kendo-svg-icons';
import { firstValueFrom, forkJoin, Observable, Subject, takeUntil } from 'rxjs';

marker('EntityTranslation.MinStockShouldbeGreaterThanZero');
marker('GluePlan.EntityMandatory');
marker('GluePlan.NrOfLamellasShouldbeGreaterThanZero');
marker('GluePlan.ThicknessShouldbeGreaterThanZero');
marker('GluePlan.EntityHasNoThickness');
marker('GluePlan.EntityThicknessExceeded');
marker('GluePlan.BeamHightMandatory');
marker('GluePlan.MoreThanOneHeightDependant');

export function maxLargerMin(controlName: string): ValidatorFn {
  return null;
}

export const sumHightBelowMax: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const formVal = (control as FormGroup)?.value;
  let sum = 0;
  const rows = formVal.specrows;
  for (const i in rows) {
    sum += rows[i]?.numberOfLammellas * rows[i]?.planingThickness;
  }

  if (sum < formVal.hightMin) {
    return {
      sumSmallerThanMin: true
    };
  } else if (sum > formVal.hightMax) {
    return {
      sumSmallerThanMin: true
    };
  }

  return null;
};

export const onlyOneHightDep: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const formVal = (control as FormGroup)?.value;
  const rows = formVal.specrows as any[];

  if (rows.filter((i) => i.heightDependant === true).length > 1) {
    return {
      onlyOneHightDep: true
    };
  }

  return null;
};

@Component({
  selector: 'app-glulam-spec',
  templateUrl: './glulam-spec.component.html',
  styleUrls: ['./glulam-spec.component.css']
})
export class GlulamSpecComponent extends DialogContentBase implements OnInit {
  loading = false;
  gluSpecForm: FormGroup<CreateGlulamSpecForm> = null;
  formdata: GlulamSpecFormModel;
  productList: IProductListItem[] = [];
  glulamSpecs: IGlulamSpec[] = [];
  layerTypes: Array<IDropDownDataItem>;
  get specRows() {
    return this.gluSpecForm.get('specrows') as FormArray;
  }
  activity: IEntity = null;
  calculating: boolean = false;
  defaultItem: { name: string; valueField: number } = {
    name: 'App.SelectItem',
    valueField: null
  };
  //Icons
  minusIcon: SVGIcon = minusIcon;
  plusIcon: SVGIcon = plusIcon;
  calculatorIcon: SVGIcon = calculatorIcon;

  private readonly destroy = new Subject<void>();

  constructor(
    private readonly entityService: EntityService,
    private readonly dialogRef: DialogRef,
    private readonly appNotificationService: AppNotificationService,
    private readonly entitySimpleService: EntitySimpleService
  ) { super(dialogRef); }

  ngOnInit(): void {
  }

  async setup(entity: IEntity, form: FormGroup<CreateGlulamSpecForm>) {

    this.gluSpecForm = form;

    this.loading = true;

    this.activity = entity;

    this.layerTypes = getGlueSpecLayerTypesValues();

    const relations = entity.entityRelations.filter(er => er.direction === EntityRelationDirection.CONSUMES && er.unitCode !== 'HOUR');

    relations.forEach(er => {
      er.glulamSpec.forEach(g => {
        this.glulamSpecs.push(g);
      });
    });

    const tasks: Observable<IEntitySimpleDTO>[] = [];

    relations.forEach(e => {
      tasks.push(this.entitySimpleService.get(e.productIndex));
    });

    var products = await firstValueFrom(forkJoin(tasks).pipe(takeUntil(this.destroy)));

    this.productList = products.map(p => <IProductListItem>{ key: p.index, value: p.description })

    this.loading = false;
  }

  getMaxThickness(productIndex: number): number {
    const entity = this.productList.find((f) => f.key === productIndex);
    if (entity !== null) {
      return entity.thickness;
    }
    return 0;
  }

  onTurnChange(c: AbstractControl) {
    c.get('isChanged').patchValue(true);
  }

  onProductChange(c: AbstractControl) {
    const pi = c.get('productIndex').value;
    const mv = this.getMaxThickness(pi);
    c.get('maxThickness').patchValue(mv);
  }

  calculateRelation(): Map<number, number> {
    const relations = new Map<number, number>();

    const rows = this.specRows;
    rows.controls.forEach((r) => {
      const pi = r.get('productIndex').value;
      const nl = r.get('numberOfLammellas').value;
      const removed = r.get('isRemoved').value;

      const entity = this.productList.find((f) => f.key === pi);
      const relation = (((entity.width / 1000) * entity.thickness) / 1000) * nl;
      if (relations.has(pi)) {
        if (!removed) {
          relations.set(pi, relations.get(pi) + relation);
        }
      } else {
        if (removed) {
          relations.set(pi, 0);
        } else {
          relations.set(pi, relation);
        }
      }
    });

    return relations;
  }

  onSubmit() {
    if (this.validate()) {
      this.dialogRef.close({ success: true, relations: this.calculateRelation(), glulamspec: this.gluSpecForm });
    }
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  validate(): boolean {
    let valid = true;
    if (!this.gluSpecForm.valid) {
      const messages = this.gluSpecForm?.errors?.['errormessages'];
      messages.forEach((e) => {
        this.showMessage(e);
      });
      valid = false;
    }

    return valid;
  }

  showMessage(message: string) {
    this.appNotificationService.notifyErrorAppChanel(message);
  }

  removeRow(specIndex: number) {
    const rows = this.specRows;
    rows.at(specIndex).get('isRemoved').patchValue(true);
    if (rows.at(specIndex).get('isNew').value) {
      rows.removeAt(specIndex);
    }
  }

  getActiveLines() {
    return this.specRows.controls.filter(c => !c.value?.isRemoved);
  }

  addRow() {
    const rows = this.specRows;
    const maxSequenseValue = Math.max(...rows.controls.map((o) => o.get('sequence').value), 0);

    rows.insert(
      rows.length,
      EntityFormComponent.newLine(<IGlulamSpec>{
        activityIndex: this.activity?.index,
        heightDependant: false,
        numberOfLammellas: null,
        planingThickness: null,
        maxThickness: 0,
        productIndex: null,
        sequence: maxSequenseValue + 1,
        dryJoint: false,
        turn: false,
        isNew: true,
        isChanged: false,
        isRemoved: false,
        layerType: null
      })
    );


  }

  calc() {

    this.calculating = true;

    this.entityService.calcGluSpec(this.activity.index).subscribe(calcRes => {
      for (var g of calcRes.lams) {
        let line = this.glulamSpecs.find(l => g.sequence === l.sequence);
        line.numberOfLammellasCalc = g.numberOfLammellas;
      }
      this.calculating = false;
    });
  }
}

import { Injectable, Injector, ComponentRef } from '@angular/core';
import { BeamMaterialLine } from '@app/core/models/beam-material-line.model';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { BMLMenuComponent } from './bml-menu.component';
import { BMLMenuOverlayRef } from './BMLMenuOverlayRef';
import { BML_DATA, BM_DATA, BML_COPY_DATA, MACHINE_DATA, IS_STATICAL_GLUE_PLAN, IS_LAYER_WITH_SPECIAL_FORM_BMT } from './tookens';
import { BeamMaterial } from '../../../../core/models/beam-material.model';
import { DeamndService } from '@app/core/services/http-services/gluelam/demand.service';
import { IMachineDTO } from '@app/core/models/machineDTO';

interface BMLDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  bml: BeamMaterialLine;
  bm: BeamMaterial;
  height?: string;
  width?: string;
  machine: IMachineDTO | null;
  isStaticalGluPlan: boolean;
  isLayerWithSpecialFormBMT: boolean;
}

const DEFAULT_CONFIG: BMLDialogConfig = {
  hasBackdrop: true,
  backdropClass: null,
  panelClass: 'menuContainer',
  bml: null,
  bm: null,
  height: '400px',
  width: '600px',
  machine: null,
  isStaticalGluPlan: false,
  isLayerWithSpecialFormBMT: false
};

@Injectable()
export class BeamMaterialLineEditorService {
  constructor(
    private injector: Injector,
    private overlay: Overlay,
    public beamOrderRowService: DeamndService
  ) { }

  private dialogRef: BMLMenuOverlayRef = null;

  public bmlsCopied: BeamMaterialLine[] = [];

  open({ x, y }: MouseEvent, config: BMLDialogConfig) {
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };
    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(x, y, dialogConfig);

    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.dialogRef = new BMLMenuOverlayRef(overlayRef);
    this.attachDialogContainer(
      overlayRef,
      dialogConfig,
      this.dialogRef
    );

    overlayRef.backdropClick().subscribe(_ => this.dialogRef.close());

    return this.dialogRef;
  }

  private createOverlay(x: number, y: number, config: BMLDialogConfig) {
    const overlayConfig = this.getOverlayConfig(x, y, config);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(
    overlayRef: OverlayRef,
    config: BMLDialogConfig,
    dialogRef: BMLMenuOverlayRef
  ) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(
      BMLMenuComponent,
      null,
      injector
    );

    const containerRef: ComponentRef<BMLMenuComponent> = overlayRef.attach(
      containerPortal
    );

    return containerRef.instance;
  }

  private createInjector(
    config: BMLDialogConfig,
    dialogRef: BMLMenuOverlayRef
  ): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(BMLMenuOverlayRef, dialogRef);
    injectionTokens.set(DeamndService, this.beamOrderRowService);
    injectionTokens.set(BML_DATA, config.bml);
    injectionTokens.set(BML_COPY_DATA, this.bmlsCopied);
    injectionTokens.set(BM_DATA, config.bm);
    injectionTokens.set(MACHINE_DATA, config.machine);
    injectionTokens.set(IS_STATICAL_GLUE_PLAN, config.isStaticalGluPlan);
    injectionTokens.set(IS_LAYER_WITH_SPECIAL_FORM_BMT, config.isLayerWithSpecialFormBMT);
    // injectionTokens.set(BMGS_DATA,config.bm. );

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(
    x: number,
    y: number,
    config: BMLDialogConfig
  ): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        }
      ]);

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('App.RemoveConfirmTitle');
marker('App.RemoveConfirmMessage');

@Component({
  selector: 'app-confirmation-dialog',
  template: `
  <kendo-dialog (close)="confirmRemove(false)">

    <kendo-dialog-titlebar>
        <kendo-label themeColor="primary" translate> {{ title }}  </kendo-label>
    </kendo-dialog-titlebar>

    <p style="margin: 30px; text-align: center;">
        {{ msg | translate }}
    </p>

    <kendo-dialog-actions layout="end">
        <button kendoButton
                class='k-button min-height-confirmDelete-button'
                (click)="confirmRemove(false)" themeColor="base">
            {{'App.No' | translate}}
        </button>
        <button kendoButton
                class='k-button k-primary min-height-confirmDelete-button'
                (click)="confirmRemove(true)"
                themeColor="primary">
            {{'App.Yes' | translate}}
        </button>
    </kendo-dialog-actions>

  </kendo-dialog>
  `,
  styles: [
    '.min-height-confirmDelete-button: { height:40px; }'
  ]
})
export class ConfirmationDialogComponent {
  @Input() title:string = 'App.RemoveConfirmTitle';
  @Input() msg:string = 'App.RemoveConfirmMessage';

  @Output() conformationDialogCloseEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  confirmRemove(isRemove:boolean) {
    this.conformationDialogCloseEvent.emit(isRemove);
  }
}

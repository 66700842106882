import { Injectable } from '@angular/core';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';

@Injectable({ providedIn: 'root' })
export class RoleBasedAccessHelper {
  constructor(private localStorageService:LocalStorageService) { }

  hasOnlyInputRole(restrictedRoles: UserRoleEnum[]): boolean {
    if (restrictedRoles && restrictedRoles.length > 0) {
      const userRoles = JSON.parse(this.localStorageService.getItem(LocalStorageService.userRoles));
      for (const [key, value] of Object.entries(userRoles)) {
        if (value) {
          if (!restrictedRoles.some(x=> x == key)) {
            return false;
          }
          return true;
        }
      }
      return true;
    }
    return true;
  }
}

export enum UserRoleEnum {
 Developer = 'IsDeveloper',
 Operator = 'IsOperator',
 Planner = 'IsPlanner'
}

<svg:rect
*ngIf="bm.takeTestPsc"
[attr.width]="ch.getWidth(bm.testPscLenght)"
[attr.height]="ch.getHight(bm.getHight())"
[attr.x]="ch.getWidth(xOffsetTestPSC)"
class="test-pcs"
></rect>

<svg:rect
[attr.width]="ch.getWidth(bm.getLength())"
[attr.height]="ch.getHight(bm.getHight())"
[ngStyle]="bm.getColor()"
(contextmenu)="grab($event)"
[attr.y]="0">
</svg:rect>

<svg:g *ngIf="isNormal()">
    <svg
        *ngFor="let bml of getLinesToDraw(); let i = index"
        app-beam-material-line
        [bml]="bml"
        [ch]="ch"
        [attr.x]="ch.getWidth(getXforBML(bml,i))"
        [planedThickness]="bm.planedThickness"
        [defaultNoOfBeams]="getDefaultNoOfBeams(bml)"
        [colorLight]="bm.beamMaterialType.colorLight"
        (openBeamMaterialLineMenu)="openBeamMaterialLineMenu($event)"
        [attr.y]="0">
    </svg>
</g>
<svg:g *ngIf="!isNormal()">
  <text alignment-baseline="middle" [attr.clip-path]="'url(#clip_' + bm.getLength() + ')'" [attr.y]="12" [attr.x]="5" >
    <tspan>
      {{getTextForManualBeamMaterial()}}
    </tspan>
  </text>
</g>

import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserSiteDTO } from '../common/user.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SiteQueryService extends BehaviorSubject<Array<UserSiteDTO>>{

  loading = false;

  constructor(private http: HttpClient) {
    super([]);
  }

  public async query(): Promise<void> {


    var users = await firstValueFrom(this.http.get<Array<UserSiteDTO>>(`${environment.apiUrl}Site`));


    super.next(users);
  }
}

import * as allSvgIcons from '@progress/kendo-svg-icons';

import { Component, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { DrawerComponent, DrawerItem, DrawerMode, DrawerSelectEvent } from '@progress/kendo-angular-layout';
import { OptinexUserDTO, UserService } from './core/services/http-services/common/user.service';
import { SVGIcon, chevronDownIcon, chevronUpIcon, logoutIcon, menuIcon } from '@progress/kendo-svg-icons';

import { AuthService } from './core/services/auth-service/auth.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environment/environment';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';

marker([
  'AppComponent.Admin',
  'AppComponent.Admin_Entity',
  'AppComponent.Admin_EntityActivity',
  'AppComponent.Admin_EntityResource',
  'AppComponent.Admin_EntityGroup',
  'AppComponent.Admin_EntityProperty',
  'AppComponent.Admin_Model',
  'AppComponent.Analytics',
  'AppComponent.Beams',
  'AppComponent.General_Dashbord',
  'AppComponent.Glue_Plans',
  'AppComponent.Gluing_Plan',
  'AppComponent.Glulam_Operator',
  'AppComponent.Glulam_Planning',
  'AppComponent.Glulam_Planning_Statical',
  'AppComponent.Glulam_Scheduler_Statical',
  'AppComponent.Log_Class',
  'AppComponent.Models',
  'AppComponent.Operational_Dashbord',
  'AppComponent.Optimization',
  'AppComponent.Pattern_Analysis',
  'AppComponent.Production_Order',
  'AppComponent.Production_Order_Schedule',
  'AppComponent.Production_Order_Gantt',
  'AppComponent.Reports',
  'AppComponent.Saw_Pattern',
  'AppComponent.Settings',
  'AppComponent.Tactical_Dashbord',
  'AppComponent.Availability',
  'AppComponent.AoT_Balance',
  'AppComponent.AoT_Balance_Report',
  'AppComponent.Glulam',
  'AppComponent.Glulam',
  'AppComponent.Machine',
  'AppComponent.Operative',
  'AppComponent.Model',
  'AppComponent.Stock',
  'AppComponent.Stock_Location',
  'AppComponent.Stock_Trans_Type',
  'AppComponent.CLT',
  'AppComponent.Plan',
  'AppComponent.MachineGroup',
  'AppComponent.Handeling_Unit_Report',
  'AppComponent.Schedule',
  'AppComponent.Shift',
  'AppComponent.Deviation',
  'AppComponent.Demand_Report',
  'AppComponent.Device',
  'AppComponent.OptimizeSequencer',
  'AppComponent.LogForecast',
  'AppComponent.Production_Order_ScheduleNg',
  'AppComponent.OptimizeRuns',
  'AppComponent.Production_Order_Calendar',
  'AppComponent.Instruction',
  'AppComponent.InstructionLine',
  'AppComponent.Parts',
  'AppComponent.Part',
  'AppComponent.Profile',
  'AppComponent.ShapeList',
  'AppComponent.EditMotherBoard',
  'AppComponent.EditProfileView',
  'AppComponent.PlanView',
  'AppComponent.EditPlan',
  'AppComponent.Tactical',
  'AppComponent.Users'
]);

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-root',
  styles: [
    `
      html,
      body,
      app-root {
        padding: 0;
        height: 100%;
      }

      app-root {
        display: flex;
        flex-direction: column;
      }

      kendo-drawer-container {
        flex: 1 1 auto;
        overflow-y: auto;
        display: flex;
      }

      kendo-drawer-content {
        flex: 1 1 auto;
        overflow-y: scroll;
        overflow-x: scroll;
        margin-botom: 0px;
        height: calc(100% - 30px)
      }

      .toolbar-logo img {
        height: 30px;
      }

      .main-content-margin{
        margin: 10px;
      }
    `
  ],
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public mode:DrawerMode = 'push';

  public menuSvg: SVGIcon = menuIcon;
  public logoutIcon: SVGIcon = logoutIcon;
  public chevronDownIcon: SVGIcon = chevronDownIcon;
  public chevronUpIcon: SVGIcon = chevronUpIcon;

  public locked: boolean = false;
  public expanded: boolean = true;
  public loggedIn: boolean = false;
  public mini: boolean = true;

  public noMargin: boolean = true;

  public configuration = environment;
  hideMenu:Boolean = false;

  @ViewChild(DrawerComponent)drawer: DrawerComponent;

  public nodes: Array<AppDrawerItem> = [
      { text: 'AppComponent.Analytics', svgIcon: allSvgIcons.chartAreaStackedIcon, id: 1, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.General_Dashbord', svgIcon: allSvgIcons.chartAreaStackedIcon, path: 'dashboard', id: 2, parentId: 1, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Tactical_Dashbord', svgIcon: allSvgIcons.chartAreaStackedIcon, path: 'dashboard/2', id: 3, parentId: 1, forDeveloper: true },
      { text: 'AppComponent.Operational_Dashbord', svgIcon: allSvgIcons.chartAreaStackedIcon, path: 'dashboard/1', id: 4, parentId: 1, forDeveloper: true },

      { separator: true },

      { text: 'AppComponent.Availability', svgIcon: allSvgIcons.chartRadarMarkersIcon, id: 5, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.AoT_Balance', svgIcon: allSvgIcons.chartRadarMarkersIcon, id: 6, path: 'aot/balance', parentId: 5, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.AoT_Balance_Report', svgIcon: allSvgIcons.chartRadarMarkersIcon, id: 7, path: 'aot/balanceReport', parentId: 5, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Demand_Report', svgIcon: allSvgIcons.chartRadarMarkersIcon, id: 8, path: 'aot/demand', parentId: 5, forDeveloper: true, forPlanner: true },
      { text: 'AoT.LogForecast', svgIcon: allSvgIcons.chartRadarMarkersIcon, id: 9, path: 'aot/logForecast', parentId: 5, forDeveloper: true, forPlanner: true },

      { separator: true },

      { text: 'AppComponent.Model', svgIcon: allSvgIcons.buildingBlocksIcon, id: 10, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Models', svgIcon: allSvgIcons.buildingBlocksIcon, id: 11, path: 'model/model', parentId: 10, forDeveloper: true },
      { text: 'AppComponent.Admin_EntityActivity', svgIcon: allSvgIcons.buildingBlocksIcon, id: 12, path: 'model/entity/activity', parentId: 10, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Admin_EntityResource', svgIcon: allSvgIcons.buildingBlocksIcon, id: 13, path: 'model/entity/material', parentId: 10, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Admin_EntityGroup', svgIcon: allSvgIcons.buildingBlocksIcon, id: 14, path: 'model/entity-group', parentId: 10, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Admin_EntityProperty', svgIcon: allSvgIcons.buildingBlocksIcon, id: 15, path: 'model/entity-property', parentId: 10, forDeveloper: true, forPlanner: true },

      { separator: true },

      { text: 'EntityTranslation.Tactical', svgIcon: allSvgIcons.arrowsAxesIcon, id: 17,  forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Plan', svgIcon: allSvgIcons.arrowsAxesIcon, id: 18, path: 'tactical/plan', parentId: 17, forDeveloper: true, forPlanner: true },

      { separator: true },

      { text: 'AppComponent.Saw_Pattern', svgIcon: allSvgIcons.cutIcon, id: 19, path: 'saw-pattern', forDeveloper: true },

      { separator: true },

      { text: 'AppComponent.Glulam', svgIcon: allSvgIcons.alignItemsStartIcon, id: 20, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Glulam_Planning', svgIcon: allSvgIcons.alignItemsStartIcon, id: 21, path: 'glulam', parentId: 20, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Glulam_Planning_Statical', svgIcon: allSvgIcons.alignItemsStartIcon, id: 22, path: 'glulam/statical', parentId: 20, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Glulam_Scheduler_Statical', svgIcon: allSvgIcons.alignItemsStartIcon, id: 56, path: 'glulam/statical/scheduler-v2', parentId: 20, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Glulam_Operator', svgIcon: allSvgIcons.alignItemsStartIcon, id: 23, path: 'glulam/operator', parentId: 20, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Beams', svgIcon: allSvgIcons.alignItemsStartIcon, id: 24, path: 'glulam/beams', parentId: 20, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Glue_Plans', svgIcon: allSvgIcons.alignItemsStartIcon, id: 25, path: 'glulam/glueplans', parentId: 20, forDeveloper: true, forPlanner: true },

      { separator: true },

      { text: 'AppComponent.Optimization', svgIcon: allSvgIcons.gearsIcon, id: 27, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.OptimizeSequencer', svgIcon: allSvgIcons.gearsIcon, id: 9, path: 'optimization/sequencer', parentId: 27, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.OptimizeRuns', svgIcon: allSvgIcons.gearsIcon, id: 28, path: 'optimization/runs', parentId: 27, forDeveloper: true, forPlanner: true },

      { separator: true },

      { text: 'AppComponent.CLT', svgIcon: allSvgIcons.tableRowGroupsIcon, id: 29, forDeveloper: true },
      { text: 'AppComponent.ShapeList', svgIcon: allSvgIcons.tableRowGroupsIcon, id: 30, path: 'clt/shape-list', parentId: 29, forDeveloper: true },
      { text: 'AppComponent.PlanList', svgIcon: allSvgIcons.tableRowGroupsIcon, id: 31, path: 'clt/plan-list', parentId: 29, forDeveloper: true },
      { text: 'AppComponent.ProfileList', svgIcon: allSvgIcons.tableRowGroupsIcon, id: 32, path: 'clt/profile-list', parentId: 29, forDeveloper: true },
      { text: 'AppComponent.EditMotherboard', svgIcon: allSvgIcons.tableRowGroupsIcon, id: 33, path: 'clt/edit-motherboard', parentId: 29, forDeveloper: true },
      { text: 'AppComponent.EditPlan', svgIcon: allSvgIcons.tableRowGroupsIcon, id: 34, path: 'clt/edit-plan', parentId: 29, forDeveloper: true },
      { text: 'AppComponent.EditProfile', svgIcon: allSvgIcons.tableRowGroupsIcon, id: 35, path: 'clt/edit-profile', parentId: 29, forDeveloper: true },

      { separator: true },

      { text: 'AppComponent.Operative', svgIcon: allSvgIcons.calendarIcon, id: 36, forDeveloper: true, forPlanner: true, forOperator: true },
      { text: 'AppComponent.Production_Order', svgIcon: allSvgIcons.calendarIcon, id: 37, path: 'prod-order', parentId: 36, forDeveloper: true, forPlanner: true, forOperator: true },
      { text: 'AppComponent.Production_Order_ScheduleNg', svgIcon: allSvgIcons.calendarIcon, id: 38, path: 'prod-order-schedule-ng', parentId: 36, forDeveloper: true, forPlanner: true, forOperator: true },
      { text: 'AppComponent.Production_Order_Calendar', svgIcon: allSvgIcons.calendarIcon, id: 39, path: 'prod-order-calendar-ng', parentId: 36, forDeveloper: true, forPlanner: true, forOperator: true },
      { text: 'AppComponent.Machine', svgIcon: allSvgIcons.calendarIcon, id: 40, path: 'machine', parentId: 36, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.MachineGroup', svgIcon: allSvgIcons.calendarIcon, id: 41, path: 'machine-group', parentId: 36, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Instruction', svgIcon: allSvgIcons.calendarIcon, id: 42, path: 'instruction', parentId: 36, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.InstructionLine', svgIcon: allSvgIcons.calendarIcon, id: 43, path: 'instruction-line', parentId: 36, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Schedule', svgIcon: allSvgIcons.calendarIcon, id: 44, path: 'schedule', parentId: 36, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Shift', svgIcon: allSvgIcons.calendarIcon, id: 45, path: 'shift', parentId: 36, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Deviation', svgIcon: allSvgIcons.calendarIcon, id: 46, path: 'deviation', parentId: 36, forDeveloper: true, forPlanner: true },

      { separator: true },

      { text: 'AppComponent.Stock', svgIcon: allSvgIcons.barcodeOutlineIcon, id: 48, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Stock_Location', svgIcon: allSvgIcons.barcodeOutlineIcon, id: 49, path: 'stock/stocklocation', parentId: 48, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Stock_Trans_Type', svgIcon: allSvgIcons.barcodeOutlineIcon, id: 50, path: 'stock/stocktranstype', parentId: 48, forDeveloper: true, forPlanner: true },
      { text: 'AppComponent.Handeling_Unit_Report', svgIcon: allSvgIcons.barcodeOutlineIcon, id: 51, path: 'stock/handelingunitreport', parentId: 48, forDeveloper: true, forPlanner: true },

      { separator: true },

      { text: 'AppComponent.Reports', svgIcon: allSvgIcons.clipboardTextIcon, id: 53, path: 'report', forDeveloper: true, forPlanner: true },

      { separator: true },

      { text: 'AppComponent.Admin', svgIcon: allSvgIcons.clipboardTextIcon, id: 54, forDeveloper: true, forCrossTenantAdmin: true, forSingleTenantAdmin: true},
      { text: 'AppComponent.Users', svgIcon: allSvgIcons.clipboardTextIcon, id: 55, path: 'user-admin', forDeveloper: true, forCrossTenantAdmin: true, forSingleTenantAdmin: true, parentId: 54 },
  ];

  public authenicatedNodes: Array<AppDrawerItem> = [];
  currentUrl: string;
  user: OptinexUserDTO;
  constructor(
    public router:Router,
    public userService: UserService,
    public translateService:TranslateService,
    public titleService: Title,
    public authService: AuthService,
    private location: Location,
    private localStorageService:LocalStorageService
  ) {this.currentUrl = this.location.path().slice(1); }

  public isItemExpanded = (item: any): boolean => {
    return item.expanded;
  };

  async ngOnInit() {
    if (environment.environment !== 'prod') {
      let title = this.titleService.getTitle();

      if (environment.environment != null && environment.environment !== '') {
        title = title + ` (${environment.environment.toUpperCase()})`;
      }

      this.titleService.setTitle(title);
    }

    this.setLoginState();

    this.user = await this.userService.getUser();

    this.confLockState(this.user.isMenuExpanded);

    this.filterNodesByUser(this.user);

    this.setMenuSelection();

    this.locked? this.drawer.toggle(true) : this.drawer.toggle(false);

  }

  filterNodesByUser(user:OptinexUserDTO){
    this.authenicatedNodes = this.nodes.filter(function f(o) {
      if (user.isCrossTenantAdmin && o.forCrossTenantAdmin) {
        return true;
      }

      if (user.isSingleTenantAdmin && o.forSingleTenantAdmin) {
        return true;
      }

      if (user.isDeveloper && o.forDeveloper) {
        return true;
      }

      if (user.isPlanner && o.forPlanner) {
        return true;
      }

      if (user.isOperator && o.forOperator) {
        return true;
      }

      return false;
    });
  }

  setMenuSelection(){

    this.authenicatedNodes.forEach(x=> {
      x.selected = false;
      x.expanded = false;
    });

    let selectedNode = this.authenicatedNodes.find(n => n.path === this.currentUrl);

    if(selectedNode){
      selectedNode.selected = true;
      this.expandParents(selectedNode);
    }

    this.authenicatedNodes = [...this.authenicatedNodes];

  }

  public confLockState(isLocked: boolean) {
    console.log('Selected isLockState', isLocked);

    this.locked = isLocked;

    if (isLocked) {
      this.mode = 'push';
      this.mini = false;
      this.drawer.toggle(true);
    } else {
      this.mode = 'overlay';
      this.mini = false;
      this.drawer.toggle(true);
    }
  }

  onRightClick(item: AppDrawerItem){
   if(item.path){
    window.open(item.path, '_blank')
   }
  }

  public onSelect(ev:DrawerSelectEvent): void {
    if (ev.item.path) {
        this.authenicatedNodes.forEach(x=> {
            x.selected = false;
            x.expanded = false;
        });

        ev.item.selected = true;


        this.currentUrl = ev.item.path;

        this.router.navigate([this.currentUrl]);

        this.setMenuSelection();

        if(!this.locked)
          ev.sender.toggle(false);
        return;
    }else{
      ev.item.expanded = !ev.item.expanded;
    }

  }

  private expandParents(item: AppDrawerItem) {
    let parentId = item.parentId;

    while (parentId) {
      var parrent = this.authenicatedNodes.find(x => x.id == parentId);

      parrent.expanded = true;

      parentId = parrent.parentId;
    }
  }

  public toggleExpanded() {
    this.drawer.toggle();
    this.setMenuSelection();
  }

  setLoginState() {
    this.loggedIn = this.authService.isLoggedIn();
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

  ngAfterViewInit(): void {
    if(JSON.parse(this.localStorageService.getItem(LocalStorageService.userSites)).length === 0){
      this.hideMenu = true;
      this.router.navigate(['/page-not-found']);
    }
  }


  @HostListener('click', ['$event.target']) onClick(e){
    if(e.className.indexOf('k-overlay') >=0){
      if(!this.locked){
      this.drawer.toggle(false);
      }
    }
  }
}

interface AppDrawerItem extends DrawerItem {
  path?: string;
  forCrossTenantAdmin?: boolean;
  forSingleTenantAdmin?: boolean;
  forDeveloper?: boolean;
  forPlanner?: boolean;
  forOperator?: boolean;
  expanded?: boolean;
}

<form
    [formGroup]="instructionForm"
    (ngSubmit)="onSubmit()"
    class="container"
>
    <div
        class="col"
        style="gap: 5px; height: 100%;"
    >
        <kendo-formfield>
            <kendo-label text="{{ 'App.Name'  | translate }}"></kendo-label>
            <kendo-textbox formControlName="name"></kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label text="{{ 'App.Code'  | translate }}"></kendo-label>
            <kendo-textbox formControlName="code"></kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label text="{{ 'Operative.MaterialPropertyCode'  | translate }}"></kendo-label>
            <kendo-combobox
                [data]="property"
                formControlName="materialPropertyCode"
                textField="description"
                valueField="code"
                placeholder="{{'EntityTranslation.SearchProperty' | translate}}"
                [valuePrimitive]="true"
            >
            </kendo-combobox>
        </kendo-formfield>
        <div class="row">
            <b translate>Operative.ValidatedPlannerInstructions</b>
            <div style="flex-grow: 1;"></div>
            <button
                kendoButton
                themeColor="primary"
                type="button"
                [svgIcon]="plusIcon"
                (click)="addValidatedValue()"
                title="{{ 'EntityTranslation.AddValidatedValue' | translate }}"
            >
            </button>
        </div>
        <div
            formArrayName="instructionConfigurations"
            class="col"
            style="gap: 5px; overflow-y: scroll; height: 100%;">
            <ng-container
                *ngFor="let config of instructionConfigurations.controls let i=index"
                [formGroupName]="i">
                <span
                    class="row"
                    *ngIf="config.get('isRemoved').value != true">
                    <kendo-textbox formControlName="configurationValue"></kendo-textbox>
                    <button
                        kendoButton
                        themeColor="error"
                        type="button"
                        [svgIcon]="trashIcon"
                        (click)="removeValidValue(i)"
                        title="{{'EntityTranslation.Remove' | translate}}"
                    >
                    </button>
                </span>
                <br>
            </ng-container>
        </div>
    </div>
</form>
<kendo-dialog-actions layout="end">
    <button
        kendoButton
        (click)="cancel()"
        [themeColor]="'error'"
    >
        {{ 'EntityTranslation.Cancle' | translate }}
    </button>
    <button
        kendoButton
        type="submit"
        (click)="onSubmit()"
        [disabled]="!instructionForm?.valid"
        [primary]="true"
    >
        {{  (isNew ? 'App.Add' : 'App.Update') | translate }}
    </button>
</kendo-dialog-actions>

import { Component, OnDestroy } from '@angular/core';
import { ScheduleService } from '@app/core/services/http-services/operative/schedule.service';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnDestroy {
  refreshScheduleGrid: boolean = false;

  private destroy$ = new Subject<void>();

  constructor(private readonly scheduleService: ScheduleService) {}

  addSchedule() {
    const dialogRef = this.scheduleService.openDialog(undefined);
    dialogRef.result
    .pipe(takeUntil(this.destroy$))
    .subscribe((d) => {
      if (d instanceof DialogCloseResult) {
        dialogRef.close();
      }
      this.refreshScheduleGrid = d as boolean;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

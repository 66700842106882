import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { BeamMaterialGlueSet } from '@app/core/models/beam-material-glue-set.model';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { IGluingPlanValidationDTO } from '@app/core/models/gluing-plan-validation-model';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { IPressbedRamConfigurationDTO } from '@app/core/models/pressbed-ram-ronfiguration-dto';
import { Injectable } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IGlusetSaveResultDTO } from '@app/core/services/http-services/gluelam/glue-set.service';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GluingPlanModalNgComponent } from '../controls/gluing-plan-modal-ng/gluing-plan-modal-ng.component';
import { IGluPlanModalInput } from '@app/core/models/glueplan-modal-input.model';
import { TranslateService } from '@ngx-translate/core';

marker('GlueSet.WARNING_GLUEPLAN_LENGTH_EXCEEDED_MAX_PRESSBED_LENGTH_API');
marker('GlueSet.WARNING_GLUEPLAN_LENGTH_EXCEEDED_MAX_PRESSBED_LENGTH');
marker('GlueSet.WARNING_INVALID_EDGE_OFFSET');
marker('GlueSet.CAN_NOT_ADD_EMPTY_GLUE_SET_LAYERS');

@Injectable()
export class GluelamStaticalMediatorService {
  private glueplanOffsetChangedSubject$ = new BehaviorSubject<GluingPlan>(null);
  public glueplanOffsetChanged:Observable<GluingPlan>;
  public notifyOnGluePlanOffsetChanged(gluePlan: GluingPlan): void {
    this.glueplanOffsetChangedSubject$.next(gluePlan);
  }

  private isGlueplanSaveAllChangesValidSubject$ = new BehaviorSubject<boolean>(false);
  public isGlueplanSaveAllChangesValid:Observable<boolean>;
  public notifyOnIsGlueplanSaveAllChangesValidChanged(isValid: boolean): void {
    this.isGlueplanSaveAllChangesValidSubject$.next(isValid);
  }

  public saveGlueplanwithGlueSetSubject$ = new Subject();
  public notifyOnGluePlanSave(): void {
    this.saveGlueplanwithGlueSetSubject$.next(true);
  }

  private glueSetUpdateSubject$ = new BehaviorSubject<GluingPlan>(null);
  public glueSetUpdate:Observable<GluingPlan>;
  public notifyOnGlueSetUpdate(gluePlan: GluingPlan): void {
    this.glueSetUpdateSubject$.next(gluePlan);
  }

  private glueplanRamConfigurationSubject$ = new BehaviorSubject<IPressbedRamConfigurationDTO[]>(null);
  public glueplanRamConfigurationChange: Observable<IPressbedRamConfigurationDTO[]>;
  public notifyGlueplanRamConfigurationChange(ramConfiguration: IPressbedRamConfigurationDTO[]): void {
    this.glueplanRamConfigurationSubject$.next(ramConfiguration);
  }

  public validateLenghtOffset(lenghtOffset : number, length : number, machine:IMachineDTO): IGluingPlanValidationDTO[] {
    const ret: IGluingPlanValidationDTO[] = [];
    if (machine !== null && Number(lenghtOffset + (length ?? 0) - machine.defaultCutOffLength) > machine.maxPressLength) {
      ret.push({
        text: 'GlueSet.WARNING_GLUEPLAN_LENGTH_EXCEEDED_MAX_PRESSBED_LENGTH',
        values: { length, maxLength: machine?.maxPressLength }
      });
    }
    return ret;
  }

  public gluesets: BeamMaterialGlueSet[] = [];

  private validationResults: IGlusetSaveResultDTO[] = [];
  public saveGluesetValidations(warning: IGlusetSaveResultDTO){
      this.removeGluesetValidation(warning.glusetID);
      this.validationResults.push(warning);
  }

  public removeGluesetValidation(glueSetID: number): void {
      this.validationResults = this.validationResults.filter(x => x.glusetID !== glueSetID);
  }

  public getGluesetValidationErrorMessage(glueSetID: number): string {
    const validationResult = this.validationResults.find(x => x.glusetID === glueSetID);
    return validationResult ? validationResult.errorMessage : null;
  }

  public openGluingPlanModelDialog(isNew: boolean, plan: GluingPlan, isContinuousPressGroup:boolean,isStaticalPressGroup:boolean, isStaticalScheduler:boolean): DialogRef {
    const dialogRef = this.dialogService.open({
      content: GluingPlanModalNgComponent,
      title: isNew ? this.translateService.instant('GluePlan.AddGluingPlan') : this.translateService.instant('GluePlan.UpdateGluePlan'),
      width: '50vw'
    });

    const content = dialogRef.content.instance as GluingPlanModalNgComponent;

    content.data = <IGluPlanModalInput>{
      isNew,
      gluingPlan: { ...plan },
      isContinuousPressGroup: isContinuousPressGroup,
      isStaticalPressGroup: isStaticalPressGroup,
      isStaticalScheduler: isStaticalScheduler
    };

    return dialogRef;
  }

  constructor(private readonly translateService: TranslateService,
    private dialogService: DialogService) {
    this.glueplanOffsetChanged = this.glueplanOffsetChangedSubject$.asObservable();
    this.glueSetUpdate = this.glueSetUpdateSubject$.asObservable();
    this.isGlueplanSaveAllChangesValid = this.isGlueplanSaveAllChangesValidSubject$.asObservable();
    this.glueplanRamConfigurationChange = this.glueplanRamConfigurationSubject$.asObservable();
  }
}

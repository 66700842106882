import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EntityService, IEntity } from '@app/core/services/http-services/model/entity.service';
import { EntitySimpleService, IEntitySimpleDTO } from '@app/core/services/http-services/model/entity-simple.service';

import { Subscription } from 'rxjs';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('App.SelectEntity');

@Component({
  selector: 'app-select-entity-simple',
  templateUrl: './select-entity-simple.component.html',
  styleUrls: ['./select-entity-simple.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectEntitySimpleComponent
    }
  ]
})
export class SelectEntitySimpleComponent implements ControlValueAccessor, OnDestroy  {
  selectedEntityIndex: number = null;
  loading: boolean = false;

  @Input() label: string = 'App.SelectEntity';
  @Input() showLable: boolean = false;
  @Input() entityTypeCode: 'RESOURCE' | 'ACTIVITY' = 'RESOURCE';
  @Input() getMaterialStandardProperties: boolean = false;
  @Input() isGlulamGroup: boolean = false;
  @Output() selectedEntity: EventEmitter<IEntitySimpleDTO> = new EventEmitter<IEntitySimpleDTO>();


  private entityTake: number = 20;
  private onChanged: Function = () => {};
  private onTouched: Function = () => {};
  public entities: IEntitySimpleDTO[];
  private filterSubscription: Subscription;
  private sub: Subscription;

  constructor(private readonly entitySimpleService: EntitySimpleService) { }

  private getEntityByIndex(entityIndex: number) {
    this.loading = true;
    this.sub = this.entitySimpleService.get(entityIndex)
      .subscribe(d => {
        if (d) {
          this.entities = [d];
          this.onSelectionChange(d);
        }
        this.loading = false;
      }
      );
  }

  onSelectionChange(entity: IEntitySimpleDTO) {
    this.selectedEntityIndex = (entity !== null) ? entity.index : null;
    this.selectedEntity.emit(entity);
    this.onChanged(this.selectedEntityIndex);
  }

  writeValue(value: number): void {
    this.selectedEntityIndex = value;
    if (value > 0) {
      this.getEntityByIndex(this.selectedEntityIndex);
    }
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public handleFilterChange(searchTerm: string): void {
    if (searchTerm.length >= 3) {
      if (this.filterSubscription) {
        this.filterSubscription.unsubscribe();
      }
      this.loading = true;
      this.filterSubscription = this.entitySimpleService.query(this.entityTypeCode, searchTerm, this.entityTake, this.getMaterialStandardProperties, this.isGlulamGroup)
        .subscribe(d => {
          this.entities = d;
          this.loading = false;
        }
        );
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

}

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { GluePlanDimension } from '@app/core/models/gluing-plan-pressbed-planning.model';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { IPressbedRamConfigurationDTO } from '@app/core/models/pressbed-ram-ronfiguration-dto';
import { GlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';
import { PressbedPlanningViewService } from '@app/core/services/http-services/gluelam/pressbed-planning-view.service';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { ExpansionPanelComponent } from '@progress/kendo-angular-layout';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GluelamStaticalMediatorService } from '@app/modules/gluelam/services/gluelam-statical-mediator.service';

marker('GluePlan.Length');
marker('GluePlan.Pressbed_Planning');
marker('Machine.NoMachineSelected');

@Component({
  selector: 'app-pressbed-planning-view',
  templateUrl: './pressbed-planning-view.component.html',
  styleUrls: ['./pressbed-planning-view.component.css']
})
export class PressbedPlanningViewComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() public machine: IMachineDTO | null;
  @Input() public gluPlans: GluingPlan[];
  @Input() refreshPressbedView: Observable<GluingPlan>;
  @ViewChild('machineview') machineView: ElementRef;
  @ViewChild('panel', { static: false }) public panel: ExpansionPanelComponent;

  release: number[];
  dimensions: GluePlanDimension[];
  containerWidth: number;
  containerHeight: number;
  validGlueplans : GluingPlan[] = [];
  observer:ResizeObserver;
  ramConfiguration : IPressbedRamConfigurationDTO[] = [];
  private destroy$: Subject<void> = new Subject<void>();

  constructor(public pressbedPlanningViewService: PressbedPlanningViewService,
              private cdr: ChangeDetectorRef,
              private host: ElementRef,
              private gluelamStaticalMediatorService: GluelamStaticalMediatorService) { }

  ngOnInit(): void {
    this.refreshPressbedView.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data) {
        const index = this.validGlueplans.findIndex(plan => plan.gluingPlanID === data.gluingPlanID);
        if (index !== -1) {
          this.validGlueplans[index] = data;
        }
      }
      this.setupCordSystem();
    });

    this.observer = new ResizeObserver(entries => {
      this.setupCordSystem();
    });

    this.observer.observe(this.host.nativeElement);

    this.gluelamStaticalMediatorService.glueplanRamConfigurationChange.pipe(takeUntil(this.destroy$)).subscribe((config) => {
      if (config != null) {
        this.ramConfiguration = config;
      }
    });
  }

  ngOnDestroy() {
    this.observer.unobserve(this.host.nativeElement);
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.panel.expand.subscribe(() => {
      this.setupCordSystem();
      this.cdr.detectChanges();
    });
  }

  ngDoCheck() {
    this.validGlueplans = [...this.gluPlans];
    this.validGlueplans = this.validGlueplans.filter((x) => {
      return ((x.glueSetStateId === GlueSetState.PLANNED || x.glueSetStateId === GlueSetState.GENERATED) && x.length > 0 &&
              this.machine?.machineId === x.machineId);
    });

    if ((!this.dimensions || this.dimensions.length !== this.validGlueplans.length) &&
       (this.machine && this.machine?.name !== 'App.SelectItem')) {
      this.setupCordSystem();
    }
  }

  onChangeOfset(event : any) {
    let id = Number(event.id.split('_')[0]);
    if (id) {
      const offset = event.offset / this.pressbedPlanningViewService.pxPerMMWidth;
      const updatedGluePlan = this.validGlueplans.find(plan => plan.gluingPlanID === id);
      updatedGluePlan.lenghtOffset = this.ramConfiguration.length > 0 ? this.getNearestRamOffset(offset, updatedGluePlan.length) : Math.round(offset);
      this.setupCordSystem();
      this.dimensions = this.pressbedPlanningViewService.getGluePlanPositions(
        this.validGlueplans.sort((a, b) => new Date(a.plannedExecutionStartDate).getTime() - new Date(b.plannedExecutionStartDate).getTime())
        , this.machine);
      this.gluelamStaticalMediatorService.notifyOnGluePlanOffsetChanged(updatedGluePlan);
    }
  }

  uniqueFilter(value, index, self) {
    return self.indexOf(value) === index;
  }

  getWidth(width:number) {
    return this.pressbedPlanningViewService.getWidth(width);
  }

  private getNearestRamOffset(offset, length): number {
    let nearestRamPosition: number = this.ramConfiguration[0]?.position;
    let minDifference: number = Math.abs(nearestRamPosition - offset);
    this.ramConfiguration.forEach(value => {
      const difference = Math.abs(value.position - offset);
      if (difference < minDifference && (this.gluelamStaticalMediatorService.validateLenghtOffset(value.position, length, this.machine).length === 0)) {
        minDifference = difference;
        nearestRamPosition = value.position;
      }
    });

    return nearestRamPosition;
  }

  private setupCordSystem() {
    this.containerHeight = this.machineView.nativeElement.offsetHeight;
    this.containerWidth = this.machineView.nativeElement.offsetWidth;
    this.pressbedPlanningViewService.initinitialize(this.containerHeight,
      this.containerWidth,
      this.machine);
    this.dimensions = this.pressbedPlanningViewService
      .getGluePlanPositions(this.validGlueplans.sort((a, b) => new Date(a.plannedExecutionStartDate).getTime() - new Date(b.plannedExecutionStartDate).getTime())
        , this.machine);
  }

  getColor(state: string) {
    switch (state) {
      case 'PLANNED':
        return 'rgba(255, 0, 0)';
      case 'GENERATED':
        return 'rgba(26, 102, 46)';
      default:
        return 'rgba(0, 0, 255)';
    }
  }
}

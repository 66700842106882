import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DimensionService } from './dimension.service';
import { State } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { environment } from 'src/environments/environment';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { IGluesetLamellaInstruction } from '@app/core/models/GluesetLamellaInstruction.model';
import { GluePlansFilters } from '@app/core/models/gluing-plans-filter.model';
import { IGluingPlanDemandVolumeReportDTO } from '@app/core/models/gluing-plan-demand-volume-report';
import { IGluingPlanDemandVolumeReportAltDTO } from '@app/core/models/gluing-plan-demand-volume-report-alt';
import { GlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';
@Injectable({
  providedIn: 'root'
})

export class GluePlanService extends BehaviorSubject<GridDataResult> {
  constructor(
    public http: HttpClient,
    public dimensioService: DimensionService,
  ) {
    super(null);
  }

  public selectedPlan = new Subject<GluingPlan>();
  public _selectedPlan: GluingPlan | undefined;

  private gluplanUri = `${environment.apiUrl}GluingPlan`;

  public setSelectedPlan(gp: GluingPlan) {
    this.selectedPlan.next(gp);
    this._selectedPlan = gp;
  }

  public query(state: State): void {
    const filter = <GluePlansFilters>{};

    filter.skip = state.skip;
    filter.take = state.take;

    if (state.filter.filters) {
      state.filter.filters.forEach((x: any) => {
        if (x.field === 'gluingPlanID' && x.value !== '') {
          filter.gluingPlanID = +x.value;
        } else if (x.field === 'stateID' && x.value !== '') {
          filter.glueSetStateId = +x.value;
        } else if (x.field === 'dimension' && x.value !== '') {
          filter.beamLaminaDimentionId = +x.value;
        } else if (x.field === 'name' && x.value !== '') {
          filter.name = x.value;
        } else if (x.field === 'instruction' && x.value !== '') {
          filter.instruction = x.value;
        } else if (x.field === 'plannedExecutionStartDate' && x.value !== '') {
          filter.plannedExecutionStartDate = x.value;
        } else if (x.field === 'plannedExecutionEndDate' && x.value !== '') {
          filter.plannedExecutionEndDate = x.value;
        } else if (x.field === 'isContinuousPressGroup' && x.value !== '') {
          filter.isContinuousPressGroup = x.value;
        } else if (x.field === 'isStaticalPressGroup' && x.value !== '') {
          filter.isStaticalPressGroup = x.value;
        }
      });
    }

    this.getGluePlans(filter.glueSetStateId ? [filter.glueSetStateId] : [], filter.gluingPlanID, filter.name, filter.instruction, filter.beamLaminaDimentionId, filter.plannedExecutionStartDate, filter.plannedExecutionEndDate, filter.isContinuousPressGroup, filter.isStaticalPressGroup, filter.skip, filter.take).subscribe((x) => super.next(x));
  }

  getGluePlans(
    glueSetStateId: number[],
    gluingPlanID: number | null = null,
    name: string | null = null,
    instruction: string | null = null,
    beamLaminaDimentionId: number | null = null,
    plannedExecutionStartDate: Date | null = null,
    plannedExecutionEndDate: Date | null = null,
    isContinuousPressGroup: Boolean | null = null,
    isStaticalPressGroup: Boolean | null = null,
    skip: number = 0,
    take: number = 80): Observable<GridDataResult> {
    let p = new HttpParams();

    if (gluingPlanID) {
      p = p.set('gluingPlanID', gluingPlanID.toString());
    }

    if (name) {
      p = p.set('name', name);
    }

    if (instruction) {
      p = p.set('instruction', instruction);
    }

    for (const i in glueSetStateId) {
      p = p.append('glueSetStateId', glueSetStateId[i].toString());
    }

    if (plannedExecutionStartDate) {
      p = p.set('plannedExecutionStartDate', plannedExecutionStartDate.toDateString());
    }

    if (plannedExecutionEndDate) {
      p = p.set('plannedExecutionEndDate', plannedExecutionEndDate.toDateString());
    }

    if (beamLaminaDimentionId) {
      p = p.set('beamLaminaDimentionId', beamLaminaDimentionId.toString());
    }

    if (isContinuousPressGroup) {
      p = p.set('isContinuousPressGroup', isContinuousPressGroup.toString());
    }

    if (isStaticalPressGroup) {
      p = p.set('isStaticalPressGroup', isStaticalPressGroup.toString());
    }

    return this.http.get<GridDataResult>(this.gluplanUri, { params: p.set('skip', skip.toString()).set('take', take.toString()) }).pipe(map((response: GridDataResult) => {
      response.data.map(item => {
        item.plannedExecutionEndDate = new Date(item.plannedExecutionEndDate);
        item.plannedExecutionStartDate = new Date(item.plannedExecutionStartDate);
        return item;
      });
      return response;
    }));
  }

  getGluingPlan(id: number): Observable<GluingPlan> {
    return this.http.get<GluingPlan>(`${this.gluplanUri}/${id}`);
  }

  getGluingPlans(list: number[] = [], isContinuousPressGroup: boolean = null, isStaticalPressGroup: boolean = null): Observable<GridDataResult> {
    return this.getGluePlans([...list], null, null, null, null, null, null, isContinuousPressGroup, isStaticalPressGroup);
  }

  deleteGluingPlan(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.gluplanUri}/${id}`);
  }

  addGluingPlan(gluingPlan: GluingPlan): Observable<GluingPlan> {
    return this.http.post<GluingPlan>(this.gluplanUri, gluingPlan);
  }

  updateGluingPlan(gluingPlan: GluingPlan): Observable<GluingPlan> {
    return this.http.put<GluingPlan>(`${this.gluplanUri}/${gluingPlan.gluingPlanID}`, gluingPlan);
  }

  getLamNeed(id: number): Observable<ILamellaNeedReportDTO> {
    return this.http.get<ILamellaNeedReportDTO>(`${this.gluplanUri}/lam-need/${id}`);
  }

  getGluingPlanDemandVolumeReport(id: number): Observable<IGluingPlanDemandVolumeReportDTO> {
    return this.http.get<IGluingPlanDemandVolumeReportDTO>(`${this.gluplanUri}/demand-volume/${id}`);
  }

  getGluingPlanDemandVolumeReportAlt(id: number): Observable<IGluingPlanDemandVolumeReportAltDTO> {
    return this.http.get<IGluingPlanDemandVolumeReportAltDTO>(`${this.gluplanUri}/demand-volume-alt/${id}`);
  }

  getGluePlansByGluesetId(gluesetId: number): Observable<GluingPlan> {
    return this.http.get<GluingPlan>(`${this.gluplanUri}/getByGlueSetID/${gluesetId}`);
  }

  isDisabledEndDate(selectedGluePlan: GluingPlan) {
    return selectedGluePlan?.glueSetStateId !== GlueSetState.GENERATED &&
      selectedGluePlan?.glueSetStateId !== GlueSetState.PLANNED &&
      selectedGluePlan?.glueSetStateId !== GlueSetState.RELEASED;
  }

  getGlueSetLamellaInstructions(glueplanId): Observable<IGluesetLamellaInstruction[]> {
    return this.http.get<IGluesetLamellaInstruction[]>(`${this.gluplanUri}/getGlueSetLamellaInstructions/${glueplanId}`);
  }
}

export interface ILamellaNeedReportDTO {
  lamNeeds: ILamellaNeedDTO[];
  volume: number;
  volumePending: number;
}

export interface ILamellaNeedDTO {
  code: string;
  volume: number;
  pendingVolume: number;
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { EntityRelationDirection } from '@app/core/services/http-services/model/entity.service';
import { EnumToObject } from '@app/shared/helpers/transform-enum-to-object.helper';
import { IEnumKeyValue } from '@app/core/models/enumKeyValue.model';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { SVGIcon } from '@progress/kendo-angular-icons';
import { minusIcon, plusIcon } from '@progress/kendo-svg-icons';


marker('App.Direction');

@Component({
  selector: 'app-select-entity-relation-direction',
  template: `
   <kendo-buttongroup selection="single">
          <button
            kendoButton
            type="button"
            [toggleable]="true"
            [svgIcon]="plusIcon"
            size="large"
            [selected]="isProduce"
            themeColor="primary"
            title="{{'App.ProduceDirection' | translate}}"
            (click)="onChangedVal(0)"
          ></button>
          <button
            kendoButton
            type="button"
            [toggleable]="true"
            [svgIcon]="minusIcon"
            [selected]="isConsume"
            themeColor="primary"
            title="{{'App.ConsumeDirection' | translate}}"
            (click)="onChangedVal(1)"
          ></button>
        </kendo-buttongroup>
  `,

  styleUrls: ['./select-entity-relation-direction.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectEntityRelationDirectionComponent
    },
    EnumToObject
  ]
})
export class SelectEntityRelationDirectionComponent implements OnInit, ControlValueAccessor {
  relationDirection: IEnumKeyValue[];
  selectedDirectionValue: number = null;
  plusIcon:SVGIcon = plusIcon;
  minusIcon:SVGIcon = minusIcon;

 @Output() onSelectDirection: EventEmitter<IEnumKeyValue> = new EventEmitter<IEnumKeyValue>();

  @Input() label: string = 'App.Direction';
  @Input() showLable: boolean = false;

  private onTouched!: Function;
  private onChanged!: Function;

  public isProduce = false;
  public isConsume = false;

  constructor(private enumToObject: EnumToObject) {
  }

  ngOnInit(): void {
    this.relationDirection = this.enumToObject.transform(EntityRelationDirection);
  }

  onChangedVal(directionValue: number) {
    this.onTouched();

    if(directionValue == this.selectedDirectionValue)
      return;

    if (directionValue != null) {
      const e = this.relationDirection.find(d => d.value === directionValue);
      this.selectedDirectionValue = directionValue;
      this.onSelectDirection.emit(e);
    } else {
      this.selectedDirectionValue = null;
      this.onSelectDirection.emit(null);
    }

    this.onChanged(this.selectedDirectionValue);
  }

  writeValue(value: number): void {

    if(value === 1){
      this.isConsume = true;
      this.isProduce = false;
    } if (value == 0){
      this.isConsume = false;
      this.isProduce = true;
    }

    this.selectedDirectionValue = value;
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';

import { BeamMaterialTypeService } from '@app/core/services/http-services/gluelam/beam-material-type.service';
import { IBeamMaterialType } from '@app/core/services/http-services/gluelam/glue-set.service';
import { IUnit } from '@app/core/models/unit.model';
import { UnitService } from '@app/core/services/http-services/common/unit-service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('App.UnitCode');

@Component({
  selector: 'app-select-beam-material-type',
  template: `
            <kendo-formfield>
            <kendo-label text="{{ 'OrderViewTranslation.BeamMaterialType' | translate }}"></kendo-label>
            <kendo-dropdownlist [data]="beamMaterialTypes" valueField="beamMaterialTypeID" textField="name"
              (valueChange)="onChangedVal($event)" [value]="selectedTypeId" [valuePrimitive]="true" [loading]="loading">
              <!--<ng-template kendoDropDownListValueTemplate let-dataItem>
                {{ ('OrderViewTranslation.beamMaterialType.' + dataItem.name) | translate}}
              </ng-template>
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                {{ ('OrderViewTranslation.beamMaterialType.' + dataItem.name) | translate}}
              </ng-template>-->
            </kendo-dropdownlist>
          </kendo-formfield>
`,
  styleUrls: ['./select-beam-material-type.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectBeamMaterialTypeComponent
    }
  ]
})
export class SelectBeamMaterialTypeComponent implements OnInit, ControlValueAccessor, OnDestroy {
  beamMaterialTypes: IBeamMaterialType[] = [];
  selectedTypeId:number;
  loading:boolean = true;

  @Input() showLable:boolean = false;
  @Input() disableTextbox:boolean = false;
  @Input() hiddenTypeIds:number[] = [];


  private onChanged: Function = () => {};
  private onTouched: Function = () => {};
  private destroy$ = new Subject<void>();

  constructor(private readonly beamMaterialTypeService: BeamMaterialTypeService) { }

  async ngOnInit() {
    this.beamMaterialTypes = await firstValueFrom(this.beamMaterialTypeService.query());
    this.loading = false
  }

  onChangedVal(selectedTypeId:number) {
    this.onTouched();
    this.onChanged(selectedTypeId);
  }

  writeValue(id: number): void {
    this.selectedTypeId = id;
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}

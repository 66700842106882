<div>
  <kendo-stepper [steps]="optiSteps" [stepType]="stepType" [(currentStep)]="current">
    <ng-template kendoStepperLabelTemplate let-step>
      <div style="display: flex; flex-direction: column;">
        <span>{{ step.label }}</span>
        <span *ngIf="step.time">{{ step.time }} sec</span>
        <span>{{ step.state }}</span>
      </div>
    </ng-template>
  </kendo-stepper>
  <form [formGroup]="form" class="col">
    <div class="row">
      <kendo-formfield>
        <div class="row mb-1">
          <app-machine-selector-dropdown formControlName="machineId" (selectedMachine)="onMachineSelected($event)"
            [readonly]="true">
          </app-machine-selector-dropdown>
        </div>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label translate>OptimizationOrderTranslation.MaxGluePlanLength</kendo-label>
        <kendo-numerictextbox format="n0" formControlName="maxGluePlanLength"></kendo-numerictextbox>
      </kendo-formfield>
    </div>
    <div class="row">
      <kendo-formfield>
        <kendo-label translate>OptimizationOrderTranslation.MaxPressLength</kendo-label>
        <kendo-numerictextbox format="n0" formControlName="maxPressLength"></kendo-numerictextbox>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label translate>OptimizationOrderTranslation.MaxPressWidth</kendo-label>
        <kendo-numerictextbox format="n0" formControlName="maxPressWidth"></kendo-numerictextbox>
      </kendo-formfield>
    </div>

    <div class="row">
      <kendo-formfield>
        <kendo-label translate>OptimizationOrderTranslation.MinPressHight</kendo-label>
        <kendo-numerictextbox format="n0" formControlName="minPressHight"></kendo-numerictextbox>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label translate>OptimizationOrderTranslation.MaxPressHight</kendo-label>
        <kendo-numerictextbox format="n0" formControlName="maxPressHight"></kendo-numerictextbox>
      </kendo-formfield>
    </div>

    <div class="row">
      <kendo-formfield>
        <kendo-label translate>OptimizationOrderTranslation.MaxLayers</kendo-label>
        <kendo-dropdownlist [data]="numberOfLayers" [valueField]="'value'" [textField]="'key'"
          formControlName="maxLayers" [valuePrimitive]="true">
        </kendo-dropdownlist>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label translate>OptimizationOrderTranslation.MaxBeamInBeamMaterial</kendo-label>
        <kendo-numerictextbox format="n0" formControlName="maxBeamInBeamMaterial"></kendo-numerictextbox>
      </kendo-formfield>
    </div>
    <div class="row">
      <kendo-formfield>
        <kendo-label translate>
          OptimizationOrderTranslation.MaxGlueplans
        </kendo-label>
        <kendo-numerictextbox format="n0" formControlName="maxGlueplans"></kendo-numerictextbox>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label translate>
          OptimizationOrderTranslation.MaxGluesets
        </kendo-label>
        <kendo-numerictextbox format="n0" formControlName="maxGluesets"></kendo-numerictextbox>
      </kendo-formfield>
    </div>
    <div class="row k-mb-3">
      <kendo-formfield>
        <kendo-label translate>
          OptimizationOrderTranslation.Optimization_Model
        </kendo-label>
        <kendo-dropdownlist [data]="watsonDeployedModels" formControlName="deployedModelId" [valuePrimitive]="true"
          [textField]="'Name'" [valueField]="'Id'">
        </kendo-dropdownlist>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label translate>
          OptimizationOrderTranslation.MaxSolveTime
        </kendo-label>
        <kendo-numerictextbox format="n0" formControlName="maxSolveTime"></kendo-numerictextbox>
      </kendo-formfield>
    </div>
    <div>
      <div class="row">
        <span class="width-200 k-font-bold">ExecutionStatus:</span>
        {{jobState?.status ?? 'Not Started'}}
      </div>
      <div class="row">
        <span class="width-200 k-font-bold">SolveStatus:</span>
        {{jobState?.solveStatus ?? 'Not Started'}}
      </div>
      <div class="row">
        <span class="width-200 k-font-bold">JobID:</span>
        {{JobUrl ?? 'Not Started'}}
      </div>
    </div>
  </form>
</div>
<kendo-dialog-actions layout="end">
  <button kendoButton themeColor="error" (click)="close()">
    {{ "App.Close" | translate }}
  </button>
  <div style="flex-grow: 1;"></div>
  <button kendoButton [disabled]="true" [primary]="true">
    {{ "OptimizationOrderTranslation.SetDefault" | translate }}
  </button>
  <button kendoButton [disabled]="true" [primary]="true">
    {{ "App.SaveSetting" | translate }}
  </button>
  <button kendoButton [primary]="true" (click)="getCsv()" [disabled]="!form.valid" translate>
    {{ "OptimizationOrderTranslation.DownloadCsvFile" | translate }}
  </button>
  <button kendoButton [primary]="true" (click)="runOptimazation()" [disabled]="!form.valid" translate>
    {{ "OptimizationOrderTranslation.Optimize" | translate }}
  </button>
</kendo-dialog-actions>

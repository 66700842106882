<div *ngIf="!showDropdown">
  <kendo-formfield *ngIf="showLable">
    <kendo-floatinglabel [text]="label ? label : ('EntityTranslation.DefaultValue' | translate)" class="min-width-dropdown">
      <kendo-textbox [value]="selectedValidValueText | ReplaceDotToCommaPipe" (valueChange)="onChange($event)"></kendo-textbox>
    </kendo-floatinglabel>
  </kendo-formfield>
  <kendo-formfield *ngIf="!showLable">
      <kendo-textbox [value]="selectedValidValueText | ReplaceDotToCommaPipe" (valueChange)="onChange($event)"></kendo-textbox>
  </kendo-formfield>
</div>

<div *ngIf="showDropdown" style="width: 100%;">
<kendo-floatinglabel [text]="label | translate" *ngIf="showLable" class="min-width-dropdown">
  <kendo-dropdownlist
    [data]="validValues"
    [textField]="textField"
    [valueField]="textField"
    [valuePrimitive]="true"
    [loading]="loading"
    [(ngModel)]="selectedValidValue"
    (selectionChange)="onChangedVal($event)">
  </kendo-dropdownlist>
</kendo-floatinglabel>

<kendo-dropdownlist
  [data]="validValues"
  [textField]="textField"
  [valueField]="textField"
  [loading]="loading"
  [valuePrimitive]="true"
  [(value)]="selectedValidValue"
  (selectionChange)="onChangedVal($event)"
  *ngIf="!showLable && validValues.length > 0"
  style="flex-grow: 1;">
</kendo-dropdownlist>
</div>

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { State } from '@progress/kendo-data-query';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { DataStateChangeEvent, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { KendoGridSetUp } from '@app/core/services/custom-services/kendo-grid-setup';
import { InstructionLineService } from '@app/core/services/http-services/operative/instruction-line.service';
import { IInstructionLineDTO } from '@app/core/models/instruction-line.model';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { CreateInstructionLineForm } from '@app/core/models/forms/operative/Instruction/create-instruction-line-form.model';
import { Subject, takeUntil } from 'rxjs';

marker('Operative.InstructionLineSaveSuccess');
marker('Operative.InstructionLineUpdateSuccess');
marker('Operative.InstructionLineDeleteMsg');
marker('Operative.InstructionLine');
marker('Operative.CanNotDeleteInstructionLineIsBindToPOLine');
marker('Operative.CanNotDeleteInstructionIsBindToInstructionLine');

const createInstructionForm = (p: IInstructionLineDTO) => new FormGroup<CreateInstructionLineForm>({
  instructionLineId: new FormControl<number>(p.instructionLineId),
  instructionID: new FormControl<number>(p.instructionID, [Validators.required]),
  machineId: new FormControl<number>(p?.machineId, [Validators.required]),
  machineName: new FormControl<string>(''),
  instructionName: new FormControl<string>('')
});

@Component({
  selector: 'app-instruction-line',
  templateUrl: './instruction-line.component.html',
  styleUrls: ['./instruction-line.component.css']
})

export class InstructionLineComponent implements OnDestroy, OnInit {
  state: State = {
    skip: 0,
    take: KendoGridSetUp.pageSize,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  gridInstructionLineData: GridDataResult;
  instructionToRemove: number = null;
  machines: IMachineDTO[] = [];
  isNew: boolean = false;
  formGroup: FormGroup;

  private editedRowIndex: number;

  @ViewChild('instructionLineEditorGrid')
  private instructionLineEditorGrid: GridComponent;
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(private readonly instructionLineService: InstructionLineService,
    private readonly notificationService: AppNotificationService) { }

  ngOnInit(): void {
    this.get();
  }

  get() {
    this.instructionLineService.getInstructionsLines(this.state)
      .pipe(takeUntil(this.destroy$))
      .subscribe(d => {
        this.gridInstructionLineData = d;
      });
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.get();
  }

  addInstructionLineRow() {
    this.closeEditor(this.instructionLineEditorGrid);
    this.formGroup = createInstructionForm(<IInstructionLineDTO>{ instructionLineId: 0 });
    this.instructionLineEditorGrid.addRow(this.formGroup);
  }

  editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(this.instructionLineEditorGrid);
    this.formGroup = createInstructionForm(dataItem);
    sender.editRow(rowIndex, this.formGroup);
  }

  cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  saveHandler({ sender, rowIndex, formGroup, isNew, dataItem }): void {
    if (isNew) {
      const newInstructionLine: IInstructionLineDTO = <IInstructionLineDTO>{
        instructionLineId: 0,
        instructionID: formGroup.get('instructionID').value,
        machineId: formGroup.get('machineId').value
      };

      this.instructionLineService.insert(newInstructionLine).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.get();
        sender.closeRow(rowIndex);
        this.notificationService.notifySucsessAppChanel('Operative.InstructionLineSaveSuccess');
      });
    } else {
      const updatedInstructionLine: IInstructionLineDTO = <IInstructionLineDTO>{
        instructionLineId: formGroup.get('instructionLineId').value,
        instructionID: formGroup.get('instructionID').value,
        machineId: formGroup.get('machineId').value
      };

      this.instructionLineService.update(updatedInstructionLine).pipe(takeUntil(this.destroy$)).subscribe(d => {
        this.get();
        sender.closeRow(rowIndex);
        this.notificationService.notifySucsessAppChanel('Operative.InstructionLineUpdateSuccess');
      });
    }
  }

  removeHandler({ dataItem }) {
    this.instructionToRemove = dataItem.instructionLineId;
  }

  confirmRemove(isRemove: boolean) {
    if (isRemove) {
      const instruction: IInstructionLineDTO = <IInstructionLineDTO>{
        instructionLineId: this.instructionToRemove
      };

      this.instructionLineService.remove(instruction).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.get();
        this.notificationService.notifySucsessAppChanel('Operative.InstructionLineDeleteMsg');
      });
    }
    this.instructionToRemove = null;
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

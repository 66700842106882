import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { State } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import { ScheduleService } from '@app/core/services/http-services/operative/schedule.service';
import { IScheduleLineDTO } from '@app/core/models/scheduleLineDTO.model';
import { ICalendarLinesCriteriaInputDTO } from '@app/core/models/CalendarLinesCriteriaInputDTO';
import { IScheduleDTO } from '@app/core/models/scheduleDTO.model';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';
import { Subject, takeUntil } from 'rxjs';

marker('Operative.CanNotDeleteScheduleShiftIsBindToSchedule');
marker('Operative.ScheduleDeleted');

@Component({
  selector: 'app-schedule-grid',
  templateUrl: './schedule-grid.component.html',
  styleUrls: ['./schedule-grid.component.css']
})
export class ScheduleGridComponent implements OnInit, OnDestroy {
  @Input() set refreshScheduleGrid(isRefresh: boolean) {
    if (isRefresh) {
      this.loadScheduleData();
    }
  }

  state: State = {
    skip: 0,
    take: 30,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  scheduleData: IScheduleDTO[] = [];
  scheduleDataLoading: boolean = false;
  itemToRemoveId: number = undefined;

  private destroy$ = new Subject<void>();

  constructor(private readonly scheduleService: ScheduleService
    , private readonly spinner: NgxSpinnerService
    , private readonly appNotificationService: AppNotificationService) {
  }

  ngOnInit(): void {
    this.loadScheduleData();
  }

  editSchedule(scheduleId: number) {
    const dialogRef = this.scheduleService.openDialog(scheduleId);
    dialogRef.result
    .pipe(takeUntil(this.destroy$))
    .subscribe((d) => {
      if (d instanceof DialogCloseResult) {
        dialogRef.close();
      }
      this.loadScheduleData();
    });
  }

  removeHandler({ dataItem }): void {
    this.itemToRemoveId = dataItem.scheduleId;
  }

  confirmRemove(isRemove:boolean) {
    if (isRemove) {
      this.spinner.show('scheduleGridLoader');
      this.scheduleService
        .removeSchedule(this.itemToRemoveId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res) => {
            this.removeSCheduleResponse(res);
          },
          error: () => {
            this.spinner.hide('scheduleGridLoader');
          }
        });
    }
    this.itemToRemoveId = undefined;
  }

  openCalender(scheduleLines: IScheduleLineDTO[]) {
    const criteria = <ICalendarLinesCriteriaInputDTO> {
      scheduleLines,
      shiftIds: []
    };

    const dialogRef = this.scheduleService.showCalender(criteria, 'App.ScheduleCalendar');
    dialogRef.result
    .pipe(takeUntil(this.destroy$))
    .subscribe((d) => {
      if (d instanceof DialogCloseResult) {
        dialogRef.close();
      }
    });
  };

  private loadScheduleData() {
    this.scheduleService.query()
      .pipe(takeUntil(this.destroy$))
      .subscribe(d => {
        this.scheduleData = d.data;
      });
  }

  private removeSCheduleResponse(response: boolean) {
    this.spinner.hide('scheduleGridLoader');
    if (response) {
      this.appNotificationService.notifySucsessAppChanel('Operative.ScheduleDeleted');
      this.loadScheduleData();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

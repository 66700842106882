import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { OrderView } from '@app/modules/gluelam/controls/order-view/order-view.component';
import {
  BeamMaterialGlueSet,
  BeamMaterialGlueSetLayer,
  BeamMaterialGlueSetState,
  IBeamMaterialGlueSets,
  ProposalState
} from '@app/core/models/beam-material-glue-set.model';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlueSetService } from '@app/core/services/http-services/gluelam/glue-set.service';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { Demandline } from '@app/core/models/demand-line.model';
import { OptiInputModel, OptimizationResponce } from '@app/core/models/optimization-order.model';
import { DeamndService, UpdateSumUIQtyDTO } from '@app/core/services/http-services/gluelam/demand.service';
import { BeamLamminaDimension } from '@app/core/models/beam-lammina-dimension.model';
import { OptimazationOrderEditor } from '../controls/optimization-order/optimization-order.component';
import { GlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { SVGIcon, plusCircleIcon } from '@progress/kendo-svg-icons';
import { TranslateService } from '@ngx-translate/core';

marker('GlueSet.GLUSET_SAVE_ERROR');
marker('GlueSet.GLUSET_UPDATED');
marker('GlueSet.BMGS_NOT_Removed');
marker('GlueSet.GLUSET_SORTED_INFO');
marker('GlueSet.BMGS_Removed');

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'glulam-planner-component',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: "d-flex-col-component"
  }
})
export class PlannerComponent implements OnInit, OnDestroy {
  @ViewChild(OrderView) orderView: OrderView;
  isSavingGlueset: boolean = false;
  selectedPlan: GluingPlan;
  _BeamMaterialGlueSetSuggestions: BeamMaterialGlueSet[] = [];
  uiQty: UpdateSumUIQtyDTO[] = [];

  get BeamMaterialGlueSetSuggestions(): BeamMaterialGlueSet[] {
    if (this.selectedState && this.selectedState.name !== 'All') {
      return this._BeamMaterialGlueSetSuggestions.filter((x) => {
        return (
          x.beamMaterialGlueSetState.glueSetStateId ===
          this.selectedState.glueSetStateId
        );
      });
    }
    return this._BeamMaterialGlueSetSuggestions;
  }
  set BeamMaterialGlueSetSuggestions(value: BeamMaterialGlueSet[]) {
    this._BeamMaterialGlueSetSuggestions = value;
  }
  states: BeamMaterialGlueSetState[] = [];
  selectedState: BeamMaterialGlueSetState;
  /**
   * All beam ordered lines. These are sent to order-row-filter view, which filters them and then set to app-order-view.
   */

  LatestDeploymentDate: Date;
  LamminaDimms: BeamLamminaDimension[] = new Array<BeamLamminaDimension>();
  ready = false;
  selectedLamminaDim: BeamLamminaDimension;
  selectedBeamOrderLines: Demandline[] = [];
  gsCols = 5;
  interval: any;
  isLeftMajor: boolean = false;
  updatedTotalBeamCount: number = 0;
  isNewGluesetsCreatedByOptimization: boolean = false;
  glueSetsIdInDB: number[] = [];
  plusCircleIcon: SVGIcon = plusCircleIcon;

  private _gluingPlan: GluingPlan;
  private subscribeRouthParams: any;
  private destroy$: Subject<void> = new Subject<void>();
  private _selectedGluPlanSubject: Subject<GluingPlan> = new Subject<GluingPlan>();

  constructor(
    private readonly notificationService: AppNotificationService,
    private readonly bmgsService: GlueSetService,
    private readonly beamOrderRowService: DeamndService,
    private readonly conf: ConfigurationService,
    private readonly gluingPlanService: GluePlanService,
    private readonly route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService
  ) {
    this.subscribeRouthParams = this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.solutionId && params.gluePlanId) {
        const solutionId = params.solutionId; // (+) converts string 'id' to a number
        const gluePlanId = params.gluePlanId;

        this.notificationService.notifySucsessAppChanel(`Loading Result for Soulution ${solutionId} into glueplan ${gluePlanId}`, 'Optimazation Soulution Loaded!');
      }
    });

    this._selectedGluPlanSubject.pipe(
      switchMap((plan: GluingPlan) => {
        this._gluingPlan = plan;
        return this.bmgsService.getGlueSetByGluingPlanId(plan.gluingPlanID);
      })
    ).subscribe({
      next: (response) => {
        this.selectedPlan = this._gluingPlan;
        this.selectedLamminaDim = this._gluingPlan.dimension;

        this.BeamMaterialGlueSetSuggestions = [];
        this.glueSetsIdInDB = [];

        if (response && response.length > 0) {
          this.BeamMaterialGlueSetSuggestions = response;
          response.forEach(gs => {
            this.glueSetsIdInDB.push(gs.beamMaterialGlueSetID);
          });
          this.populateState();
        }
        this.onCalcTotalsPerBeamOrderRow();
        this.spinner.hide();
      },
      error: (error) => {
        console.error('Error loading data:', error);
        this.spinner.hide();
      }
    });

  }

  removeOrder(order: any) {
    this.beamOrderRowService.unselectOrderRow(order);
  }

  ngOnInit(): void {
    this.conf.load().then(() => this.SetUpSubscriptions());
  }

  SetUpSubscriptions() {
    this.beamOrderRowService.selectedOrderRows.pipe(takeUntil(this.destroy$)).subscribe((rows) => {
      this.selectedBeamOrderLines = rows;
    });

    this.gluingPlanService.selectedPlan.pipe(takeUntil(this.destroy$)).subscribe((plan: GluingPlan) => {
      this.spinner.show();
      this._selectedGluPlanSubject.next(plan);
    });
  }

  onCalcTotalsPerBeamOrderRow() {
    const updateList: UpdateSumUIQtyDTO[] = [];

    this.BeamMaterialGlueSetSuggestions.filter(g => g.beamMaterialGlueSetState.glueSetStateId < 2).forEach((bmgs) => {
      bmgs.layers.forEach((l) => {
        l.BeamMaterials.forEach((bm) => {
          bm.beamMaterialLines.forEach((bml) => {
            const u = updateList.find(g => g.demandId === bml.beamOrderRowID && bml.transportPackageId === g.transportPackageId);

            if (u) {
              u.newQty += bml.NumberOfBeams * bmgs.performedTimes;
            } else {
              updateList.push(<UpdateSumUIQtyDTO>{
                demandId: bml.beamOrderRowID,
                transportPackageId: bml.transportPackageId,
                newQty: bml.NumberOfBeams * bmgs.performedTimes,
                gluePlanId: this.selectedPlan.gluingPlanID
              });
            }
          });
        });
      });
    });

    this.uiQty = updateList;
  }

  AddEmptyBmgs() {
    const bmgsl = new BeamMaterialGlueSetLayer([]);

    const bmgs = new BeamMaterialGlueSet(
      -1,
      null,
      0,
      false,
      [bmgsl],
      0,
      0,
      null,
      true,
      this.selectedLamminaDim,
      null,
      false,
      false,
      this.conf,
      null,
      ProposalState,
      1
    );

    this.BeamMaterialGlueSetSuggestions.unshift(bmgs);
    this.populateState();
  }

  openOptimizationEditor(): DialogRef {
    const dialogRef = this.dialogService.open({
      content: OptimazationOrderEditor,
      title: this.translateService.instant('OptimizationOrderTranslation.OrderOptimization'),
      width: '700px',
      height: '1100px',
    });

    const content = dialogRef.content.instance as OptimazationOrderEditor;
    content.input = <OptiInputModel>{
      BeamLamminaDimension: this.selectedLamminaDim,
      BeamOrderLines: this.selectedBeamOrderLines
    };

    dialogRef.result.pipe(takeUntil(this.destroy$)).subscribe((result: OptimizationResponce) => {
      if (result) {
        this.isNewGluesetsCreatedByOptimization = result.beamMaterialGlueSets.length > 0;
        result.beamMaterialGlueSets.forEach((element: BeamMaterialGlueSet) => {
          element.beamMaterialGlueSetState = ProposalState;
        });

        this.BeamMaterialGlueSetSuggestions = result.beamMaterialGlueSets.concat(
          this.BeamMaterialGlueSetSuggestions.filter(
            (bmgs) => bmgs.beamMaterialGlueSetID > 0
          )
        );

        this.populateState();
        this.onCalcTotalsPerBeamOrderRow();
      }
    });
    return dialogRef
  }

  disableSaveGluesetsButton(): boolean {
    return !this.selectedPlan ||
      this.selectedPlan?.glueSetStateId >= GlueSetState.PLANNED ||
      this.isSavingGlueset ||
      this.BeamMaterialGlueSetSuggestions.filter(gp => gp.save || gp.remove).length === 0;
  }

  saveGluesetsToGluingPlan() {
    this.isSavingGlueset = true;
    this.spinner.show();
    this.notificationService.notifyInfoAppChanel('Saving');
    const glueSets = <IBeamMaterialGlueSets>{
      glueSets: this.BeamMaterialGlueSetSuggestions.filter(gp => gp.save || gp.remove),
      existingGlueSetsId: this.glueSetsIdInDB
    };

    this.bmgsService
      .updateBeamMaterialGlueSetForGluingPlan(
        this.selectedPlan.gluingPlanID,
        glueSets
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (gpSaveResult) => {
          this.isSavingGlueset = false;
          let hasError: boolean = false;
          const warnings: string[] = [];

          this.updatedTotalBeamCount = gpSaveResult.glueplanNumberOfBeams;
          gpSaveResult.glusetSaveResults.forEach(gp => {
            const bmgs = this.BeamMaterialGlueSetSuggestions.find(g => g.guid === gp.guiID);

            if (gp.isSuccsessfull && gp.errorMessage) {
              gp.warnings.forEach(element => {
                if (!warnings.includes(element)) {
                  warnings.push(element);
                }
              });
              bmgs.errorMessageText = gp.errorMessage;
              bmgs.save = false;
            } else if (gp.errorMessage) {
              this.notificationService.notifyErrorAppChanel(gp.errorMessage, 'GlueSet.GLUSET_SAVE_ERROR');
              hasError = true;
              console.log(`Error Found ${gp.errorMessage}`);
              bmgs.errorMessageText = gp.errorMessage;
              bmgs.save = true;
            } else if (gp.isSuccsessfull) {
              bmgs.errorMessageText = null;
              bmgs.save = false;
            } else {
              bmgs.save = true;
            }

            bmgs.beamMaterialGlueSetID = gp.glusetID;
            if (gp.isSuccsessfull && !this.glueSetsIdInDB.includes(gp.glusetID)) {
              this.glueSetsIdInDB.push(gp.glusetID);
            }
          });

          if (!hasError) {
            this.bmgsService.isDirty = false;
            this.isNewGluesetsCreatedByOptimization = false;
            this.notificationService.notifySucsessAppChanel('GlueSet.GLUSET_UPDATED');
            this.notificationService.notifyInfoAppChanel('GlueSet.GLUSET_SORTED_INFO');
          }

          warnings.forEach(element => {
            this.notificationService.notifyWarningAppChanel(element, 'GlueSet.GLUSET_SAVE_ERROR');
          });

          this.spinner.hide();
        },
        error: (e: HttpErrorResponse) => {
          this.spinner.hide();
          this.isSavingGlueset = false;
        }
      });
  }

  DeleteBeamMaterialGlueSet(bmgs: BeamMaterialGlueSet, index: number) {
    this.spinner.show();
    if (!bmgs.beamMaterialGlueSetID || bmgs.beamMaterialGlueSetID === -1) {
      this.BeamMaterialGlueSetSuggestions.splice(index, 1);
      this.spinner.hide();
    } else {
      this.bmgsService.removeGlueSet(bmgs.beamMaterialGlueSetID, bmgs.guid, this.selectedPlan.gluingPlanID).pipe(takeUntil(this.destroy$)).subscribe(res => {
        if (res.isSuccsessfull) {
          this.updatedTotalBeamCount = res.glueplanNumberOfBeams;
          this.notificationService.notifySucsessAppChanel(
            'GlueSet.BMGS_Removed'
          );
          this.BeamMaterialGlueSetSuggestions = this.BeamMaterialGlueSetSuggestions.filter(b => b.guid !== res.guiID);
          this.glueSetsIdInDB = this.glueSetsIdInDB.filter(i => i !== bmgs.beamMaterialGlueSetID);
        } else {
          this.notificationService.notifySucsessAppChanel(
            'GlueSet.BMGS_NOT_Removed'
          );
          bmgs.errorMessageText = res.errorMessage;
        }
        this.onCalcTotalsPerBeamOrderRow();
        this.spinner.hide();
      });
    }
  }

  resizeGrid(e: boolean) {
    this.isLeftMajor = e;
    this.gsCols = e ? 4 : 5;
  }

  onConfirmUnsavedGlueSet(result: boolean) {
    if (result) {
      this.isNewGluesetsCreatedByOptimization = false;
    }
  }

  private populateState() {
    this.states = [];
    const allState = new BeamMaterialGlueSetState(
      -1,
      'All',
      'All PlaceHolder',
      false,
      []
    );
    this.states.push(allState);

    this.BeamMaterialGlueSetSuggestions.forEach((bmgs) => {
      if (
        !this.states.find((x) => x.name === bmgs.beamMaterialGlueSetState.name)
      ) {
        this.states.push(bmgs.beamMaterialGlueSetState);
      }
    });

    this.selectedState = allState;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

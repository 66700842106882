import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMachineGroupDTO, MachineGroupService } from '@app/core/services/http-services/operative/machine-group.service';
import { ISchedulerCalendarColorDTO, IStaticalSchedulerResultLineDTO } from '@app/core/models/glue-lam-scheduler-result-dto';
import { SVGIcon, arrowLeftIcon, arrowRightIcon, downloadIcon, pencilIcon, printIcon, searchIcon, trashIcon } from '@progress/kendo-svg-icons';
import { Subject, takeUntil } from 'rxjs';

import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { DatePipe } from '@angular/common';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';
import { DownloadFileHelper } from '@app/shared/helpers/download-file-helper';
import { GlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';
import { GluelamStaticalMediatorService } from '../services/gluelam-statical-mediator.service';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { IGluPlanModalResult } from '@app/core/models/glueplan-modal-input.model';
import { LegendItemClickEvent } from '@progress/kendo-angular-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { PopoverAnchorDirective } from '@progress/kendo-angular-tooltip';
import { ProductionFileService } from '@app/core/services/http-services/gluelam/production-file.service';
import { StaticalSchedulerService } from '@app/core/services/http-services/gluelam/statical-scheduler.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker([
  'Scheduler.StartDate',
  'Scheduler.EndDate',
  'Scheduler.TimeResolution',
  'Scheduler.LengthResolution'
]);

@Component({
  selector: 'app-statical-scheduler-v2',
  templateUrl: './statical-scheduler-v2.component.html',
  styleUrls: ['./statical-scheduler-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: "d-flex-col-component"
  }
})
export class StaticalSchedulerComponentV2 implements OnInit, OnDestroy {
  gluelamSchedulerForm: FormGroup;
  machineGroup: IMachineGroupDTO;
  yAxisCategories: string[] = [];
  xAxisCategories: number[] = [];
  scaleFactorY:number = 4;
  maxX:number = 50000;
  maxY:number = 60 * 5 * 24 * this.scaleFactorY;
  viewBox:string = `0 0 ${this.maxX} ${this.maxY}`
  transform:string = `scale(1, ${this.scaleFactorY})`
  fontSize: 300;
  gluePlans: IStaticalSchedulerResultLineDTO[] = [];
  availableShifts:ISchedulerCalendarColorDTO[] = [];
  gluingPlanId: number;

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild("anchor", { static: false })private popovera: PopoverAnchorDirective;

  public searchIcon: SVGIcon = searchIcon;
  public downloadIcon: SVGIcon = downloadIcon;
  public printIcon: SVGIcon = printIcon;
  public trashIcon: SVGIcon = trashIcon;
  public pencilIcon: SVGIcon = pencilIcon;
  public arrowRightIcon: SVGIcon = arrowRightIcon;
  public arrowLeftIcon: SVGIcon = arrowLeftIcon;

  constructor(private readonly fb: FormBuilder,
    private readonly machineGroupService: MachineGroupService,
    private readonly staticalSchedulerService: StaticalSchedulerService,
    private readonly gluelamStaticalMediatorService: GluelamStaticalMediatorService,
    private readonly productionFileService: ProductionFileService,
    private readonly datePipe: DatePipe,
    private readonly notificationService: AppNotificationService,
    private readonly spinnerService: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    const startDate = new Date(new Date().setHours(0, 0, 0, 0));
    const endDate = this.getDate(startDate, 4);

    this.gluelamSchedulerForm = this.fb.group({
      machineId: [null, Validators.required],
      startDate: [startDate, Validators.required],
      endDate: [endDate, Validators.required]
    });

    this.gluelamSchedulerForm
      .controls['startDate']
      .valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((date: Date) => {
        const end = this.getDate(date, 4);
        this.gluelamSchedulerForm.controls['endDate'].setValue(end);
      });

    this.machineGroupService
      .query(null, true, false)
      .pipe(takeUntil(this.destroy$))
      .subscribe((machineGroup) => {
        this.machineGroup = machineGroup.data.length > 0 ? machineGroup.data[0] : null;
      });
  }

  prev() {
    const startDate = this.gluelamSchedulerForm.controls['startDate'].value;
    const endDate = this.gluelamSchedulerForm.controls['endDate'].value;
    endDate.setDate(startDate.getDate() - 1);
    startDate.setDate(startDate.getDate() - 5);
    this.gluelamSchedulerForm.controls['endDate'].setValue(endDate);
    this.gluelamSchedulerForm.controls['startDate'].setValue(startDate);
  }
  next() {
    const startDate = this.gluelamSchedulerForm.value.startDate;
    const endDate = this.gluelamSchedulerForm.value.endDate;
    startDate.setDate(endDate.getDate() + 1);
    endDate.setDate(endDate.getDate() + 5);
    this.gluelamSchedulerForm.controls['endDate'].setValue(endDate);
    this.gluelamSchedulerForm.controls['startDate'].setValue(startDate);
  }

  onSubmit() {
    this.spinnerService.show('glueLamSchedulerLoader');

    this.staticalSchedulerService
      .get(this.gluelamSchedulerForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {

        this.maxX = result.width
        this.maxY = result.height * this.scaleFactorY

        this.viewBox = `0 0 ${this.maxX} ${this.maxY}`

        this.gluePlans = result.data;

        this.availableShifts = result.availableShifts;

        this.spinnerService.hide('glueLamSchedulerLoader');
      });
  }

  onGluePlanEdit(gluePlan:GluingPlan) {
    this.popovera.hide();

    const dialogRef = this.gluelamStaticalMediatorService.openGluingPlanModelDialog(false, gluePlan, false, true, true);

    dialogRef.result
      .pipe(takeUntil(this.destroy$))
      .subscribe((gluePlanResult: IGluPlanModalResult) => {
        if (gluePlanResult instanceof DialogCloseResult) {
          dialogRef.close();
        }
        else if (gluePlanResult.isGluePlanModified) {
          this.notificationService.notifySucsessAppChanel('GluePlan.GluePlanUpdated');
          this.onSubmit();
        }
      });
    return dialogRef;
  }

  onLegendItemClick(e: LegendItemClickEvent): void {
    e.preventDefault();
  }

  download(gluingPlanID:number) {
    this.spinnerService.show('glueLamSchedulerLoader');
    this.productionFileService.productionFileForGlueplan(gluingPlanID).then(b => {
      DownloadFileHelper.downloadFile(b, `GluePlan-${gluingPlanID}-${new Date(Date.now()).toISOString().split('T')[0]}`);
      this.spinnerService.hide('glueLamSchedulerLoader');
    });
  }

  dateLabelContent = (e: { value: number }): string => {
    if (e.value % 4 === 0 || e.value === 0 || e.value === this.yAxisCategories.length - 1) {
      const date = this.yAxisCategories[e.value];
      return this.datePipe.transform(new Date(date), 'dd.MM.YYYY - HH:MM').toString();
    }

    return '';
  };

  lenghtLabelContent = (e: { value: number }): string => {
    if (this.xAxisCategories[e.value] % 2000 === 0) {
      return this.xAxisCategories[e.value].toString();
    }

    return '';
  };

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getDate(date: Date, noOfDays: number): Date {
    const endDate = new Date(date);
    endDate.setDate(endDate.getDate() + noOfDays);
    return endDate;
  }

  public getContextData = (anchor: Element):GluingPlan => {

    var gluePlanId = anchor.getAttribute('id').replace('glue-plan-', '');

    return  this.gluePlans.find(gp => gp.gluePlanId === +gluePlanId).gluePlan

  }

  public getStyle(line:IStaticalSchedulerResultLineDTO): { fill:string, stroke:string } {

    if(line.overlaps){
      return { fill: 'rgb(255,0,0)', stroke: 'rgb(255,0,0)' };
    }

    switch (line.gluePlan.glueSetStateId) {
      case GlueSetState.RELEASED:
        return { fill: '#FE7021', stroke: '#FE7021' }
      case GlueSetState.SENT_BY_PLANNER:
      case GlueSetState.RECIVED_BY_PROD_SYSTEM:
        return { fill: '#00ffff', stroke: '#00ffff' }
      case GlueSetState.COMPLETED:
        return { fill: '#03a103', stroke: '#03a103' }
      case GlueSetState.FAIL_TO_RECEIVE_BY_PROD_SYSTEM:
      case GlueSetState.CANCELED:
        return { fill: '#f50707', stroke: '#f50707' }
      default:
        return { fill: 'rgb(0,0,255)', stroke: 'rgb(0,0,255)' }
    }
  }
}

export interface gridLine{
  offset:number;
  main:boolean;
}

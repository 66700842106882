import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { BeamMaterialGlueSet, BeamMaterialGlueSetLayer } from '@app/core/models/beam-material-glue-set.model';
import { CordHelper } from '@app/modules/gluelam/controls/beam-material-gluset/CordHelper';
import { GluePLanLayerdViewModelComponent } from '@app/modules/gluelam/controls/gluelam-planning-statical/glue-plan-layerd-view-model/glue-plan-layerd-view-model.component';
import { DialogService } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-gluing-plan-layered-view',
  templateUrl: './gluing-plan-layered-view.component.html',
  styleUrls: ['./gluing-plan-layered-view.component.css']
})
export class GluingPlanLayeredViewComponent implements OnChanges {
  @Input() glueSets: BeamMaterialGlueSet[];
  @Input() machine: IMachineDTO;
  @ViewChild('chartView') chartView: ElementRef;
  ch: CordHelper;
  containerWidth:number = 100;
  containerHeight:number = 100;
  defaultLayerPlacementffset:number = 50;
  cloneGlueSets = [];
  gsOffsets:number[][] = [];

  constructor(
    private dialog: DialogService,
  ) {
    this.setupCordSystem();
  }

  ngOnChanges(): void {
    let offset = this.machine.maxPressWidth;
    this.gsOffsets = [];
    this.cloneGlueSets = this.glueSets.map(set => {
      return {
        ...set,
        layers: set.layers.map(item => ({ ...item }))
      };
    });

    // Generate the array of offsets to place the beams
    this.cloneGlueSets?.reverse().forEach((gs) => {
      const layerOffsets:number[] = [];
      gs.layers.reverse().forEach((layer) => {
        offset -= (this.calcMaxWidth(layer) + this.defaultLayerPlacementffset);
        layerOffsets.push(offset);
      });
      this.gsOffsets.push(layerOffsets);
    }
    );

    this.setupCordSystem();
  }

  private setupCordSystem() {
    if (this.glueSets?.length > 0 && this.machine) {
      this.ch = new CordHelper(
        this.containerHeight, // height of the coniainer
        this.machine.maxPressWidth ?? 1200,
        this.containerWidth, // width of the coniainer
        this.machine.maxPressHight ?? 1200// width of the gluepress machine
      );
    }
  }

  onSideViewClick() {
    const dialogRef = this.dialog.open({
      content: GluePLanLayerdViewModelComponent,
      width: 600,
      height: 'auto',
    });

    const content = dialogRef.content.instance as GluePLanLayerdViewModelComponent;
    content.data = { glueSets: [...this.cloneGlueSets], machine: this.machine };
  }

  calcMaxWidth(layer:BeamMaterialGlueSetLayer) {
    const bmWidth = layer.BeamMaterials?.map(bm => bm.width);
    const max = layer.BeamMaterials.length > 0 ? Math.max(...bmWidth) : 0;
    return max;
  }

  calcBMSideX(bmgsl: BeamMaterialGlueSetLayer, index: number): number {
    let x = 0;

    for (let i = 0; i < index; i++) {
      x += bmgsl.BeamMaterials[i].getHight();
    }

    return x;
  }

  getOffset(layerIndex: number, gsIndex : number) {
    return this.gsOffsets[gsIndex][layerIndex];
  }

  getTotalHeightForLayer(layer : BeamMaterialGlueSetLayer){
    return layer.BeamMaterials.reduce((total, item) => total + item.height, 0);
  }

  getTotalNoOfLammelasForlayer(layer : BeamMaterialGlueSetLayer){
    return layer.BeamMaterials.reduce((total, item) => total + item.numberOfLamminas, 0);
  }
}

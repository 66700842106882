<h1 translate>Operative.InstructionLine</h1>

<section class="app-button-row">
  <div class="k-flex-grow"></div>
  <button kendoButton class="k-mb-1" themeColor="primary" (click)="addInstructionLineRow()">
    {{ 'App.AddNew' | translate }}
  </button>
</section>

<kendo-grid #instructionLineEditorGrid
  [data]="gridInstructionLineData"
  class="crud-grid"
  [pageSize]="state.take"
  [skip]="state.skip"
  [pageable]="true"
  [filterable]="false"
  [filter]="state.filter"
  [size]="'small'"
  (dataStateChange)="dataStateChange($event)"
  (edit)="editHandler($event)"
  (cancel)="cancelHandler($event)"
  (save)="saveHandler($event)"
  (remove)="removeHandler($event)"
  [size]="'small'">

  <kendo-grid-column field="instructionID"  title="{{ 'Operative.Instruction' | translate }}" [editable]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.instructionName }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <app-intsructions [showLabel]="false" [formControl]="formGroup.get('instructionID')">
      </app-intsructions>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="machineId"  title="{{ 'Operative.Machine' | translate }}" [editable]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.machineName }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <app-machine-selector-dropdown [showLabel]="false" [formControl]="formGroup.get('machineId')">
      </app-machine-selector-dropdown>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column title="{{ 'Operative.Command' | translate }}">
    <ng-template
      kendoGridCellTemplate
      let-isNew="isNew">
      <button
        kendoGridEditCommand
        [primary]="true">
        {{ 'App.Edit' | translate }}
      </button>
      <button
        kendoGridRemoveCommand
        themeColor="error">
        {{ 'App.Remove'  | translate  }}
      </button>
      <button
        kendoGridSaveCommand
        [disabled]="formGroup?.invalid" themeColor="primary">
        {{ (isNew ? "App.Add" : "App.Update") | translate }}
      </button>
      <button themeColor="error"
        kendoGridCancelCommand>
        {{ 'App.Cancel'  | translate  }}
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>

<app-confirmation-dialog
    *ngIf="instructionToRemove"
    (conformationDialogCloseEvent)="confirmRemove($event)">
</app-confirmation-dialog>

<form [formGroup]="entityForm" style="display: flex; flex-direction: column;" (ngSubmit)="onSubmit()">
  <div>
    <h3 translate>EntityTranslation.BaseData</h3>
    <div class="row field-row">
      <kendo-formfield style="display: block; flex-grow: 2;">
        <kendo-label translate>EntityTranslation.Code</kendo-label>
        <kendo-textbox formControlName="code"></kendo-textbox>
      </kendo-formfield>
      <kendo-formfield style="flex-grow: 2;">
        <kendo-label translate>EntityTranslation.Description</kendo-label>
        <kendo-textbox formControlName="description"></kendo-textbox>
      </kendo-formfield>
      <app-select-unit style="flex-grow: 1;" formControlName="baseUnitCode" [showLable]="true"
        [label]="'EntityTranslation.BaseUnitCode'" class="width-dropdown">
      </app-select-unit>
      <app-select-entity-status style="flex-grow: 1;" formControlName="status" [showLable]="true"
        [label]="'EntityTranslation.EntityStatus'" class="width-dropdown">
      </app-select-entity-status>

      <kendo-formfield style="flex-grow: 1;">
        <kendo-label translate>
          GluePlan.Dimension
        </kendo-label>
        <kendo-dropdownlist formControlName="beamLamminaDimensionId" [valuePrimitive]="true" [data]="dimensions"
          textField="description" valueField="beamLamminaDimensionID">
        </kendo-dropdownlist>
      </kendo-formfield>
    </div>
    <div class="row field-row" *ngIf="isActivity">
      <app-machine-selector-dropdown formControlName="machineId"
        [machineGroupId]="_entityGroup?.machineGroupId"></app-machine-selector-dropdown>
      <kendo-formfield>
        <kendo-label translate>EntityTranslation.BatchSize</kendo-label>
        <kendo-textbox type="text" formControlName="batchSize">
          <ng-template kendoTextBoxSuffixTemplate>
            {{ entityForm.value?.baseUnitCode }}
          </ng-template>
        </kendo-textbox>
      </kendo-formfield>
    </div>
    <div *ngIf="!isActivity" class="row field-row">
      <kendo-formfield>
        <kendo-label translate>EntityTranslation.MinStock</kendo-label>
        <kendo-numerictextbox formControlName="targetMinStock">
          <ng-template kendoTextBoxSuffixTemplate>
            {{ entityForm.value.baseUnitCode }}
          </ng-template>
        </kendo-numerictextbox>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label translate>EntityTranslation.MaxStock</kendo-label>
        <kendo-numerictextbox formControlName="targetMaxStock">
          <ng-template kendoTextBoxSuffixTemplate>
            {{ entityForm.value.baseUnitCode }}
          </ng-template>
        </kendo-numerictextbox>
      </kendo-formfield>
    </div>

    <div class="row">
      <div class="col" style="flex-grow: 1;">
        <h3 translate>
          EntityTranslation.PropertyValues
        </h3>
        <table>
          <thead>
            <th>{{ 'EntityTranslation.Property' | translate }}</th>
            <th>{{ 'EntityTranslation.Value' | translate }}</th>
          </thead>
          <tbody formArrayName="propertyValues">

            <tr *ngFor="let v of propertyValues.controls; let i = index" [formGroupName]="i">
              <td>
                {{ propertyValues.controls[i].value['propertyCode'] }}
              </td>
              <td>
                <app-select-valid-value formControlName="value" [showLable]="false"
                  [propertyCode]="v.value['propertyCode']">
                </app-select-valid-value>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col" style="flex-grow: 1;">
      <h3 *ngIf="!isActivity">{{ 'EntityTranslation.UnitConversions' | translate }}</h3>
      <div *ngIf="!isActivity">
        <span *ngIf="unitConversions.errors != null" class="validation-text">
          Unit code already exist!!!
        </span>
        <table>
          <thead>
            <th translate>EntityTranslation.ConversionFactor</th>
            <th translate>App.UnitCode</th>
            <th></th>
          </thead>
          <tbody formArrayName="unitConversions">
            <tr *ngFor="let v of unitConversionsActive; let i = index" [formGroupName]="i">
              <td><kendo-numerictextbox formControlName="convFactor"></kendo-numerictextbox></td>
              <td><app-select-unit [showLable]="false" formControlName="unitCode"></app-select-unit></td>
              <td> <button
                  [disabled]="v.value.unitCode == 'M3' || v.value.unitCode == 'LPM' || v.value.unitCode == 'PCS'"
                  kendoButton (click)="removeUnitConversion(i)" themeColor="error" [svgIcon]="minusIcon">
                </button></td>
            </tr>
            <tr>
              <td [colSpan]="2"></td>
              <td>
                <button kendoButton (click)="addNewunitConversion()" themeColor="primary" [svgIcon]="plusIcon">
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3 *ngIf="isActivity">
        {{ 'EntityTranslation.Files' | translate }}
      </h3>
      <app-file-upload *ngIf="isActivity && !isNew" [container]="'activity-attachments'"
        [folder]="entityForm.get('guid').value" [downloadMode]="fileDownloadMode.SingleFileUsingChip">
      </app-file-upload>

    </div>
    <div class="col" style="flex-grow: 4;">
      <div class="row">
        <h3 *ngIf="data.entityTypeCode == 'RESOURCE'">
          {{ 'EntityTranslation.RelationMaterial' | translate }}
        </h3>
        <h3 *ngIf="data.entityTypeCode == 'ACTIVITY'">
          {{ 'EntityTranslation.RelationActivity' | translate }}
        </h3>
        <div style="flex-grow: 1;"></div>
        <div class="col">
          <button *ngIf="isActivity && isGlulamSpecEnabled" kendoButton (click)="openAddEditGlulamSpecModal()"
            [primary]="true" [disabled]="glulamSpecButtonDisabled">
            {{ 'GluePlan.GlulamSpec' | translate }}
          </button>
        </div>
      </div>
      <table>
        <thead>
          <th translate>{{ data.entityTypeCode == 'RESOURCE'? 'ACTIVITY' : 'MATERIAL' }}</th>
          <th translate>EntityTranslation.Relation</th>
          <th translate>App.UnitCode</th>
          <th translate>App.Direction</th>
          <th translate>EntityTranslation.Tactical</th>
          <th translate>EntityTranslation.Operative</th>
          <th *ngIf="isActivity" translate>EntityTranslation.Chaining</th>
          <th></th>
        </thead>
        <tbody formArrayName="entityRelations">
          <tr *ngFor="let r of entityRelations().controls let i = index" [formGroupName]="i">
            <td>
              <app-select-entity formControlName="entityIndex"
                [entityTypeCode]="data.entityTypeCode == 'RESOURCE'? 'ACTIVITY' : 'RESOURCE'" [showLable]="false">
              </app-select-entity>
            </td>
            <td>
              <kendo-numerictextbox formControlName="relation">
              </kendo-numerictextbox>
            </td>
            <td>
              <app-select-unit formControlName="unitCode" [showLable]="false"></app-select-unit>
            </td>
            <td>
              <app-select-entity-relation-direction formControlName="direction" [showLable]="false">
              </app-select-entity-relation-direction>
            </td>
            <td>
              <kendo-switch #Tactical formControlName="inTactical"></kendo-switch>
            </td>
            <td>
              <kendo-switch #Operative formControlName="inOperative"></kendo-switch>
            </td>
            <td *ngIf="isActivity">
              <kendo-switch #Chaining formControlName="isChaining"></kendo-switch>
            </td>
            <td>
              <button kendoButton themeColor="error" type="button" [svgIcon]="minusIcon" size="large"
                (click)="removeEntityRelation(i)">
              </button>
            </td>
          </tr>
          <tr>
            <td [attr.colspan]="isActivity ? 7 : 6"></td>
            <td>
              <button kendoButton (click)="addNewEntityRelation(data.entityTypeCode)" [svgIcon]="plusIcon" size="large"
                [primary]="true">
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  </div>
</form>
<kendo-dialog-actions layout="end">
  <button kendoButton (click)="onCancel()" themeColor="error">
    {{ 'EntityTranslation.Cancle' | translate }}
  </button>
  <button kendoButton type="submit" (click)="onSubmit()" [disabled]="(!entityForm.valid || disableSubmit)" *ngIf="isNew"
    [primary]="true">
    {{ 'App.Add' | translate }}
  </button>
  <button kendoButton type="submit" [disabled]="(!entityForm.valid || disableSubmit)" *ngIf="!isNew"
    (click)="onSubmit()" [primary]="true">
    {{ 'App.Update'| translate }}
  </button>
</kendo-dialog-actions>

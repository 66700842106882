import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { EntityRelationDirection, IEntity } from '@app/core/services/http-services/model/entity.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { Guid } from 'guid-typescript';
import { RegexHelper } from '@app/shared/helpers/regex-helper';
import { CommonHelper } from '@app/shared/helpers/common-helper';
import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { ProdOrderReportService } from '@app/core/services/http-services/operative/prod-order-report.service';
import { ICreateProdOrderReportDTO, IProdOrderReportDTO, IProdOrderLineDTO, ICreateProdOrderLineReportDTO, IProdOrderReportStateDTO, IProdOrderLineReportedQuantityDTO } from '@app/core/models/prod-order';
import { IHandelingUnit } from '@app/core/services/http-services/stock/handeling-unit-report.service';
import { IEntitySimpleDTO } from '@app/core/services/http-services/model/entity-simple.service';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { ICreateProdOrderReport } from '@app/core/models/forms/production-orders/create-prod-order-report.model';
import { ICreateProdOrderReportForm } from '@app/core/models/forms/production-orders/create-prod-order-report-form.model';
import { IconSize, IconThemeColor } from '@progress/kendo-angular-icons';

marker('Operative.ProdOrderReportErrorMsgTitle');
marker('Operative.ProdOrderReportReportQtyErrorMsg');
marker('Operative.ProdOrderReportMaterialErrorMsg');
marker('Operative.ProdOrderReportLocationErrorMsg');
marker('Operative.ProdOrderReportCreatedSuccessMsg');
marker('Operative.ProdOrderReportReportPackageErrorMsg');

@Component({
  selector: 'app-prod-order-report',
  templateUrl: './prod-order-report.component.html',
  styleUrls: ['./prod-order-report.component.css']
})
export class ProdOrderReportComponent extends DialogContentBase implements OnInit {
  prodOrderReportForm: FormGroup<ICreateProdOrderReportForm>;
  selectedMaterialCode: string;
  entityTypeCode:'RESOURCE' = 'RESOURCE';
  selectedStockLocationName: string;
  prodOrderReport: ICreateProdOrderReportDTO;
  loading = false;
  prodOrderReportFormCopy: IProdOrderReportDTO[] = [];
  prodorderFormarray: FormArray<FormGroup<ICreateProdOrderReport>>;
  handelingUnitId:number;
  poLines: IProdOrderLineReportedQuantityDTO[] = [];
  themeColor: IconThemeColor = 'dark';
  size: IconSize ='xlarge';
  state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [
      ]
    }
  };

  private destroy:Subject<any> = new Subject<any>();

  private prodOrderReportErrorTitle = 'Operative.ProdOrderReportErrorMsgTitle';
  constructor(
    @Inject(DialogRef)
    public data: {prodOrderLines: IProdOrderLineDTO[], prodOrderId: number}
    , private appNotificationService: AppNotificationService
    , private poService: ProdOrderService
    , private poReportService: ProdOrderReportService
    , private spinner: NgxSpinnerService
    , private dialogRef: DialogRef) {
      super(dialogRef);
  }

  ngOnInit(): void {
    console.log('Prod Order Report Component');
    this.buildForm();
  }

  ngOnDestroy() {
    this.destroy.complete();
  }

  buildForm() {
    this.prodOrderReportForm = createProdOrderReportForm(this.data.prodOrderLines, false, new Date());
  }

  getProdOrderReportsForm(): FormArray<FormGroup<ICreateProdOrderReport>> {
    return this.prodOrderReportForm.get('prodOrderReports') as FormArray<FormGroup<ICreateProdOrderReport>>;
  }

  setSelectedMaterial(entity:IEntity) {
    this.selectedMaterialCode = entity.code;
  }

  setSelectedStockLocationText(stockLocationName:string) {
    this.selectedStockLocationName = stockLocationName;
  }

  packagechange(prodOrderReportdata:any, hu:IHandelingUnit) {
    prodOrderReportdata.controls['reportedQty'].enable();

    if (hu.qty > 0) {
      prodOrderReportdata.controls['reportedQty'].setValue(hu.qty);
      prodOrderReportdata.controls['reportedQty'].disable({ onlySelf: true });
    }
    this.loading = false;
  }

  createcopy(pol:any, rowIndex:number) {
    const prodOrderLine = createProdOrderReport(<IProdOrderLineDTO><unknown>{
      lineNo: '',
      prodOrderLineId: pol.get('prodOrderLineId').value,
      planedQty: '',
      reportedQty: pol.get('reportedQty').value,
      materialIndex: pol.get('materialIndex').value,
      handelingUnitId: 0,
      stockLocationId: pol.get('stockLocationId').value,
      stockLocationName: pol.get('stockLocationName').value,
      planedQtyUOM: pol.get('reportedQtyUOM').value,
      reportData: pol.get('reportData').value,
      message: pol.get('message').value,
      direction: pol.get('direction').value
    });
    this.getProdOrderReportsForm().controls.splice(rowIndex + 1, 0, prodOrderLine);
  }

  removerow(rowIndex:number) {
    this.getProdOrderReportsForm().controls.splice(rowIndex, 1);
  }

  save() {
    this.prodOrderReport = undefined;
    if (!this.validate()) {
      const prodOrderReports: ICreateProdOrderLineReportDTO [] = [];
      this.getProdOrderReportsForm().controls.forEach(por => {
        const reportedQty = CommonHelper.replaceCommawithDot(por.value.reportedQty);
        if (reportedQty !== '' && Number(reportedQty) !== 0) {
          prodOrderReports.push(<ICreateProdOrderLineReportDTO>{
            guID: Guid.create().toString(),
            prodOrderLineId: por.value.prodOrderLineId,
            materialIndex: por.value.materialIndex,
            reportedQty: this.poService.validateQuantityAsPerDirection(Number(CommonHelper.replaceCommawithDot(reportedQty)), por.value.direction),
            newQty: por.value.newQty,
            handelingUnitId: por.value.handelingUnitId,
            reportedQtyUOM: por.value.reportedQtyUOM,
            stockLocationId: por.value.stockLocationId,
            reportData: por.value.reportData,
            message: por.value.message,
            eventDate: new Date(this.prodOrderReportForm.get('eventDate').value)
          });
        }
      });

      this.prodOrderReport = <ICreateProdOrderReportDTO> {
        prodOrderId: this.data.prodOrderLines[0].prodOrderID,
        prodOrderLineReport: prodOrderReports,
        reportDate: new Date(),
        isActive: 1,
        isPartial: (this.prodOrderReportForm.get('isPartial').value ? 1 : 0)
      };

      this.spinner.show('prodOrderReportLoader');
      this.poReportService
          .addUpdateProdOrderLineReport(this.prodOrderReport)
          .pipe(takeUntil(this.destroy))
          .subscribe({
              next: (poReportState: IProdOrderReportStateDTO) => {
                this.addUpdateReportResponse(poReportState)
              },
              error: (err) => this.handleResponseError(err)

            }
          );
    }
  }

  hideLoader() {
    this.spinner.hide('prodOrderReportLoader');
  }

  close() {
    this.dialogRef.close(false);
  }

  private validate() : boolean {
    const errorMessages: Array<{ text: string, values: any }> = new Array<{ text: string, values: any }>();
    let lineNo = 0;

    this.prodOrderReportForm.get('prodOrderReports').value.forEach((por, index) => {
      lineNo = (index + 1);

      if (por.materialIndex === null || por.materialIndex === 0) {
        errorMessages.push({ text: 'Operative.ProdOrderReportMaterialErrorMsg', values: { lineNo } });
      }

      if (por.stockLocationId === null || por.stockLocationId === 0) {
        errorMessages.push({ text: 'Operative.ProdOrderReportLocationErrorMsg', values: { lineNo } });
      }
    });

    const msg = this.validateQtyZero();
    if (msg !== '') {
      errorMessages.push({ text: msg, values: { lineNo: 0 } }); // validate qty
    }
    errorMessages.forEach(err => {
      this.showErrorMessage(err.text, this.prodOrderReportErrorTitle, err.values);
    });

    return (errorMessages.length > 0);
  }

  validateQtyZero(): string {
    let errorMessages: string = '';
    for (const por of this.prodOrderReportForm.get('prodOrderReports').value) {
      let reportedQty = CommonHelper.replaceCommawithDot(por.reportedQty);
      reportedQty = reportedQty + '';
      if (reportedQty === 'null') {
        reportedQty = '';
      }
      if (reportedQty !== '' && Number(reportedQty) === 0) {
        errorMessages = 'Operative.ProdOrderReportReportQtyErrorMsg';
      } else if (errorMessages === '') {
        errorMessages = '';
      }
    }
    return errorMessages;
  }

  private addUpdateReportResponse(poReportState : IProdOrderReportStateDTO) {
    console.log(poReportState);
    this.hideLoader();
    if (poReportState.isSaved) {
      this.dialogRef.close(true);
      this.appNotificationService.notifySucsessAppChanel('Operative.ProdOrderReportCreatedSuccessMsg');
    } else {
      poReportState.prodOrderLineReportStates.forEach(rs => {
        this.showErrorMessage(rs.errorMessage, this.prodOrderReportErrorTitle);
      });
    }
  }

  private handleResponseError(_error: any) {
    this.hideLoader();
  }

  private showErrorMessage(errMsg: string, title: string, values: any = {}) {
    this.appNotificationService.notifyErrorAppChanel(errMsg, title, values);
  }

  keyPressNumbersWithDecimal(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    const element = event.target.value;
    if (
      (charCode !== 45 || element.indexOf('-') !== -1) && // “-” CHECK MINUS, AND ONLY ONE.
            (charCode !== 46 || element.indexOf('.') !== -1) && // “.” CHECK DOT, AND ONLY ONE.
            (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  disableField(prodOrderReportdata:any) {
    return Number(prodOrderReportdata.controls['direction'].value) === EntityRelationDirection.PRODUCE;
  }

  calculateReportedQty() {
    this.poService
        .getPOLinesWithReportedQty(this.data.prodOrderId)
        .pipe(takeUntil(this.destroy))
        .subscribe({
          next: (data: IProdOrderLineReportedQuantityDTO[]) => {
            data.forEach(element => {
              this.setReportedQtyForLine(element.prodOrderLineId, element.totalReportedQty);
            });
          }
        });
  }

  setReportedQtyForLine(prodOrderLineId: number, newValue: any): void {
    const prodOrderReportsArray = this.getProdOrderReportsForm();

    const lineFormGroup = prodOrderReportsArray.controls.find((control: FormGroup<ICreateProdOrderReport>) => {
      return control.get('prodOrderLineId').value === prodOrderLineId;
    }) as FormGroup<ICreateProdOrderReport>;

    if (lineFormGroup) {
      const reportedQtyControl = lineFormGroup.get('reportedQty') as FormControl<string> | null;
      if (reportedQtyControl) {
        reportedQtyControl.setValue(newValue);
      }
    }
  }
}

const createProdOrderReportForm = (pol: IProdOrderLineDTO[], isPartial:boolean = false, eventDate:Date) => new FormGroup<ICreateProdOrderReportForm>({
  isPartial: new FormControl<boolean>(isPartial, [Validators.required]),
  eventDate: new FormControl<Date>(eventDate, [Validators.required]),
  prodOrderReports: new FormArray<FormGroup<ICreateProdOrderReport>>(pol.map<FormGroup>(por => createProdOrderReport(por)))

});

const createProdOrderReport = (pol: IProdOrderLineDTO) => new FormGroup<ICreateProdOrderReport>({
  lineNo: new FormControl<number>(pol.lineNo),
  isMain: new FormControl<boolean>(pol.mainReportingLine),
  prodOrderLineId: new FormControl<number>(pol.prodOrderLineId),
  planedQty: new FormControl<number>(pol.planedQty),
  reportedQty: new FormControl<string>('', [Validators.pattern(RegexHelper.positiveNegativeWithDotComma)]),
  newQty: new FormControl<number>(pol.totalReportedQty),
  handelingUnitId: new FormControl(),
  materialIndex: new FormControl<number>(pol.materialIndex, [Validators.required]),
  stockLocationId: new FormControl<number>(pol.stockLocationId, [Validators.required]),
  stockLocationName: new FormControl<string>(pol.stockLocationName),
  reportedQtyUOM: new FormControl<string>(pol.planedQtyUOM),
  reportData: new FormControl<string>(''),
  message: new FormControl<string>(''),
  direction: new FormControl<number>(pol.direction)
});

<h1>
  {{"GluePlanPrint.Beam_Material"| translate }}
</h1>

<button
  kendoButton
  themeColor="info"
  type="button"
  size="large"
  [svgIcon]="printIcon"
  (click)="onClick(pdfs)">
</button>

<kendo-pdf-export #pdfs class="font-size-large" [scale]="0.35" [autoPrint]="true">
  <div *ngFor="let beamMaterial of beamMaterials" class="page-break borderbox">
    <div class="row pad0">
      <div class="k-card-body">
        <kendo-barcode type="Code128" value="{{beamMaterial.beamMaterialId}}"> </kendo-barcode>
      </div>
      <div class="row pad0 wordwrap">
        <dl>
          <dt>
           {{beamMaterial.orderId}}
          </dt>
          <div *ngFor="let comment of beamMaterial.orderComment">
            <dd> {{comment}}</dd>
          </div>
        </dl>
      </div>
    </div>
    <div class="label">
      <kendo-label>
        <span>
          {{ beamMaterial.width}} x {{ beamMaterial.height}} x {{ beamMaterial.length}}
        </span>
      </kendo-label>
    </div>
    <div class="label">
      <kendo-label>{{beamMaterial.gluesetId}}</kendo-label>
    </div>
    <br>
    <div class="label">
      <kendo-label>{{beamMaterial.trimmingLocation}}</kendo-label>
    </div>
    <div class="label">
      <kendo-label>{{beamMaterial.productionDate}}</kendo-label>
    </div>
    <div class="label">
      <kendo-label>{{beamMaterial.beamQuality}}</kendo-label>
    </div>
    <div class="label row1">
      <kendo-label>{{beamMaterial.customerDescription}}</kendo-label>
      <kendo-label class="font-size-larger"><b>
          {{beamMaterial.layerNumber}}/{{beamMaterial.beamNumber}}</b></kendo-label>
    </div>
  </div>
</kendo-pdf-export>

import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker([
  'GlulamSpec.LayerType.Unknown',
  'GlulamSpec.LayerType.OuterTop',
  'GlulamSpec.LayerType.Middle',
  'GlulamSpec.LayerType.OuterBottom'
]);

export enum GlueSpecLayerTypeEnum {
  UNKNOWN,
  OUTER_TOP,
  MIDDLE,
  OUTER_BOTTOM
}

export function getGlueSpecLayerTypesValues(): Array<IDropDownDataItem> {
  return [
    {
      value: GlueSpecLayerTypeEnum.UNKNOWN,
      text: 'GlulamSpec.LayerType.Unknown'
    },
    {
      value: GlueSpecLayerTypeEnum.OUTER_TOP,
      text: 'GlulamSpec.LayerType.OuterTop'
    },
    {
      value: GlueSpecLayerTypeEnum.MIDDLE,
      text: 'GlulamSpec.LayerType.Middle'
    },
    {
      value: GlueSpecLayerTypeEnum.OUTER_BOTTOM,
      text: 'GlulamSpec.LayerType.OuterBottom'
    }
  ];
}

export class IDropDownDataItem{
public value: any;
public text: string;
}

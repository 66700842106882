import { InjectionToken } from '@angular/core';
import { BeamMaterialLine } from '@app/core/models/beam-material-line.model';
import { BeamMaterial } from '../../../../core/models/beam-material.model';
import { BeamMaterialGlueSet } from '../../../../core/models/beam-material-glue-set.model';
import { IMachineDTO } from '@app/core/models/machineDTO';
export const BML_DATA = new InjectionToken<BeamMaterialLine>('BML_DATA');
export const BML_COPY_DATA = new InjectionToken<BeamMaterialLine[]>('BML_COPY_DATA');
export const BM_DATA = new InjectionToken<BeamMaterial>('BM_DATA');
export const BMGS_DATA = new InjectionToken<BeamMaterialGlueSet>('BMGS_DATA');
export const MACHINE_DATA = new InjectionToken<IMachineDTO>('MACHINE_DATA');
export const IS_STATICAL_GLUE_PLAN = new InjectionToken<boolean>('IS_STATICAL_GLUE_PLAN');
export const IS_LAYER_WITH_SPECIAL_FORM_BMT = new InjectionToken<boolean>('IS_LAYER_WITH_SPECIAL_FORM_BMT');

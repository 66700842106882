import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { GridDataResult } from '@progress/kendo-angular-grid';
import { IEntityGroup } from '../../custom-services/entity-group.service';
import { IStandardProperties } from './entity.service';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EntitySimpleService extends BehaviorSubject<GridDataResult> {
  loading: boolean = false;

  private contUrl = `${environment.apiUrl}EntitySimple`;

  constructor(
    public http: HttpClient
  ) {
    super(null);
  }

  get(entityIndex: number): Observable<IEntitySimpleDTO> {
    return this.http.get<IEntitySimpleDTO>(`${this.contUrl}/${entityIndex}`);
  }

  query(entityTypeCode: string, code: string, take: number, getMaterialStandardProperties: boolean = false, isGlulamGroup:boolean = null): Observable<IEntitySimpleDTO[]> {
    return this.http.get<IEntitySimpleDTO[]>(this.contUrl, {
      params: new HttpParams()
        .set('entityTypeCode', entityTypeCode)
        .set('code', code)
        .set('take', take.toString())
        .set('isGlulamGroup', isGlulamGroup)
        .set('getMaterialStandardProperties', getMaterialStandardProperties)
    });
  }

}

export interface IEntitySimpleDTO {
  baseUnitCode: string;
  changeDate: string | null;
  code: string;
  commentId: number | null;
  description: string;
  entityGroupCode: string;
  index: number;
  isDynamicActivity: boolean | null;
  sortOrderIndex: number | null;
  status: number | null;
  superiorEntityIndex: number | null;
  userCode: string;
  machineId: number;
  entityGroup?: IEntityGroup;
  targetMinStock: number | null;
  targetMaxStock: number | null;
  batchSize: number | null;
  guid:string;
  beamHightMin: number | null;
  beamHightMax: number | null;
  standardProperties?: IStandardProperties
}

import { AddEvent, RemoveEvent } from '@progress/kendo-angular-grid';
import { Component, OnInit } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { IUserRoleRequestDTO, OptinexUserDTO, UserService } from '@app/core/services/http-services/common/user.service';
import { SVGIcon, codeIcon, heartIcon, pencilIcon, plusIcon, trashIcon } from '@progress/kendo-svg-icons';

import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { UserFormComponent } from '../user-form/user-form.component';
import { UserQueryService } from '@app/core/services/http-services/common/user-query.service';
import { UserSettingService } from '@app/core/services/http-services/common/user-setting.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.css']
})
export class UserAdminComponent implements OnInit {

  public users: UserQueryService;

  public pencilIcon: SVGIcon = pencilIcon;
  public heartIcon: SVGIcon = heartIcon;
  public plusIcon: SVGIcon = plusIcon;
  public trashIcon: SVGIcon = trashIcon;
  public codeIcon: SVGIcon = codeIcon;

  public revokeTennatAcsess: boolean = false;
  public userToRevokeTennatAcsess: OptinexUserDTO = null;

  public constructor(
    public userQueryService: UserQueryService,
    public dialogService: DialogService,
    public translateService: TranslateService,
    public userService: UserService,
    public userSettingService: UserSettingService,
    public appNotificationService: AppNotificationService
  ) { }

  ngOnInit(): void {

    this.users = this.userQueryService;

    this.users.query();
  }

  async onEdit(ev: AddEvent) {

    const dialogRef: DialogRef = this.dialogService.open({
      content: UserFormComponent,
      minWidth: '600px'
    });

    let eventUser = ev.dataItem as OptinexUserDTO;

    const userForm = dialogRef.content.instance as UserFormComponent;

    let user: OptinexUserDTO;

    if (eventUser.userId) {
      user = await firstValueFrom(this.userService.get(eventUser.entraId));
      await userForm.setupForm(user, false);
    } else {
      user = await firstValueFrom(this.userService.new(eventUser));
      await userForm.setupForm(user, true);
    }

    dialogRef.result.subscribe(async () => {
      await this.users.query();
    });

  }

  clearUserSettings(user: OptinexUserDTO) {

    if (user.userId) {
      this.userSettingService.deleteByUser(user.userId).subscribe(_ => {
        this.appNotificationService.notifySucsessAppChanel('User Settings Cleared');
      });
    }
    this.userService.clearCache(user.entraId).subscribe(_ => {
      this.appNotificationService.notifySucsessAppChanel('User Cashe Cleared');
    });
  }

  onRemove(ev: RemoveEvent) {

    this.userToRevokeTennatAcsess = ev.dataItem as OptinexUserDTO;

  }

  heal() {
    this.userService.heal().subscribe(_ => {
      this.appNotificationService.notifySucsessAppChanel('User Heal Applied');
    });
  }

  onRevokeTennatAcsess() {
    this.userService.revokeTennatGroup(<IUserRoleRequestDTO>{ userId: this.userToRevokeTennatAcsess.entraId }).subscribe(
      _ => {
        this.appNotificationService.notifySucsessAppChanel('User Acsess Removed');
      }
    );

    this.userToRevokeTennatAcsess = null;
  }
}

import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import { BeamMaterialGlueSet } from '../../../core/models/beam-material-glue-set.model';
import { GluingPlan } from '@app/core/models/gluing-plans.model';

@Component({
  selector: 'app-glue-set-view',
  template: `
    <div class="gluesetContainer" #bmgsContainer>
      <app-beam-material-glue-set
        *ngFor="let bmgs of gluesets; let i = index"
        [glueSet]="bmgs"
        [plan] = "plan"
        [gsWidth]="gsWidth"
        [lamminaDim]="bmgs.beamLamminaDimension"
        [isOperator]="isOperator"
        (CalculateUnits)="onCalc()"
        (deleteBeamMaterialGlueSet)="onDelete($event, i)"
        [ngClass]="classes"
      >
      </app-beam-material-glue-set>
    </div>
  `,
  styleUrls: ['glueset-view.component.css'],
  host:{
    class :"d-flex-col-component"
  }
})
export class GluesetViewComponent implements OnChanges {
  @ViewChild('bmgsContainer') bmgsContainer: ElementRef;
  @Input() plan: GluingPlan;
  @Input() gluesets: BeamMaterialGlueSet[];
  @Input() isOperator: boolean = false;
  @Input() set cols(c: number) {
    this._cols = c;
    if (c > 0 && c < 7) {
      for (let i = 1; i <= 6; i++) {
        this.classes[`glueset-${i}-cols`] = c === i;
      }
    }
  }

  get cols() {
    return this._cols;
  }

  @Output() onUpdate = new EventEmitter<any>();
  @Output() onDeleteGlueSet = new EventEmitter<{ gs: BeamMaterialGlueSet; index: number }>();

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.onChangeSize();
  }

  gsWidth = 200;
  classes = {
    glueset: true,
    'glueset-1-cols': true,
    'glueset-2-cols': true,
    'glueset-3-cols': true,
    'glueset-4-cols': false,
    'glueset-5-cols': true,
    'glueset-6-cols': true
  };

  private _cols: number;

  constructor() {  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onChangeSize();
  }

  ngAfterViewInit(): void {
    this.onChangeSize();
  }

  onCalc() {
    this.onUpdate.emit(true);
  }

  onDelete(glueset: BeamMaterialGlueSet, index: number) {
    this.onDeleteGlueSet.emit({ gs: glueset, index });
  }

  onChangeSize() {
    setTimeout(() => {
      this.calcGsWidth();
    }, 50);
  }

  calcGsWidth() {
    const space = 20;
    this.gsWidth = this.bmgsContainer.nativeElement.offsetWidth / this.cols - 2 * space;
  }
}

<div *ngIf="showLable">
  <kendo-formfield>
    <kendo-label>{{label | translate}}</kendo-label>
    <kendo-multicolumncombobox [data]="entities" textField="description"
      valueField="index" [loading]="loading" [filterable]="true" [(value)]="selectedEntityIndex"
      (filterChange)="handleFilterChange($event)" (selectionChange)="onSelectionChange($event)" [valuePrimitive]="true">

      <kendo-combobox-column field="description" title="{{'EntityTranslation.Description' | translate}}">
        <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
          <span class="white-space"> {{dataItem.description ?? 'Unknown'}} </span>
        </ng-template>
      </kendo-combobox-column>

      <kendo-combobox-column field="entityGroup.description"
        title="{{'EntityTranslation.EntityGroupDescription' | translate}}">
        <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
          <span class="white-space"> {{dataItem.entityGroup?.description ?? 'Unknown'}} </span>
        </ng-template>
      </kendo-combobox-column>

    </kendo-multicolumncombobox>
  </kendo-formfield>

</div>

<kendo-multicolumncombobox [data]="entities" textField="description"
  valueField="index" placeholder="{{'EntityTranslation.SearchEntity' | translate}}" [loading]="loading"
  [filterable]="true" [(value)]="selectedEntityIndex" (filterChange)="handleFilterChange($event)"
  (selectionChange)="onSelectionChange($event)" [valuePrimitive]="true" class="min-width-dropdown" *ngIf="!showLable">
  <kendo-combobox-column field="description" title="{{'EntityTranslation.Description' | translate}}">
    <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
      <span class="white-space"> {{dataItem.description ?? 'Unknown'}} </span>
    </ng-template>
  </kendo-combobox-column>

  <kendo-combobox-column field="entityGroup.description"
    title="{{'EntityTranslation.EntityGroupDescription' | translate}}">
    <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
      {{dataItem.entityGroup?.description ?? 'Unknown'}}
    </ng-template>
  </kendo-combobox-column>

</kendo-multicolumncombobox>

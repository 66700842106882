import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUserSettingDTO } from '@app/core/models/userSettingDTO';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserSettingService {
  constructor(public http: HttpClient) {}

  /**
 * @deprecated Use `get()` instead.
 */
  public getUserSetting(settingsId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}UserSetting`, {
      params: {
        settingsId
      }
    });
  }

  public get<T>(settingsId: string): Observable<T> {
    return this.http.get<T>(`${environment.apiUrl}UserSetting`, {
      params: {
        settingsId
      }
    });
  }

  public saveUserSetting(settings: IUserSettingDTO): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}UserSetting`, settings);
  }

  public save<T>(key: string, value: T): Observable<boolean> {
    const val = <IUserSettingDTO>{
      key,
      value: JSON.stringify(value)
    };

    return this.http.post<boolean>(`${environment.apiUrl}UserSetting`, val);
  }

  public delete(settingsId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}UserSetting`, {
      params: {
        settingsId
      }
    });
  }

  public deleteByUser(userId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}UserSetting/user`, {
      params: {
        userId
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { environment } from '../../../../../environments/environment';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { State } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import { StateCategory } from '@app/core/models/state-category-enum';
import { KendoGridService } from '../../custom-services/kendo-grid.service';

export abstract class BeamMaterialLinesBaseService extends BehaviorSubject<GridDataResult> {
  public loading: boolean;
  constructor(

    public http: HttpClient,

    private kendoGridService: KendoGridService,

  ) {
    super(null);
  }

  query(state: State): void {
    this.loading = true;
    const stateFilter = this.kendoGridService.getStateFilter(state);

    let p = new HttpParams();

    const beam: any = stateFilter.filter.find(x => x['code'] === 'beamMaterialLineID');
    const glueSet: any = stateFilter.filter.find(x => x['code'] === 'glueSetId');
    const plan: any = stateFilter.filter.find(x => x['code'] === 'gluePlanId');
    const order: any = stateFilter.filter.find(x => x['code'] === 'orderNo');
    const orderRow: any = stateFilter.filter.find(x => x['code'] === 'orderRowNo');
    const transportPackageId: any = stateFilter.filter.find(x => x['code'] === 'transportPackageId');
    const customerDesc: any = stateFilter.filter.find(x => x['code'] === 'customerDesc');
    const mainProduct: any = stateFilter.filter.find(x => x['code'] === 'mainProduct');
    const quality: any = stateFilter.filter.find(x => x['code'] === 'quality');
    const constructionCertification: any = stateFilter.filter.find(x => x['code'] === 'constructionCertification');
    const width: any = stateFilter.filter.find(x => x['code'] === 'width');
    const height: any = stateFilter.filter.find(x => x['code'] === 'height');
    const length: any = stateFilter.filter.find(x => x['code'] === 'length');
    const stateCode: any = stateFilter.filter.find(x => x['code'] === 'stateId');

    if (beam) {
      p = p.set('beamId', beam.value);
    }
    if (glueSet) {
      p = p.set('glueSetId', glueSet.value);
    }
    if (plan) {
      p = p.set('planId', plan.value);
    }
    if (order) {
      p = p.set('orderNoStr', order.value);
    }
    if (orderRow) {
      p = p.set('orderRowNoStr', orderRow.value);
    }
    if (transportPackageId) {
      p = p.set('transportPackageId', transportPackageId.value);
    }
    if (customerDesc) {
      p = p.set('customerDesc', customerDesc.value);
    }
    if (mainProduct) {
      p = p.set('mainProduct', mainProduct.value);
    }
    if (quality) {
      p = p.set('quality', quality.value);
    }
    if (constructionCertification) {
      p = p.set('constructionCertification', constructionCertification.value);
    }
    if (width) {
      p = p.set('width', width.value);
    }
    if (height) {
      p = p.set('height', height.value);
    }
    if (length) {
      p = p.set('length', length.value);
    }
    if (stateCode) {
      p = p.set('stateId', stateCode.value);
    }
    if (stateFilter.sort) {
      p = p.append('sortColumn', stateFilter.sort.sortColumn);
      p = p.append('sortDirection', stateFilter.sort.sortDirection);
    };

    p = p.set('skip', stateFilter.skip.toString()).set('take', stateFilter.take.toString());

    this.http.get<GridDataResult>(`${environment.apiUrl}Beam`, { params: p }).pipe(map(response => (<GridDataResult>{
      data: response.data,
      total: response.total
    }))).subscribe(result => {
      super.next(result);
    });
  }

  getStates():Observable<IBeamMaterialLineState[]> {
    return this.http.get<IBeamMaterialLineState[]>(`${environment.apiUrl}Beam/states`);
  }

  reject(beamState: IBeamStateReport, state) {
    this.http.post<any>(`${environment.apiUrl}Beam/productionReport`, beamState, {}).subscribe(result => {
      this.query(state);
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class BeamMaterialLinesService extends BeamMaterialLinesBaseService {
  constructor(
    http: HttpClient,
    kendoGridService: KendoGridService) {
    super( http,kendoGridService );
  }
}

export interface IBeamMaterialLineInfo {
  beamMaterialID: number | null;
  beamMaterialLineID: number | null;
  beamOrderRowID: number;
  cut: number;
  indexInCut: number;
  numberOfBeams: number;
  transportPackageId: number | null;
  productionStateName: string;
  gluePlanId: number;
  glueSetId: number;
  productionStateCode: string;
  orderNo: string;
  stateID:number;
  orderRowNo: string;
  customerDesc: string;
  mainProduct: string;
  quality: string;
  constructionCertification: string;
  width: number | null;
  height: number | null;
  length: number | null;
}

export interface IGridDataResult<T> {
  data: T[];
  total: number;
}

export interface IBeamStateReport {
  beamMaterialLineId: number;
  code: string;
  force: boolean;
}

export interface IBeamMaterialLineState {
  beamMaterialLineStateID: number;
  code: string;
  name: string;
  state: StateCategory;
}

import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';

import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { IProdOrderDeleteResponseDTO } from '@app/core/models/prod-order';
import { ProdOrderResponseEnum } from '@app/core/models/prod-order-response-enum';

@Injectable({
  providedIn: 'root'
})
export class ProdOrderRemoveDialogService {
   setloader: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private ProdOrderService: ProdOrderService,
    private notificationService: AppNotificationService
  ) {}

  public showConfirmation(prodOrderIds: number[]): Observable<boolean> {
    return new Observable((observer) => {
      const dialog: DialogRef = this.dialogService.open({
        title: this.translateService.instant('App.Remove'),
        content: this.translateService.instant('Operative.ConfirmRemoveProdOrder', { count: prodOrderIds.length }),
        actions: [
          { text: this.translateService.instant('App.No'), isClose: true },
          { text: this.translateService.instant('App.Yes'), themeColor: 'primary', isClose: false }
        ],
        width: 450,
        height: 200,
        minWidth: 250
      });

      dialog.result.subscribe((result: any) => {
        if (result instanceof DialogCloseResult || result.isClose === true) {
            this.setObserverAndLoader(observer, true);
        } else {
            this.setloader.next(true);
            if (prodOrderIds.length === 1) {
                this.ProdOrderService.removeProdOrder(prodOrderIds[0], true).subscribe((response) => {
                    this.onSuccessDeleteProdOrder(response);
                    this.setObserverAndLoader(observer, response.deleteAllowed);
                });
            }
             else if (prodOrderIds.length > 0) {
                this.ProdOrderService.bulkDelete(prodOrderIds).subscribe((responses) => {
                    for (const response of responses) {
                        this.onSuccessDeleteProdOrder(response);
                    }
                    this.setObserverAndLoader(observer, true);
                });
            }
        }
    });
    });
  }

  private setObserverAndLoader(observer, deleteAllowed: boolean) {
    observer.next(deleteAllowed);
    observer.complete();
    this.setloader.next(false);
  }

  private onSuccessDeleteProdOrder(res: IProdOrderDeleteResponseDTO) {
    if (!res.deleteAllowed) {
      if (res.reason === ProdOrderResponseEnum.prodOrderMapToGlueset) {
        this.notificationService.notifyErrorAppChanel('Operative.ProdOrderLinkedToGlueset', 'Error', {
          name: res.name
        });
      } else if (res.reason === ProdOrderResponseEnum.prodOrderMapToSequence) {
        this.notificationService.notifyErrorAppChanel('Operative.ProdOrderLinkedToProdOrderSequence', 'Error', {
          prodorderlink: "'" + '/prod-order/' + res.prodOrderIds[0] + "'",
          name: res.name
        });
      } else if (res.reason === ProdOrderResponseEnum.prodOrderDeleteNotAllowedDueToState) {
        this.notificationService.notifyErrorAppChanel('Operative.ProdOrderDeleteNotAllowedDueToState', 'Error', {
          prodorderlink: "'" + '/prod-order/' + res.prodOrderIds[0] + "'",
          name: res.name
        });
      }
    } else {
      this.notificationService.notifySucsessAppChanel('Operative.ProdOrderDeletedSuccessfullyMsg', 'Sucsess', {
        prodOrderId: res.prodOrderIds.join(',')
      });
    }
  }
}

<button kendoButton *ngIf="!badge" themeColor="primary" (click)="onToggle()" [disabled]="!gluesetID">
  {{"Operative.LinkedProdOrder" | translate}}
  <kendo-loader *ngIf="loading && !badge"></kendo-loader>
</button>
<kendo-icon name="hyperlink" *ngIf="badge && !loading" (click)="onToggle()"></kendo-icon>
<kendo-loader *ngIf="loading && badge"></kendo-loader>

<div #anchor></div>
<kendo-popup [anchor]="anchor" (anchorViewportLeave)="showPopup = false" *ngIf="showPopup">
    <div class="main-div">
      <table class="tbl full-width">
        <tbody>
        <tr>
            <th class="color-title"><b>{{"Operative.Color" | translate}}</b></th>
            <th><b>{{"App.Name" | translate}}</b></th>
            <th><b>{{"App.StartDate" | translate}}</b></th>
            <th><b>{{"App.EndDate" | translate}}</b></th>
          </tr>
          <tr *ngFor="let line of gluesetSequenceReturn">
            <td><div [ngStyle]="{'background-color': line.colorCode}" class="machineColor"><br/></div></td>
            <td class="tb-pad"  routerLink="/prod-order/{{line.prodOrderId}}" >{{line.prodOrderName}}</td>
            <td class="tb-pad" >{{line.poStartDate | siteDateFormat:true}}</td>
            <td class="tb-pad" >{{line.poEndDate | siteDateFormat:true}}</td>
          </tr>
        </tbody>
      </table>
    </div>
</kendo-popup>

<div class="k-d-flex">
  <div
    class="k-d-flex k-flex-col-reverse line-height-normal k-mb-1 k-mr-sm">
    <div
      class="k-d-flex k-flex-col-reverse line-height-normal"
      *ngFor="let bmgs of cloneGlueSets; let gs_index = index">
      <div
        *ngFor="let layer of bmgs.layers;"
        class="k-d-flex k-justify-content-between">
        <div
          class="line-height-normal layer-height-text bold-font">
          H:
        </div>
        <span
          class="line-height-normal layer-height-text">
          {{getTotalHeightForLayer(layer) | number}} ({{getTotalNoOfLammelasForlayer(layer)}})
        </span>
      </div>
    </div>
  </div>

  <!-- Outer Container with border for sides -->
  <div
    #chartView
    class="layerdChart"
    [attr.width]="containerWidth"
    [attr.height]="containerHeight"
    (click)="onSideViewClick()"
    title="Click to view details.."
    *ngIf="glueSets">

    <!-- Outer SVG container -->
    <svg
      [attr.height]="containerHeight"
      [attr.width]="containerWidth">

      <!-- SVG container for every glueset -->
      <svg
        class="gluSet"
        *ngFor="let bmgs of cloneGlueSets; let gs_index = index">

        <!-- SVG container for every glueset layer -->
        <svg
          *ngFor="let layer of bmgs.layers; let l_index = index"
          [attr.width]="ch.getWidth(this.machine.maxPressHight)"
          [attr.height]="ch.getHight(calcMaxWidth(layer))"
          [attr.y]="getOffset(l_index,gs_index) > 0 ? ch.getHight(getOffset(l_index,gs_index)) : 0">

          <svg
            *ngFor="let bm of layer.BeamMaterials; let bm_index = index"
            [attr.height]="ch.getHight(bm.width)"
            [attr.width]="ch.getWidth(bm.getHight())"
            [attr.x]="ch.getWidth(calcBMSideX(layer, bm_index))">
            <rect
              [attr.height]="ch.getHight(bm.width)"
              [attr.width]="ch.getWidth(bm.getHight())"
              class="bm">
            </rect>
            <text
              class="mini"
              width="40"
              height="40"
              x="2"
              y="7">
              {{bm.numberOfLamminas}}
            </text>
          </svg>
        </svg>
      </svg>
    </svg>
  </div>
</div>

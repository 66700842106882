/* eslint-disable camelcase */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { BaseService } from '@app/core/services/custom-services/base.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import {
  OptimizationOrder,
  OptimizationResponce,
  OptimizationResponceCreator
} from '@app/core/models/optimization-order.model';
import {
  StaticalOptimizationOrder
} from '@app/core/models/statical-optimization-order.model';
import { WatsonStatus } from '@app/core/models/watson-status';
import { DeamndService } from './demand.service';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { IBeamMaterialGlueSet } from '@app/core/services/http-services/gluelam/glue-set.service';

@Injectable()
export class OptimazationService extends BaseService {
  constructor(
    conf: ConfigurationService,
    http: HttpClient,
    notificationService: AppNotificationService,
    public borService: DeamndService
  ) {
    super(http, conf, notificationService);
  }

  newOptimazationStart(
    optimizationOrder: OptimizationOrder,
    selectedOptimizationType: string
  ): Observable<WatsonStatus> {
    console.log('Optimazation Order:');
    console.log(JSON.stringify(optimizationOrder));

    return this.http.post<WatsonStatus>(
      this.configurations.baseApiUrl + 'RunJob/optimize/glulamContinous',
      JSON.stringify(optimizationOrder),
      this.httpOptions
    );
  }

  getOptimazationResult(optimizationOrder: OptimizationOrder, jobID: string): Observable<OptimizationResponce> {
    console.log('Optimazation Order:');
    console.log(JSON.stringify(optimizationOrder));

    return this.http
      .post<IOptimizationResponse>(
        this.configurations.baseApiUrl + 'RunJob/optimize/glulamResult/' + encodeURIComponent(jobID),
        JSON.stringify(optimizationOrder),
        this.httpOptions
      )
      .pipe(
        map((response: IOptimizationResponse) => {
          return OptimizationResponceCreator.FromJson(response, this.configurations);
        })
      );
  }

  staticalOptimazationStart(
    staticalOptimizationOrder: StaticalOptimizationOrder
  ): Observable<WatsonStatus> {
    console.log('Optimazation Order:');
    console.log(JSON.stringify(staticalOptimizationOrder));

    return this.http.post<WatsonStatus>(
      this.configurations.baseApiUrl + 'RunJob/optimize/glulamStatical',
      JSON.stringify(staticalOptimizationOrder),
      this.httpOptions
    );
  }

  getStaticalOptimizationData(
    staticalOptimizationOrder: StaticalOptimizationOrder
  ): Observable<Blob> {
    return this.http.post(
      this.configurations.baseApiUrl + 'RunJob/dataset/glulamStatical',
      JSON.stringify(staticalOptimizationOrder),
      { ...this.httpOptions, responseType: 'blob' } 
    );
  }

  getStaticalOptimazationResult(staticaloptimizationOrder: StaticalOptimizationOrder, jobID: string): Observable<IStaticalOptimizationResponse> {
    console.log('Optimazation Order:');
    console.log(JSON.stringify(staticaloptimizationOrder));

    return this.http
      .post<IStaticalOptimizationResponse>(
        this.configurations.baseApiUrl + 'RunJob/optimize/staticalGlulamResult/' + encodeURIComponent(jobID),
        JSON.stringify(staticaloptimizationOrder),
        this.httpOptions
      );
  }
}

export interface IOptimizationResponse {
  beamLamminaDimensionID: number;
  maxLength: number;
  minHight: number;
  maxHight: number;
  maxLamminas: number;
  beamOrderRowIDs: string;
  optiApiJson: string;
  optiApiURL: string;
  gluePlans:GluingPlan[];
  beamMaterialGlueSets:IBeamMaterialGlueSet[];
  numberOfDemands: number;
  numberOfGluestest: number;
  numberOfAllocations: number;
  numberOfBeamMaterials: number;
  solveState: string;
  errorMessage: string;
}

export interface IStaticalOptimizationResponse {
  beamLamminaDimensionID: number;
  maxPressLength: number;
  maxPressHight: number;
  minPressHight: number;
  maxPressWidth:number
  maxGluePlanLength: number;
  maxBeamInBeamMaterial: number;
  maxGlueplans:number;
  maxGluesets:number
  beamOrderRowIDs: string;
  optiApiJson: string;
  optiApiURL: string;
  gluePlans:GluingPlan[];
  beamMaterialGlueSets:IBeamMaterialGlueSet[];
  numberOfDemands: number;
  numberOfGluestest: number;
  numberOfAllocations: number;
  numberOfBeamMaterials: number;
  solveState: string;
  errorMessage: string;
}

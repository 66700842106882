import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl, FormGroup, FormArray } from '@angular/forms';
import { IEntityGroup } from '@app/core/services/custom-services/entity-group.service';
import { EntityPropertyService } from '@app/core/services/http-services/model/entity-property.service';
import { EntityRelationDirection, EntityService, IEntity, IEntityRelation, IGlulamSpec, IPropertyValue } from '@app/core/services/http-services/model/entity.service';
import { UnitService } from '@app/core/services/http-services/common/unit-service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { RegexHelper } from '@app/shared/helpers/regex-helper';
import { CommonHelper } from '@app/shared/helpers/common-helper';
import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { FileDownloadMode } from '@app/core/models/file-download-mode-enum';
import { IUnit } from '@app/core/models/unit.model';
import { IUnitConversion } from '@app/core/models/unitConversion.model';
import { CreateEntityForm } from '@app/core/models/forms/entity-admin/entity-form/create-entity-form.model';
import { CreatePropertyValueEntityForm } from '@app/core/models/forms/entity-admin/entity-form/create-entity-property-value-form.model';
import { CreateEntityRelationForm } from '@app/core/models/forms/entity-admin/entity-form/create-entity-relation-form.model';
import { CreateEntityUnitConversionForm } from '@app/core/models/forms/entity-admin/entity-form/create-entity-unit-conversion-form.model';
import { EntityPropertyDataTypeEnum } from '@app/core/models/entity-property-data-type.enum';
import { DialogRef, DialogContentBase, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { CreateGlulamSpecForm } from '@app/core/models/forms/gluelam/glulam-spec/create-glulam-spec-form.model';
import { BeamLamminaDimension } from '@app/core/models/beam-lammina-dimension.model';
import { DimensionService } from '@app/core/services/http-services/gluelam/dimension.service';
import { CreateGlulamSpecRowForm } from '@app/core/models/forms/gluelam/glulam-spec/create-glulam-spec-row-form.model';
import { SVGIcon, minusIcon, plusIcon } from '@progress/kendo-svg-icons';
import { Guid } from '@app/core/models/Guid';

marker('EntityTranslation.MinStockShouldbeGreaterThanZero');
marker('EntityTranslation.MaxStockShouldbeGreaterThanZero');
marker('EntityTranslation.MaxStockShouldbeGreater');
marker('EntityTranslation.MinStockNotEqualToMax');
marker('EntityTranslation.EntityRelationInvalidRelationForConsume');
marker('EntityTranslation.InstructionFileDeleted');
marker('EntityTranslation.EntityCodeLengthError');
marker('EntityTranslation.InvalidDataType');

const createFormGroupP = (pv: IPropertyValue) => new FormGroup<CreatePropertyValueEntityForm>({
  id: new FormControl<number>(pv.id),
  entityIndex: new FormControl<number>(pv.entityIndex),
  propertyCode: new FormControl<string>(pv.propertyCode),
  dataType: new FormControl<number>(pv.dataType),
  value: new FormControl<string>(getStringValue(pv)),
  userCode: new FormControl<string>(pv.userCode),
  changeDate: new FormControl<string>(pv.changeDate)
});

const createEntityRelationFormGroup = (enr: IEntityRelation, isActivity: boolean, entityIndex: number, isNew: boolean) => new FormGroup<CreateEntityRelationForm>({
  entityIndex: new FormControl<number>(entityIndex),
  relation: new FormControl<number>(enr.relation, [Validators.required, Validators.pattern(RegexHelper.positiveNegativeWithDotCommaWithNoScale)]),
  direction: new FormControl<EntityRelationDirection>(enr.direction),
  unitCode: new FormControl<string>(enr.unitCode),
  userCode: new FormControl<string>(enr.userCode),
  changeDate: new FormControl<string>(enr.ChangeDate),
  productCode: new FormControl<string>(enr.productCode),
  activityCode: new FormControl<string>(enr.activityCode),
  productIndex: new FormControl<number>(enr.productIndex),
  activityIndex: new FormControl<number>(enr.activityIndex),
  isNew: new FormControl<boolean>(isNew),
  isChange: new FormControl<boolean>(false),
  isRemoved: new FormControl<boolean>(false),
  inTactical: new FormControl<boolean>(enr.inTactical),
  inOperative: new FormControl<boolean>(enr.inOperative),
  isChaining: new FormControl<boolean>(enr.isChaining)
});

const createFormGroupUC = (uc: IUnitConversion, isNew: boolean, UnitConversionData: IUnitConversion[], isActivity: boolean) => new FormGroup<CreateEntityUnitConversionForm>({
  index: new FormControl<number>(uc.index),
  entityIndex: new FormControl<number>(uc.entityIndex),
  unitCode: new FormControl<string>(uc.unitCode, isActivity ? null : [Validators.required]),
  convFactor: new FormControl<number>(uc.convFactor, isActivity ? null : [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotCommaWithNoScaleNoZero)]),
  userCode: new FormControl<string>(uc.userCode),
  changeDate: new FormControl<string>(uc.changeDate),
  isNew: new FormControl<boolean>(isNew),
  isChange: new FormControl<boolean>(false),
  isRemoved: new FormControl<boolean>(false),
  id: new FormControl<number>(uc.id)
});

export const getStringValue = (pv: IPropertyValue): string => {
  if (pv.dataType === EntityPropertyDataTypeEnum.DATA_TYPE_INT) {
    return pv?.valueInt?.toString();
  } else if (pv.dataType === EntityPropertyDataTypeEnum.DATA_TYPE_DOUBLE) {
    return pv?.valueDouble?.toString();
  } else {
    return pv?.valueString;
  }
};

export function validateUnitConversionArray(): ValidatorFn {
  return (formArray: FormArray): { [key: string]: any } | null => {
    const notRemoved = formArray.value.filter(i => i.isRemoved === false);

    const uniqueValues = new Set(notRemoved.map(v => v.unitCode));

    if (uniqueValues.size < notRemoved.length) {
      return { error: 'Unit code already exist!!!' };
    }
    return null;
  };
};

@Component({
  selector: 'app-entity-form',
  templateUrl: './entity-form.component.html',
  styleUrls: ['./../entity-editor.component.css']
})
export class EntityFormComponent extends DialogContentBase implements OnInit, OnDestroy {
  _entity: IEntity;
  _entityGroup: IEntityGroup;
  _entityRelation: IEntityRelation;
  units: IUnit[];
  entityRelationForm: FormGroup;
  disableSubmit: boolean = false;
  fileDownloadMode = FileDownloadMode;
  glulamSpecButtonDisabled: boolean = false;
  entityForm: FormGroup<CreateEntityForm>;
  isNew: boolean;
  isChange: boolean;
  isRemoved: boolean;
  isActivity = false;
  isEntityTypeCodeResource = false;
  isGlulamSpecEnabled = false;

  minusIcon: SVGIcon = minusIcon;
  plusIcon: SVGIcon = plusIcon;


  dimensions: BeamLamminaDimension[] = [];

  private glulamSpecForm: FormGroup<CreateGlulamSpecForm>;
  private readonly destroy = new Subject<void>();

  entityRelations(): FormArray<FormGroup<CreateEntityRelationForm>> {
    return this.entityForm.get('entityRelations') as FormArray;
  }

  glulamSpecs(): FormArray<FormGroup<CreateGlulamSpecRowForm>> {
    return this.glulamSpecForm?.get('specrows') as FormArray;
  }

  newEntityRelation(entityTypeCode): FormGroup {
    return createEntityRelationFormGroup(<IEntityRelation>{
      relation: null,
      direction: EntityRelationDirection.CONSUMES,
      unitCode: 'M3',
      userCode: null,
      inTactical: true,
      inOperative: true,
      isChaining: false
    }, entityTypeCode === 'ACTIVITY', 0, true);
  }

  newUnitConversion(): FormGroup {
    return createFormGroupUC(<IUnitConversion>{
      unitCode: 'SELECT',
      convFactor: null,
      entityIndex: this.data.entity.index,
      index: 0,
      id: 0
    }, true, this.data.entity.unitConversions, false);
  }

  addNewunitConversion() {
    this.unitConversions.push(this.newUnitConversion());
  }

  addNewEntityRelation(entityTypeCode) {
    this.entityRelations().push(this.newEntityRelation(entityTypeCode));
  }

  removeEntityRelation(entityIndex: number) {
    this.entityRelations().at(entityIndex).get('isRemoved').patchValue(true);
    if (this.entityRelations().at(entityIndex).get('isNew').value) {
      this.entityRelations().removeAt(entityIndex);
    }
  }

  removeUnitConversion(Index: number) {
    if (this.unitConversions.at(Index).get('isNew').value) {
      this.unitConversions.removeAt(Index);
    } else {
      this.unitConversions.at(Index).get('isRemoved').patchValue(true);
    }
  }

  buildForm() {
    this.entityForm = new FormGroup<CreateEntityForm>({
      index: new FormControl<number>(this._entity.index, [Validators.required]),
      code: new FormControl<string>(this._entity.code, [Validators.required, Validators.maxLength(100)]),
      baseUnitCode: new FormControl<string>(this._entity.baseUnitCode ?? '', [Validators.required]),
      changeDate: new FormControl<string>(this._entity.changeDate),
      commentId: new FormControl<number>(this._entity.commentId),
      description: new FormControl<string>(this._entity.description, [Validators.required]),
      entityGroupCode: new FormControl<string>(this._entity.entityGroupCode),
      isDynamicActivity: new FormControl<boolean>(this._entity.isDynamicActivity),
      sortOrderIndex: new FormControl<number>(this._entity.sortOrderIndex),
      status: new FormControl<number>(this._entity.status),
      superiorEntityIndex: new FormControl<number>(this._entity.superiorEntityIndex),
      userCode: new FormControl<string>(this._entity.userCode),
      machineId: new FormControl<number>(this._entity.machineId),
      propertyValues: new FormArray(this._entity.propertyValues.map<FormGroup<CreatePropertyValueEntityForm>>(pv => createFormGroupP(pv))),
      entityRelations: new FormArray(this._entity.entityRelations.map<FormGroup<CreateEntityRelationForm>>(enr => createEntityRelationFormGroup(enr, this.isActivity, this.isActivity ? enr.productIndex : enr.activityIndex, false))),
      unitConversions: new FormArray(this._entity.unitConversions.map<FormGroup<CreateEntityUnitConversionForm>>(uc => createFormGroupUC(uc, false, this._entity.unitConversions, this.isActivity)), validateUnitConversionArray()),
      targetMinStock: new FormControl<number>(this._entity.targetMinStock, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)),
      targetMaxStock: new FormControl<number>(this._entity.targetMaxStock, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)),
      batchSize: new FormControl<number>(this._entity.batchSize, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)),
      guid: new FormControl<string>(this._entity.guid),
      beamLamminaDimensionId: new FormControl<number>(this._entity.beamLamminaDimensionId)
    });

    this.buildFormGlue();
  }

  buildFormGlue() {

    var specrows = new FormArray([]);

    const relations = this._entity.entityRelations.filter(er => er.direction === EntityRelationDirection.CONSUMES && er.unitCode !== 'HOUR');

    relations.forEach(er => {
      er.glulamSpec.forEach(g => {
        specrows.push(EntityFormComponent.newLine(g));
      });
    });

    this.glulamSpecForm = new FormGroup<CreateGlulamSpecForm>(
      {
        specrows: specrows,
        hightMin: new FormControl<number>(this._entity.gluSpecHightMin, [Validators.min(1)]),
        hightMax: new FormControl<number>(this._entity.gluSpecHightMax, [Validators.min(1)]),
        topPercentage: new FormControl<number>((this._entity.gluSpecTopPercentage ?? 0.18), []),
        bottomPercentage: new FormControl<number>((this._entity.gluSpecBottomPercentage ?? 0.18), []),
        addedCalcHight: new FormControl<number>(this._entity.gluSpecAddedCalcHight ?? 0, []),
        addedCalcWidth: new FormControl<number>(this._entity.gluSpecAddedCalcWidth ?? 0, [])
      }
      //,[sumHightBelowMax, onlyOneHightDep]
    );
  }

  public static newLine(gs: IGlulamSpec): FormGroup<CreateGlulamSpecRowForm> {
    return new FormGroup<CreateGlulamSpecRowForm>({
      activityIndex: new FormControl<number>(gs.activityIndex),
      productIndex: new FormControl<number>(gs.productIndex, [Validators.required]),
      sequence: new FormControl<number>(gs.sequence),
      numberOfLammellas: new FormControl<number>(gs.numberOfLammellas),
      planingThickness: new FormControl<number>(gs.planingThickness, [Validators.required]),
      maxThickness: new FormControl<number>(0),
      heightDependant: new FormControl<boolean>(gs.heightDependant),
      dryJoint: new FormControl<boolean>(gs.dryJoint),
      turn: new FormControl<boolean>(gs.turn),
      isNew: new FormControl<boolean>(gs.isNew),
      isChanged: new FormControl<boolean>(gs.isChanged),
      isRemoved: new FormControl<boolean>(gs.isRemoved),
      layerType: new FormControl<number>(gs.layerType)
    });
  }

  constructor(@Inject(DialogRef)
  public data: { entity: IEntity, entityGroup: IEntityGroup, isNew: boolean, entityTypeCode: string },
    private readonly unitService: UnitService,
    private readonly entityService: EntityService,
    private readonly poService: ProdOrderService,
    private readonly entityPropertyService: EntityPropertyService,
    private readonly formBuilder: FormBuilder,
    private readonly dialogRef: DialogRef,
    private readonly appNotificationService: AppNotificationService,
    private readonly dimensionService: DimensionService,
  ) {
    super(dialogRef);
    this.entityRelationForm = this.formBuilder.group({
      entityRelations: this.formBuilder.array([])
    });
  }

  ngOnInit(): void {
    this.unitService.query({}).pipe(takeUntil(this.destroy)).subscribe(u => {
      this.units = u;
    });

    this.dimensionService.getBeamLamminaDimentions(true).pipe(takeUntil(this.destroy)).subscribe(d => {
      this.dimensions = d;
    });

    this._entity = this.data.entity;
    this._entityGroup = this.data.entityGroup;
    this.isNew = this.data.isNew;
    this.isActivity = this.data.entityTypeCode.toLocaleLowerCase() === 'ACTIVITY'.toLocaleLowerCase();
    this.isGlulamSpecEnabled = this.data.entityGroup.isGlulamGroup;
    this.buildForm();
  }

  show(c: AbstractControl): boolean {
    if (this.entityForm.get('baseUnitCode').value === c.value.unitCode) {
      c.get('convFactor').patchValue(1);
    }
    return this.entityForm.get('baseUnitCode').value !== c.value.unitCode;
  }

  onSubmit() {
    if (this.validate()) {
      const e: IEntity = <IEntity>{
        index: this.entityForm.value.index ?? 0,
        code: this.entityForm.value.code,
        baseUnitCode: this.entityForm.value.baseUnitCode,
        changeDate: this.entityForm.value.changeDate,
        commentId: this.entityForm.value.commentId,
        description: this.entityForm.value.description,
        entityGroupCode: this.entityForm.value.entityGroupCode,
        sortOrderIndex: this.entityForm.value.sortOrderIndex,
        status: this.entityForm.value.status,
        userCode: this.entityForm.value.userCode,
        machineId: this.isActivity ? this.entityForm.value.machineId : 0,
        targetMinStock: this.entityForm.value.targetMinStock ?? null,
        targetMaxStock: this.entityForm.value.targetMaxStock ?? null,
        batchSize: this.entityForm.value.batchSize ?? null,
        gluSpecHightMin: this.glulamSpecForm?.value.hightMin,
        gluSpecHightMax: this.glulamSpecForm?.value.hightMax,
        gluSpecAddedCalcHight: this.glulamSpecForm?.value.addedCalcHight,
        gluSpecAddedCalcWidth: this.glulamSpecForm?.value.addedCalcWidth,
        gluSpecBottomPercentage: this.glulamSpecForm?.value.bottomPercentage,
        gluSpecTopPercentage: this.glulamSpecForm?.value.topPercentage,
        guid: this.entityForm.value.guid ?? Guid.newGuid(),
        beamLamminaDimensionId: this.entityForm.value.beamLamminaDimensionId,
        propertyValues: this.propertyValues.value.filter(c => {
          return c.value && c.value !== 'null';
        })
          .map<IPropertyValue>(c => {
            return <IPropertyValue>{
              id: c.id,
              entityIndex: this.entityForm.value.index,
              propertyCode: c.propertyCode,
              valueInt: c.dataType === EntityPropertyDataTypeEnum.DATA_TYPE_INT ? +c.value : 0,
              valueDouble: c.dataType === EntityPropertyDataTypeEnum.DATA_TYPE_DOUBLE ? +CommonHelper.replaceCommawithDot(c.value) : 0,
              valueString: c.dataType === EntityPropertyDataTypeEnum.DATA_TYPE_STRING ? c.value : '',
              userCode: 'API',
              changeDate: new Date().toISOString(),
              dataType: c.dataType
            };
          }),
        entityRelations: this.entityForm.value.entityRelations.map<IEntityRelation>(erc => {
          return <IEntityRelation>{
            productIndex: this._entityGroup.entityTypeCode === 'RESOURCE' ? this._entity.index : erc.entityIndex,
            activityIndex: this._entityGroup.entityTypeCode === 'ACTIVITY' ? this._entity.index : erc.entityIndex,
            relation: this.poService.validateQuantityAsPerDirection(Number(CommonHelper.replaceCommawithDot(erc.relation)), Number(erc.direction)),
            direction: erc.direction,
            unitCode: erc.unitCode,
            userCode: erc.userCode,
            ChangeDate: new Date().toISOString(),
            productCode: erc.productCode,
            activityCode: erc.activityCode,
            isNew: erc.isNew,
            isChange: true,
            isRemoved: erc.isRemoved,
            inTactical: erc.inTactical,
            inOperative: erc.inOperative,
            isChaining: erc.isChaining,
            glulamSpec: this.glulamSpecs()?.value.filter(f => f.productIndex === erc.entityIndex)
          };
        }),
        unitConversions: this.unitConversions.controls.map<IUnitConversion>(c => {
          return <IUnitConversion>{
            index: c.value.index ?? 0,
            entityIndex: this._entity.index ? this._entity.index : 0,
            unitCode: c.value.unitCode,
            convFactor: CommonHelper.convertStringQuantityToNumber(c.value.convFactor),
            userCode: 'API',
            changeDate: new Date().toISOString(),
            isNew: this.isNew,
            isChange: true,
            isRemoved: c.value.isRemoved,
            id: c.value.id ?? 0
          };
        })
      };

      this.disableSubmit = true;
      if (e.index === -1) {
        this.entityService.insert(e).pipe(takeUntil(this.destroy)).subscribe({
          next: () => {
            this.disableSubmit = false;
            this.dialogRef.close(true);
          },
          error: () => {
            this.disableSubmit = false;
          }
        });
      } else {
        this.entityService.update(e).pipe(takeUntil(this.destroy)).subscribe({
          next: () => {
            this.disableSubmit = false;
            this.dialogRef.close(true);
          },
          error: () => {
            this.disableSubmit = false;
          }
        });
      }
    }
  }

  get unitConversions() {
    return this.entityForm.get('unitConversions') as FormArray;
  };

  get unitConversionsActive(): AbstractControl[] {
    return (this.unitConversions.controls.filter(c => c.get('isRemoved').value === false));
  };

  get propertyValues(): FormArray<FormGroup<CreatePropertyValueEntityForm>> {
    return this.entityForm.get('propertyValues') as FormArray;
  };

  onCancel() {
    this.dialogRef.close(false);
  }

  openAddEditGlulamSpecModal() {
    if (this.glulamSpecButtonDisabled) {
      return;
    }

    this.glulamSpecButtonDisabled = true;

    const dr = this.entityService.openDialogForGlulamSpec(this.data.entity, this.glulamSpecForm);
    dr.result.pipe(takeUntil(this.destroy)).subscribe((d: any) => {
      if (d instanceof DialogCloseResult) {
        dr.close();
      } else if (d.success) {
        this.glulamSpecForm = d.glulamspec;
      }
      this.glulamSpecButtonDisabled = false;
    });
  }

  private validate(): boolean {
    // Entity min max stock validation
    const minStock = this.entityForm.value.targetMinStock;
    const maxStock = this.entityForm.value.targetMaxStock;

    if (minStock != null) {
      if (maxStock < minStock) {
        this.showMessage('EntityTranslation.MaxStockShouldbeGreater');
        return false;
      } else if (minStock > 0 && maxStock > 0 && maxStock === minStock) {
        this.showMessage('EntityTranslation.MinStockNotEqualToMax');
        return false;
      }
    }
    // Entity relation validation with direction
    const invalidEntityRelationIndex: number[] = [];
    this.entityRelations().controls.forEach((erc, index) => {
      if ((erc.get('direction').value === EntityRelationDirection.CONSUMES) && (Number(CommonHelper.replaceCommawithDot(erc.get('relation').value)) === 0)) {
        invalidEntityRelationIndex.push(index + 1);
      }
    });

    if (invalidEntityRelationIndex.length > 0) {
      this.appNotificationService
        .notifyErrorAppChanel('EntityTranslation.EntityRelationInvalidRelationForConsume'
          , 'Error'
          , { lineNo: invalidEntityRelationIndex.join(', ') });
      return false;
    }

    // Property value data type validation
    const propsWithoutAreValuesValidated = this._entityGroup.entityGroupProperties.filter(x => !x.property?.areValuesValidated);
    if (propsWithoutAreValuesValidated.length > 0) {
      let dataTypes = this.entityPropertyService.getDataTypeMap();

      for (const element of propsWithoutAreValuesValidated) {
        const prop = this.propertyValues.controls.find(control => control.get('propertyCode').value === element.propertyCode);
        const dataType = prop.value.dataType;

        if (prop && this.isInvalidProperty(prop, dataType)) {
          this.appNotificationService.notifyErrorAppChanel('EntityTranslation.InvalidDataType', 'Error', { propertyCode: prop.value.propertyCode, dataType: dataTypes.find(x => x.value == dataType).text });
          return false;
        }
      };
    }
    return true;
  }

  private isInvalidProperty(prop: any, dataType: any): boolean {
    if (prop.value.value !== null && prop.value.value !== 'null' && prop.value.value.trim() !== '') {
      const valueAsNumber = Number(CommonHelper.replaceCommawithDot(prop.value.value));

      if (dataType === EntityPropertyDataTypeEnum.DATA_TYPE_DOUBLE && isNaN(valueAsNumber) || dataType === EntityPropertyDataTypeEnum.DATA_TYPE_INT && !Number.isInteger(valueAsNumber))
        return true;
    }
    return false;
  }

  private showMessage(message: string) {
    this.appNotificationService.notifyErrorAppChanel(message);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}

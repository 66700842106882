import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { State } from '@progress/kendo-data-query';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import {
  IMachineGroupDTO,
  MachineGroupService
} from '@app/core/services/http-services/operative/machine-group.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ICalendarLinesCriteriaInputDTO } from '@app/core/models/CalendarLinesCriteriaInputDTO';
import { IShiftDTO, IShiftAllocationDTO } from '@app/core/models/shift';
import { ScheduleService } from '@app/core/services/http-services/operative/schedule.service';
import { ShiftService } from '@app/core/services/http-services/operative/shift.service';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { CreateMachineGroupForm } from '@app/core/models/forms/operative/machine-group/create-machine-group-form.model';
import { CreateMachineGroupFilterForm } from '@app/core/models/forms/operative/machine-group/create-machine-group-filter-form.model';

marker('MachineGroup.SaveMachineGroupMsg');
marker('MachineGroup.MachineGroupNameAlreadyExistsMsg');
marker('MachineGroup.UpdateMachineGroupMsg');
marker('MachineGroup.MachineGroupDeleteMsg');
marker('MachineGroup.SiteRequired');

const createMachineGroupForm = (p: IMachineGroupDTO, shifts: IShiftDTO[]) =>
  new FormGroup<CreateMachineGroupForm>({
    machineGroupId: new FormControl<number>(p.machineGroupId, [Validators.required]),
    name: new FormControl<string>(p.name, [Validators.required]),
    shifts: new FormControl<IShiftDTO[]>(shifts),
    isDisabledForAutoSheduler: new FormControl<boolean>(p.isDisabledForAutoSheduler),
    preserveOrderAutoSheduler: new FormControl<boolean>(p.preserveOrderAutoSheduler),
    isContinuousPressGroup: new FormControl<boolean>(p.isContinuousPressGroup),
    isStaticalPressGroup: new FormControl<boolean>(p.isStaticalPressGroup)
  });

@Component({
  selector: 'app-machine-group',
  templateUrl: './machine-group.component.html',
  styleUrls: ['./machine-group.component.css']
})
export class MachineGroupComponent implements OnInit {
  state: State = {
    skip: 0,
    take: 30,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  machineGroups: IMachineGroupDTO[] = [];
  itemToRemove: IMachineGroupDTO;
  machinegrouploader: boolean = false;
  isNew: boolean = false;
  shifts: IShiftDTO[] = [];
  formGroup: FormGroup;
  machineGroupFilterForm: FormGroup;
  selectedSiteId: string;
  disableSite:boolean = false;

  private rowIndexN: any;
  private editedRowIndex: number;
  private isEdit: boolean = false;

  @ViewChild('MachineGroupEditorGrid')
  private MachineGroupEditorGrid: GridComponent;

  constructor(
    public machineGroupService: MachineGroupService,
    private notificationService: AppNotificationService,
    private scheduleService: ScheduleService,
    private shiftService: ShiftService,
    private localStorageService:LocalStorageService
  ) { }

  public ngOnInit(): void {
    this.setMachineGroupFilterForm();
    this.loadData();
  }

  private setMachineGroupFilterForm() {
    this.machineGroupFilterForm = new FormGroup<CreateMachineGroupFilterForm>({
      siteId: new FormControl<string>('', [Validators.required])
    });
  }

  private loadData() {
    this.machinegrouploader = true;
    this.machineGroupService.query().subscribe((d) => {
      this.machinegrouploader = false;
      this.machineGroups = d.data;
    });
    this.loadShifts();
  }

  private loadShifts() {
   this.selectedSiteId = this.machineGroupFilterForm.get('siteId').value !== ''
                                ? this.machineGroupFilterForm.get('siteId').value
                                : this.localStorageService.getItem(LocalStorageService.selectedSiteId);

    this.machinegrouploader = true;
    this.shiftService.query().subscribe((d) => {
      this.shifts = this.selectedSiteId ? d.data.filter(x => x.siteId === this.selectedSiteId) : d.data;
      this.machinegrouploader = false;
    });
  }

  editHandler({ sender, rowIndex, dataItem }) {
    const selectedShifts: IShiftDTO[] = [];
    dataItem.shiftAllocations.forEach((x) => {
      selectedShifts.push(<IShiftDTO>{ shiftId: x.shiftId, name: x.shiftName });
    });

    this.formGroup = createMachineGroupForm(dataItem, selectedShifts);
    this.disableSite = true;
    sender.editRow(rowIndex, this.formGroup);
  }

  cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  saveHandler({ sender, rowIndex, formGroup, isNew, dataItem }): void {
    if (this.validate(dataItem, formGroup)) {
      const machineToPerst: IMachineGroupDTO = <IMachineGroupDTO>{
        machineGroupId: formGroup.get('machineGroupId').value,
        name: formGroup.get('name').value,
        siteId: this.machineGroupFilterForm.get('siteId').value,
        isDisabledForAutoSheduler: formGroup.get('isDisabledForAutoSheduler').value
          ? formGroup.get('isDisabledForAutoSheduler').value
          : false,
        preserveOrderAutoSheduler: formGroup.get('preserveOrderAutoSheduler').value
          ? formGroup.get('preserveOrderAutoSheduler').value
          : false,
        isContinuousPressGroup: formGroup.get('isContinuousPressGroup').value
          ? formGroup.get('isContinuousPressGroup').value
          : false,
        isStaticalPressGroup: formGroup.get('isStaticalPressGroup').value
          ? formGroup.get('isStaticalPressGroup').value
          : false,
        shiftAllocations: []
      };

      formGroup.value.shifts.forEach((s) => {
        machineToPerst.shiftAllocations.push(<IShiftAllocationDTO>{ shiftId: s.shiftId, shiftName: s.name });
      });

      if (isNew) {
        this.machineGroupService.insert(machineToPerst).subscribe((m) => {
          this.machineGroups.splice(0, 0, m);
          const temp = this.machineGroups;
          this.machineGroups = [...temp];

          sender.closeRow(rowIndex);
          this.notificationService.notifySucsessAppChanel('MachineGroup.SaveMachineGroupMsg');
        });
      } else {
        this.machineGroupService.update(machineToPerst).subscribe((m) => {
          dataItem.machineGroupId = m.machineGroupId;
          dataItem.name = m.name;
          dataItem.isDisabledForAutoSheduler = m.isDisabledForAutoSheduler;
          dataItem.preserveOrderAutoSheduler = m.preserveOrderAutoSheduler;
          dataItem.isContinuousPressGroup = m.isContinuousPressGroup;
          dataItem.isStaticalPressGroup = m.isStaticalPressGroup;
          dataItem.shiftAllocations = m.shiftAllocations;

          sender.closeRow(rowIndex);
          this.notificationService.notifySucsessAppChanel('MachineGroup.UpdateMachineGroupMsg');
        });
      }
    }
  }

  private validate(dataItem, formGroup): boolean {
    if (
      this.machineGroups.some(
        (item) => item.machineGroupId !== dataItem.machineGroupId && item.name === formGroup.get('name').value && item.siteId === this.selectedSiteId
      )
    ) {
      this.notificationService.notifyErrorAppChanel('MachineGroup.MachineGroupNameAlreadyExistsMsg');
      return false;
    }
    return true;
  }

  removeHandler({ dataItem, isNew, rowIndex }): void {
    this.itemToRemove = dataItem;
    this.rowIndexN = rowIndex;
  }

  confirmRemove(isRemove: boolean) {
    if (isRemove) {
      this.machineGroupService.remove(this.itemToRemove).subscribe(() => {
        const temp = this.machineGroups;
        temp.splice(this.rowIndexN, 1);
        this.machineGroups = [...temp]; // can use .map
        this.notificationService.notifyInfoAppChanel('MachineGroup.MachineGroupDeleteMsg');
      });
    }
    this.itemToRemove = null;
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = null;
    this.formGroup = null;
    this.disableSite = false;
  }

  openCalender(machineGroupId: number) {
    this.scheduleService.getScheduleLinesbyMachinegroupId(machineGroupId).subscribe((m) => {
      const shift = m.scheduleLines
        .map((item) => item.shiftId)
        .filter((value, index, self) => self.indexOf(value) === index);
      const criteria = <ICalendarLinesCriteriaInputDTO>{
        scheduleLines: m.scheduleLines,
        shiftIds: shift
      };
      this.scheduleService.showCalender(criteria, 'App.MachineGroupCalendar');
    });
  }

  addMachineGroupRow() {
    this.isEdit = false;
    this.closeEditor(this.MachineGroupEditorGrid);
    this.formGroup = createMachineGroupForm(
      <IMachineGroupDTO>{
        machineGroupId: 0,
        name: ''
      },
      []
    );
    this.MachineGroupEditorGrid.addRow(this.formGroup);
  }

  getShiftsName(shiftAllocations: IShiftAllocationDTO[]) {
    return shiftAllocations.length === 0 ? '' : shiftAllocations.map((a) => a.shiftName).join(',');
  }

  public toggleIsDisabledForAutoShedulerCheckbox() {
    this.formGroup.patchValue({ isDisabledForAutoSheduler: !this.formGroup.get('isDisabledForAutoSheduler').value });
  }

  public togglePreserveOrderAutoShedulerCheckbox() {
    this.formGroup.patchValue({ preserveOrderAutoSheduler: !this.formGroup.get('preserveOrderAutoSheduler').value });
  }

  public toggleIsContinuousPressGroupCheckbox() {
    this.formGroup.patchValue({ isContinuousPressGroup: !this.formGroup.get('isContinuousPressGroup').value });
  }

  public toggleIsStaticalPressGroupCheckbox() {
    this.formGroup.patchValue({ isStaticalPressGroup: !this.formGroup.get('isStaticalPressGroup').value });
  }

  onSiteSelected() {
    this.loadData();
    if (this.formGroup) {
    this.formGroup.get('shifts').patchValue('');
    }
  }
}

import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EntityService, IEntity } from '@app/core/services/http-services/model/entity.service';

import { Subscription } from 'rxjs';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('App.SelectEntity');

@Component({
  selector: 'app-select-entity',
  templateUrl: './select-entity.component.html',
  styleUrls: ['./select-entity.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectEntityComponent
    }
  ]
})
export class SelectEntityComponent implements ControlValueAccessor, OnDestroy  {
  selectedEntityIndex: number = null;
  loading: boolean = false;

   /* Dynamic width class we can set from calling parent component */
  @Input() entitycombobox_width_class : string = 'entitycombobox-400';
  @Input() label: string = 'App.SelectEntity';
  @Input() showLable: boolean = false;
  @Input() entityTypeCode: 'RESOURCE' | 'ACTIVITY' = 'RESOURCE';
  @Input() getMaterialStandardProperties: boolean = false;
  @Output() selectedEntity: EventEmitter<IEntity> = new EventEmitter<IEntity>();


  private entityTake: number = 20;
  private onChanged: Function = () => {};
  private onTouched: Function = () => {};
  public entities: IEntity[];
  private filterSubscription: Subscription;
  private sub: Subscription;

  constructor(private readonly entityService: EntityService) { }

  private getEntityByIndex(entityIndex: number) {
    this.loading = true;
    this.sub = this.entityService.get(entityIndex, true)
      .subscribe(d => {
        if (d) {
          this.entities = [d];
          this.onSelectionChange(d);
        }
        this.loading = false;
      }
      );
  }

  onSelectionChange(entity: IEntity) {
    this.selectedEntityIndex = (entity !== null) ? entity.index : null;
    this.selectedEntity.emit(entity);
    this.onChanged(this.selectedEntityIndex);
  }

  writeValue(value: number): void {
    this.selectedEntityIndex = value;
    if (value > 0) {
      this.getEntityByIndex(this.selectedEntityIndex);
    }
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public handleFilterChange(searchTerm: string): void {
    if (searchTerm.length >= 3) {
      if (this.filterSubscription) {
        this.filterSubscription.unsubscribe();
      }
      this.loading = true;
      this.filterSubscription = this.entityService.searchEntities(this.entityTypeCode, searchTerm, this.entityTake, this.getMaterialStandardProperties)
        .subscribe(d => {
          this.entities = d;
          this.loading = false;
        }
        );
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

}

<kendo-dialog-titlebar kendo-dialog-title>
  <div class="primaryColor section-header-operative" translate>Operative.Events</div>
</kendo-dialog-titlebar>

<div class="container">
  <div class="popupContent">
    <kendo-grid
      [data]="prodOrderEvents"
      style="width: 100%;height: 100ch;"
      [selectable]="true"
      [reorderable]="true"
      [filterable]="true" [sortable]="true"
      (dataStateChange)="dataStateChange($event)" [filter]="state.filter"
      [pageable]="true"
      [pageSize]="state.take"
      [skip]="state.skip"
      [loading]="loading"
      [size]="'small'"
    >

    <kendo-grid-column field="state" title="{{ 'App.State' | translate }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
    <kendo-grid-string-filter-cell  [showOperators]="false"
                                    [column]="column"
                                    [filter]="filter">
    </kendo-grid-string-filter-cell>
    </ng-template>
    </kendo-grid-column>

    <kendo-grid-column  field="eventDate" title="{{ 'Operative.EventDate' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{dataItem.eventDate | siteDateFormat: true}}
                </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
    <kendo-grid-date-filter-cell    [showOperators]="false"
                                    [column]="column"
                                    [filter]="filter">
    </kendo-grid-date-filter-cell>
    </ng-template>
    </kendo-grid-column>

    </kendo-grid>
  </div>
</div>

<kendo-dialog-actions layout="end">
  <button kendoButton
      type="button"
      themeColor="error"
      (click)="close()"
      class="closebtn"
  >
  {{"App.Close" | translate }}
</button>
</kendo-dialog-actions>


import { Component, Input } from '@angular/core';

@Component({
  selector: '[appSvgCoordinateSytem]',
  template: `
  <svg:defs>
    <svg:pattern id="smallGrid" [attr.width]="gridX" [attr.height]="gridY" patternUnits="userSpaceOnUse">
      <path [attr.d]="gridPath" fill="none" class="glidLine"/>
    </pattern>
    <svg:pattern id="grid" [attr.width]="gridMainX" [attr.height]="gridMainY" patternUnits="userSpaceOnUse">
      <svg:rect [attr.width]="gridMainX" [attr.height]="gridMainY" fill="url(#smallGrid)"/>
      <svg:path [attr.d]="gridMainPath" fill="none" class="glidLineMain"/>
    </pattern>
  </defs>
  <svg:rect width="100%" height="100%" fill="url(#grid)" />`,
  styles: [
    '.glidLine { stroke: #000000; stroke-width: 5;}',
    '.glidLineMain { stroke: #000000; stroke-width: 10;}'
  ]
})
export class SvgCoordinateSytemComponent {

  @Input() scaleFactorY:number = 4;
  @Input() unitsMainY:number = 60 * 24;
  @Input() smallFractionsY:number = 24;

  @Input() scaleFactorX:number = 1;
  @Input() unitsMainX:number = 5000;
  @Input() smallFractionsX:number = 5;

  gridMainX:number = this.unitsMainX * this.scaleFactorX;
  gridX:number = this.gridMainX / this.smallFractionsX;

  gridMainY:number = this.unitsMainY * this.scaleFactorY;
  gridY:number = this.gridMainY / this.smallFractionsY;


  gridMainPath:string = `M ${this.gridMainX} 0 L 0 0 0 ${this.gridMainY}`
  gridPath:string = `M ${this.gridX} 0 L 0 0 0 ${this.gridY}`
}

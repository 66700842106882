// import { Component, OnInit, ViewChild } from '@angular/core';

import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SVGIcon, printIcon } from '@progress/kendo-svg-icons';

import { ActivatedRoute } from '@angular/router';
import { BeamMaterialService } from '../services/beam-material.service';
import { IPrintBeamMaterial } from '@app/core/models/print-beammaterial.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { exportPDF } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';

@Component({
  selector: 'app-glueplan-print-third',
  templateUrl: './glueplan-print-third.component.html',
  styleUrls: ['./glueplan-print-third.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GlueplanPrintThirdComponent implements OnInit {
   @ViewChild('pdfs', { static: false }) pdfs: ElementRef;
   beamMaterials:IPrintBeamMaterial[];

   public printIcon:SVGIcon = printIcon;

   constructor(private route: ActivatedRoute, public beamService: BeamMaterialService, private spinner: NgxSpinnerService) {
     this.route.params.subscribe(params => {
       const gpID = params.id;

       this.beamService.getBeamMaterialByGlueplanId(gpID).subscribe((glueSets) => {
         this.beamMaterials = glueSets;
         this.hideLoader();
       });
     });
   }

   ngOnInit(): void {
     this.showLoader();
   }

   onClick(pdf) {
    pdf.paperSize = ['8cm', '5cm'];
    pdf.export().then((pdfs) => {
      exportPDF(pdfs).then((base64Data) => {
        window.open().document.write("<embed width='100%' height='100%' src='" + base64Data + "'/>");
      });
    });
   }

   private hideLoader() {
     this.spinner.hide('glueplanLoader');
   }

   private showLoader() {
     this.spinner.show('glueplanLoader');
   }
}

import { Component, OnInit } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { IManualBeamMaterialCreateDeamndForm, IManualBeamMaterialCreateForm } from '@app/core/models/forms/gluelam/manual-beam-material-create-form.model';

import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { Demandline } from '@app/core/models/demand-line.model';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker([
  'App.Cancel',
  'Beams.ManualBeamMaterial',
  'App.Add'
]);

@Component({
  selector: 'app-manual-beam-material-dialog',
  templateUrl: './manual-beam-material-dialog.component.html',
  styleUrls: ['./manual-beam-material-dialog.component.css']
})
export class ManualBeamMaterialDialogComponent extends DialogContentBase implements OnInit {

  form:FormGroup<IManualBeamMaterialCreateForm> = null;

  public demandlines:Demandline[] = [];

  constructor(
    private readonly dialogRef: DialogRef,
    public notificationService: AppNotificationService) {
    super(dialogRef);
  }

  ngOnInit(): void {
    console.log(this.demandlines)
  }

  setupForm(demandLine:Demandline[]){
    this.demandlines = demandLine;

    const demands = new FormArray<FormGroup<IManualBeamMaterialCreateDeamndForm>>([]);

    let beamMaterialTypeId = demandLine[0].beamMaterialType.beamMaterialTypeID


    let mateialIndex = null;

    for(let d of this.demandlines){
      mateialIndex = d.materialIndex;
      demands.push(new FormGroup<IManualBeamMaterialCreateDeamndForm>({
        demandId: new FormControl<number>(d.BeamOrderRowID, [ Validators.required ]),
        qty: new FormControl<number>(1, [ Validators.required ]),
      }))
    }

    this.form = new FormGroup<IManualBeamMaterialCreateForm>({
      length: new FormControl<number>(15695, [ Validators.required ]),
      beamMaterialTypeId: new FormControl<number>(beamMaterialTypeId, [ Validators.required, Validators.min(2) ]),
      materialId: new FormControl<number>(mateialIndex, [ Validators.required ]),
      numberOfLaminas: new FormControl<number>(demandLine[0].NumberOfLaminas, [ Validators.required ]),
      planingThickness: new FormControl<number>(40.5, [ Validators.required ]),
      demands: demands
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addManualBeamMaterial() {
    this.dialogRef.close(this.form);
  }

  getDemandFormArray():FormArray{
    return this.form.controls["demands"] as FormArray
  }

  getDemandFormControl(index:number, name: string):FormControl{
    const a = this.getDemandFormArray();

    const fg = a.at(index) as FormGroup;

    return fg.controls[name] as FormControl;
  }
}

<kendo-dialog-titlebar kendo-dialog-title>
    {{"Beams.ManualBeamMaterial" | translate}}
</kendo-dialog-titlebar>
<form
    [formGroup]="form"
    *ngIf="form"
>
    <div class="row">
        <kendo-grid [data]="demandlines">
            <kendo-grid-column
                field="orderID"
                title="OrderID"
            ></kendo-grid-column>
            <kendo-grid-column
                field="orderRowID"
                title="OrderID"
            ></kendo-grid-column>
            <kendo-grid-column
                field="length"
                title="Length"
            ></kendo-grid-column>
            <kendo-grid-column
                field="width"
                title="width"
            ></kendo-grid-column>
            <kendo-grid-column
                field="thickness"
                title="thickness"
            ></kendo-grid-column>
            <kendo-grid-column
                field="hight"
                title="hight"
            ></kendo-grid-column>
            <kendo-grid-column
                field="NumberOfLaminas"
                title="NumberOfLaminas"
            ></kendo-grid-column>
            <kendo-grid-column
                field="DeafultLamminaPlaneThicknes"
                title="DeafultLamminaPlaneThicknes"
            ></kendo-grid-column>
            <kendo-grid-column
                field="qty"
                title="Qty"
            >
                <ng-template
                    kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex"
                >
                    <kendo-numerictextbox [formControl]="getDemandFormControl(rowIndex, 'qty')"></kendo-numerictextbox>
                </ng-template>
                >
            </kendo-grid-column>
        </kendo-grid>
    </div>
    <div class="row">
        <kendo-formfield>
            <kendo-label>Lenght</kendo-label>
            <kendo-numerictextbox formControlName="length"></kendo-numerictextbox>
        </kendo-formfield>
        <app-select-entity-simple [showLable]="true" entityTypeCode="RESOURCE" [isGlulamGroup]="true" formControlName="materialId"></app-select-entity-simple>
        <kendo-formfield>
            <kendo-label>numberOfLaminas</kendo-label>
            <kendo-numerictextbox formControlName="numberOfLaminas"></kendo-numerictextbox>
        </kendo-formfield>
        <app-select-beam-material-type formControlName="beamMaterialTypeId"></app-select-beam-material-type>
    </div>
    <div>{{ form.value | json}}</div>



</form>
<kendo-dialog-actions>
    <div display="flex-1"></div>
    <button
        kendoButton
        type="submit"
        themeColor="primary"
        [disabled]="!form.valid"
        (click)="addManualBeamMaterial()"
    >
        {{'App.Add' | translate}}
    </button>
    <button
        kendoButton
        themeColor="error"
        type="button"
        (click)="closeDialog()"
    >
        {{'App.Close' | translate}}
    </button>
</kendo-dialog-actions>

<kendo-dialog-titlebar kendo-dialog-title>
  <div translate> Operative.CreateMultiStepPO </div>
</kendo-dialog-titlebar>

<div class="container">
  <form
    [formGroup]="form"
    style="height: 98%;">
    <div
        class="col"
        style="gap: 20px;">
        <kendo-formfield>
            <div>
                <kendo-label translate>Operative.NumberOfSteps</kendo-label>
                <kendo-numerictextbox
                    format="n"
                    formControlName="numberOfSteps"
                    [min]="1"
                    [max]="5">
                </kendo-numerictextbox>
            </div>
        </kendo-formfield>
        <div class="col">
            <table *ngIf="prod">
                <thead>
                    <th translate>App.Material</th>
                    <th translate>App.Activity</th>
                    <th></th>
                    <th translate>App.Quantity</th>
                    <th translate>Operative.ReportedQty</th>
                    <th translate>EntityTranslation.BatchSize</th>
                    <th translate>Operative.NoProdOrders</th>
                </thead>
                <tbody>
                    <tr *ngFor="let ad of prod; index as i">
                        <td>{{ad.productDesc}}</td>
                        <td>
                            <kendo-dropdownlist
                                [data]="ad.activitys"
                                textField="description"
                                valueField="index"
                                [valuePrimitive]="true"
                                [formControl]="getControl(i,'activityIndex')"
                                [defaultItem]="{ description: 'n/a', index: null}">
                            </kendo-dropdownlist>
                        </td>
                        <td>
                            <kendo-svg-icon
                                [icon]="warningTriangleIcon"
                                themeColor="warning"
                                *ngIf="ad.activitys.length < 1">
                            </kendo-svg-icon>
                        </td>
                        <td class="numberCol">
                            {{ getQty(ad.productIndex) | number:'0.3' }}
                        </td>
                        <td class="numberCol">
                            {{ getReportedQty(ad.productIndex) | number:'0.3' }}
                        </td>
                        <td class="numberCol">
                            <span *ngIf="getBatchSize(i, ad.activitys)">
                                {{ getBatchSize(i, ad.activitys) ?? 0 | number:'0.2' }}
                            </span>
                            <div *ngIf="!getBatchSize(i, ad.activitys)">n/a</div>
                        </td>
                        <td class="numberCol">
                            {{ calcNoProdOrders(getQty(ad.productIndex), getBatchSize(i, ad.activitys))| number:'0.0' }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style="align-items: center;">
              <kendo-loader
              style="flex-grow: 1; margin-top: 50px;"
              *ngIf="!prod"></kendo-loader>
            </div>

      </div>
    </div>
  </form>
</div>

<kendo-dialog-actions layout="end">
  <div>
    <input class="k-checkbox group-check" (click)="toggleUseReportedQtyCheckbox()" [checked]="useReportedQty" type="checkbox" kendoCheckBox />
    <kendo-label translate>Operative.UseReportedQty</kendo-label>
  </div>
  <div class="flex-grow-8"></div>
  <div class="btn-holder">
    <button
        kendoButton
        type="button"
        class="closebtn"
        themeColor="error"
        (click)="close()">
        {{"App.Close" | translate }}
    </button>
    <button
        kendoButton
        themeColor="primary"
        type="submit"
        (click)="save()"
        class="float-right"
        [disabled]="disableGenerateBtn">
        {{"Operative.Generate" | translate}}
    </button>
  </div>
  <div style="align-items: center;">
    <kendo-loader *ngIf="loading"></kendo-loader>
  </div>
</kendo-dialog-actions>


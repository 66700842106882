<div class="col statical-plan-view">
  <kendo-card width="600px" (click)="updateSelection()" (contextmenu)="updateSelection()">
    <ngx-spinner
        bdColor="rgb(255 255 255 / 59%)"
        size="medium" color="rgb(254 112 33)"
        type="ball-scale-multiple"
        [fullScreen]="false"
        [name]="gluelamSpinner">
      <p> {{ "GluePlan.Loading" | translate}}</p>
    </ngx-spinner>
    <ngx-spinner
      bdColor="rgb(255 255 255 / 59%)"
      size="medium" color="rgb(254 112 33)"
      type="ball-clip-rotate-multiple"
      [fullScreen]="false"
      [name]="glueSetSpinner">
      <p>{{ "GluePlan.Loading" | translate}} </p>
    </ngx-spinner>
    <kendo-card-header>
      <div class="row">
        <div class="col" class="k-flex-1">
          <h3 kendoCardTitle>{{ plan.name }} - {{plan.gluingPlanID}}</h3>
          <h4 kendoCardSubtitle>{{ plan.plannedExecutionStartDate | date}} - {{ plan.plannedExecutionEndDate | date}}</h4>
          <div class="k-flex-1"></div>
          <div class="row">
            <button kendoButton [svgIcon]="printIcon" themeColor="secondary" [routerLink]="['/glulam/glueplan-print-third', plan.gluingPlanID]">
            </button>
            <div class="k-flex-1"></div>
            <button kendoButton [svgIcon]="trashIcon" themeColor="error" (click)="delete()">
            </button>
            <button kendoButton [svgIcon]="pencilIcon" themeColor="primary" (click)="edit()">
            </button>
            <button kendoButton [svgIcon]="saveIcon" themeColor="secondary"
            [disabled]="!plan || plan?.glueSetStateId >= glueSetStateEnum.PLANNED || disableSaveGluesetsButton()"
            (click)="saveGluePlan()"></button>
          </div>
        </div>
        <app-gluing-plan-layered-view [glueSets]="glueSets"
          [machine]="machine"></app-gluing-plan-layered-view>
      </div>
    </kendo-card-header>
    <kendo-card-body>
      <app-beam-material-gluset-statical [id]="bmgs.beamMaterialGlueSetID"
        *ngFor="let bmgs of glueSets; let i = index" [plan]="plan" [glueSet]="bmgs" [gsWidth]="gsWidth"
        [lamminaDim]="dimension" [isOperator]="false" [machine]="machine" (calculateUnits)="calculateUnits()"
        (deleteBeamMaterialGlueSet)="deleteGluesetsFromGluingPlan($event, i)" (updateGluePlan)="updateGluePlan()"
        (addEmptyBmgs)="AddEmptyBmgs()">
      </app-beam-material-gluset-statical>
    </kendo-card-body>
  </kendo-card>
</div>
<app-confirmation-dialog *ngIf="isRemoveGluePlan" [msg]="'GluePlan.GluePlanConfirmDelete'"
  (conformationDialogCloseEvent)="confirmRemove($event)">
</app-confirmation-dialog>

import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AppLocale, IUserRoleRequestDTO, OptinexUserDTO, UserService } from '@app/core/services/http-services/common/user.service';
import { SVGIcon, checkIcon, saveIcon, xIcon, lockIcon } from '@progress/kendo-svg-icons';

import { Component } from '@angular/core';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { IUserForm } from '@app/core/models/forms/app/user-form';
import { MachineService } from '@app/core/services/http-services/operative/machine.service';
import { SiteQueryService } from '@app/core/services/http-services/app/site-query.service';
import { firstValueFrom } from 'rxjs';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';


const validator: ValidatorFn = (formArray: AbstractControl) => {
  if (formArray instanceof FormControl) {


    if (!formArray.value) {
      return { required: true };
    }

    return formArray.value.length > 0 ? null : { required: true };
  }
};

const defaultSiteIsInSites: ValidatorFn = (formArray: AbstractControl) => {

    let sites = formArray.get('sites').value;

    if(!sites){
      return null;
    }

    let defaultSite = formArray.get('defaultSite').value;

    if(!defaultSite){
      return null;
    }

    if(sites.find(s => s === defaultSite) == undefined){
      return { defaultSiteIsInSites: true}
    }

    return null;
};

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent extends DialogContentBase {

  private singleTenantAdmin: string = 'SingleTenantAdmin';
  private developer: string = 'Developer';
  private Planner: string = 'Planner';
  private operator: string = 'Operator';

  public from: FormGroup<IUserForm> = null;

  applicationUser: OptinexUserDTO;
  user: OptinexUserDTO;
  sites: any;
  mashines: IMachineDTO[];
  locales: AppLocale[]

  checkIcon: SVGIcon = checkIcon;
  xIcon: SVGIcon = xIcon;
  saveIcon: SVGIcon = saveIcon;
  lockIcon: SVGIcon = lockIcon;

  plannerLoading: boolean = false;
  operatorLoading: boolean = false;
  singleTenantLoading: boolean = false;
  developerLoading: boolean = false;
  isNew: boolean = false;


  public constructor(
    dialog: DialogRef,
    public userService: UserService,
    public siteQueryService: SiteQueryService,
    public machineService: MachineService,
    public notificationService: AppNotificationService

  ) {

    super(dialog)

    this.locales = userService.locales.filter(l => l.id !== UserService.emptyLlocaleId);
  }

  async setupForm(eventUser: OptinexUserDTO, isNew: boolean) {

    this.applicationUser = await this.userService.getUser();

    this.user = eventUser;

    this.isNew = isNew;

    if (!this.user) {
      this.dialog.close();
    }

    this.sites = this.siteQueryService;

    this.siteQueryService.query();

    this.mashines = (await firstValueFrom(this.machineService.query())).data

    var sideIds = this.user.userSites.map<string>(s => s.siteId)
    var machinesIds = this.user.userMachines.map<number>(s => s.machineId)

    let defaultSite = this.user.userSites.find(s => s.isDefaultSite)

    this.from = new FormGroup<IUserForm>({
      userId: new FormControl<string>(eventUser.userId,[Validators.required]),
      entraId: new FormControl<string>(eventUser.entraId, [Validators.required]),
      machines: new FormControl(machinesIds),
      sites: new FormControl<string[]>(sideIds, [validator, Validators.required]),
      languageCode: new FormControl<string>(eventUser.languageCode, [Validators.required]),
      defaultSite: new FormControl<string>(defaultSite?.siteId, [Validators.required])
    }, [defaultSiteIsInSites])
  }

  async toggleSingleTenantAdminRole() {

    if (this.singleTenantLoading || !this.applicationUser.isCrossTenantAdmin) {
      return true
    }

    this.singleTenantLoading = true;

    const req: IUserRoleRequestDTO = <IUserRoleRequestDTO>{
      role: this.singleTenantAdmin,
      userId: this.user.entraId
    }

    if (this.user.isSingleTenantAdmin) {
      await firstValueFrom(this.userService.revokeMemberRole(req));
      this.user.isSingleTenantAdmin = false;
    } else {
      await firstValueFrom(this.userService.addMemberToRole(req));
      this.user.isSingleTenantAdmin = true;
    }

    this.singleTenantLoading = false;
  }

  async toggleDeveloperRole() {

    if (this.developerLoading || !this.applicationUser.isCrossTenantAdmin) {
      return true
    }

    this.developerLoading = true;

    const req: IUserRoleRequestDTO = <IUserRoleRequestDTO>{
      role: this.developer,
      userId: this.user.entraId
    }

    if (this.user.isDeveloper) {
      await firstValueFrom(this.userService.revokeMemberRole(req));
      this.user.isDeveloper = false;
    } else {
      await firstValueFrom(this.userService.addMemberToRole(req));
      this.user.isDeveloper = true;
    }

    this.developerLoading = false;
  }

  async togglePlannerRole() {

    if (this.plannerLoading) {
      return true
    }

    this.plannerLoading = true;

    const req: IUserRoleRequestDTO = <IUserRoleRequestDTO>{
      role: this.Planner,
      userId: this.user.entraId
    }

    if (this.user.isPlanner) {
      await firstValueFrom(this.userService.revokeMemberRole(req));
      this.user.isPlanner = false;
    } else {
      await firstValueFrom(this.userService.addMemberToRole(req));
      this.user.isPlanner = true;
    }

    this.plannerLoading = false;
  }

  onSave() {
    this.user.machinesIds = this.from.value.machines;
    this.user.sitesIds = this.from.value.sites;
    this.user.defaultSite = this.from.value.defaultSite;
    this.user.languageCode = this.from.value.languageCode;

    if (this.isNew === true) {
      this.userService.save(this.user).subscribe(() => {
        this.notificationService.notifySucsessAppChanel('User added successfully');
        this.dialog.close();
      });
    } else {
      this.userService.update(this.user.entraId, this.user).subscribe(() => {
        this.notificationService.notifySucsessAppChanel('User updated successfully');
        this.dialog.close();
      });
    }
  }

  getIcon(isAuth: boolean): SVGIcon {
    if (!this.applicationUser.isGlobalAdmin) {
      return this.lockIcon;
    }
    return isAuth ? checkIcon : xIcon
  }
}


<kendo-dialog-titlebar kendo-dialog-title>
  <div class="primaryColor section-header-operative" translate>Operative.Title</div>
</kendo-dialog-titlebar>

<div class="container">
  <div class="popupContent">
    <div class="width-40">
      <div>
        <form [formGroup]="form">
          <div>
            <div class="width-full">
              <div class="row">
                <div class="width-60">
                  <div class="col">
                    <div class="width-full">
                      <h3 translate>Operative.ActivityDataHeader</h3>
                      <div class="width-full">
                        <div class="row">
                          <div class="width-80">
                            <div class="col">
                              <app-select-entity class="entitycombobox" formControlName="productIndex"
                                [entityTypeCode]="'RESOURCE'" [showLable]="true" [label]="'Operative.SelectMaterial'">
                              </app-select-entity>
                            </div>
                          </div>
                          <div class="width-20">
                            <div class="col">
                              <app-select-entity-relation-direction formControlName="direction"
                                                                    class="direction">
                              </app-select-entity-relation-direction>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="width-full">
                        <div class="col">
                          <div class="col; activity">
                            <kendo-formfield>
                              <kendo-label translate>App.Activity</kendo-label>
                              <div class="row">
                                <kendo-dropdownlist formControlName="activityIndex" [data]="prodModelActivity"
                                  [valueField]="'index'" [textField]="'description'" [valuePrimitive]="true"
                                  [defaultItem]="0">
                                </kendo-dropdownlist>
                                <button kendoButton (click)="openPatternView()" [disabled]="viewingSawPatternDisabled" themeColor="primary">
                                  {{ 'Operative.ViewSawPattern' | translate }}
                                </button>
                                <app-file-upload [container]="'activity-attachments'" [folder]="selectedeActivity?.guid"
                                  [canEdit]="false" [downloadMode]="fileDownloadMode.AllFiles">
                                </app-file-upload>
                              </div>
                            </kendo-formfield>
                          </div>
                        </div>

                        <div class="width-full">
                          <app-machine-selector-dropdown
                            [machineGroupId]="selectedeActivity?.entityGroup?.machineGroupId"
                            [loadMachinesOnInit] = "false"
                            [widthClass]="'width100Percentage'"
                            formControlName="machineId"
                            class="machine-selector">
                          </app-machine-selector-dropdown>
                        </div>

                        <div class="row">
                          <div class="width-full">
                            <div class="row">
                              <div class="width-25">
                                <div class="flex-item">
                                  <kendo-formfield>
                                    <kendo-label translate>App.Quantity</kendo-label>
                                    <kendo-textbox formControlName="baseQuantity" [ngModel]="form.get('baseQuantity').value | ReplaceDotToCommaPipe"
                                      (blur)="qtyChange(this.form.get('entityRelationProductIndex').value, this.form.get('baseUnitCode').value)"
                                      [disabled]="isEditQtyAndUnit">
                                    </kendo-textbox>
                                    <kendo-formerror translate> Operative.CreateProdOrderInvalidQuantityErrMsg
                                    </kendo-formerror>
                                  </kendo-formfield>
                                </div>
                              </div>
                              <div class="width-20">
                                <app-select-unit formControlName="baseUnitCode" [showLable]="true" [label]="'AoT.UOM'" [disableTextbox]="isEditQtyAndUnit"
                                  class="width-dropdown"
                                  (onUnitChange)="onUnitChangeForPO($event, this.form.get('entityRelationProductIndex').value)">
                                </app-select-unit>
                              </div>
                              <div class="width-30">
                                <div class="flex-item" *ngIf="isNumber(data.input.quantity)">
                                  <kendo-formfield>
                                    <kendo-label translate>Operative.BaseUnit</kendo-label>
                                    <kendo-textbox  formControlName="quantity"
                                      [ngModel]="form.get('quantity').value | ReplaceDotToCommaPipe">
                                      <ng-template kendoTextBoxSuffixTemplate>{{ form.value.unitCode }}</ng-template>
                                    </kendo-textbox>
                                  </kendo-formfield>
                                </div>
                              </div>
                              <div class="width-25">
                                <div class="flex-item">
                                  <kendo-formfield>
                                    <kendo-label translate>Operative.Duration</kendo-label>
                                    <kendo-textbox formControlName="duration">
                                      <ng-template kendoTextBoxSuffixTemplate>m</ng-template>
                                    </kendo-textbox>
                                    <kendo-formerror
                                      translate>Operative.CreateProdOrderInvalidDurationErrMsg</kendo-formerror>
                                  </kendo-formfield>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="width-40">
                  <div class="col">
                    <div class="row">
                      <div class="width-full">
                        <h3 translate>Operative.Instruction</h3>
                        <kendo-formfield>
                          <kendo-textarea formControlName="instruction" [rows]="10" class="width-full"></kendo-textarea>
                        </kendo-formfield>
                      </div>
                    </div>
                    <div class="row">
                      <div>
                        <div class="row control-group">
                          <kendo-label translate> Operative.EmptyStock </kendo-label>
                          <kendo-formfield>
                            <kendo-switch formControlName="isEmptyStock" [disabled]="disabledEmptyStock" color="primary"
                              class="margin-empty-stock"> </kendo-switch>
                          </kendo-formfield>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="overflow-y">
              <div>
                <h3 translate>Operative.ProductionOrderPreviewHeader</h3>
              </div>
              <div class="entityRelation">
                <table class="width-full">
                  <thead>
                    <tr>
                      <th translate>Operative.Main</th>
                      <th translate>EntityTranslation.Description</th>
                      <th translate>Operative.DirectionSymbol</th>
                      <th translate>Operative.Relation<br>Operative.BaseUnitTitle</th>
                      <th translate>Operative.PlannedQty</th>
                      <th translate>App.UOM</th>
                      <th translate><kendo-svg-icon [icon]="linkIcon" themeColor="info"></kendo-svg-icon></th>
                      <th translate>Operative.Forecast<br>Operative.BaseUnitTitle</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let entityRelation of activityEntityRelations">
                      <td>
                        <input type="radio" kendoRadioButton [value]="entityRelation.productIndex" (input)="onMainLineChange()"
                          formControlName="entityRelationProductIndex" />
                      </td>
                      <td>
                        {{ entityRelation?.product?.description ?? 'UNKNNOWN' }}
                      </td>
                      <td translate>
                        <kendo-svg-icon [icon]="plusIcon" themeColor="primary" *ngIf="entityRelation.direction === 0"></kendo-svg-icon>
                        <kendo-svg-icon [icon]="minusIcon" themeColor="primary" *ngIf="entityRelation.direction != 0"></kendo-svg-icon>
                      </td>
                      <td class="text-align-right">
                        {{ entityRelation.relation.toFixed(4) | number }}
                      </td>
                      <td>
                        <div class="text-align-center" *ngIf="isEditQtyAndUnit">
                          <input class="padding-qty" type="decimal" format="n" [value]="entityRelation.plannedQty | number"
                            (input)="onChangePlannedQty($event, entityRelation)" />
                        </div>
                        <div class="text-align-right" *ngIf="!isEditQtyAndUnit">
                          {{ setBaseQtyOrPlannedQty(entityRelation) | number }}
                        </div>
                      </td>
                      <td>
                        <div *ngIf="isEditQtyAndUnit">
                          <app-select-unit class="width-dropdown"
                            (onUnitChange)="onUnitChangeForPOLine($event, entityRelation)"
                            [selectedUnitCodeInput]="entityRelation.unitCode"></app-select-unit>
                        </div>
                        <div *ngIf="!isEditQtyAndUnit">
                          {{form.value.entityRelationProductIndex == entityRelation.productIndex
                          ? form.value.baseUnitCode : entityRelation.unitCode ?? 0}}
                        </div>
                      </td>
                      <td>
                        <kendo-svg-icon [icon]="linkIcon" themeColor="info" *ngIf="entityRelation.isChaining"></kendo-svg-icon>
                        <kendo-svg-icon [icon]="unlinkIcon" themeColor="info" *ngIf="!entityRelation.isChaining"></kendo-svg-icon>
                      </td>
                      <td class="text-align-right">
                        <span *ngIf="!entityRelation?.forecastVolume && entityRelation.forecastVolume !== 0"><kendo-loader
                            size="small"></kendo-loader>{{ entityRelation?.forecastVolume }}</span>
                        <span *ngIf="entityRelation?.forecastVolume || entityRelation.forecastVolume === 0">{{
                          entityRelation.forecastVolume.toFixed(3) ?? 0 | number }}</span>
                      </td>
                    </tr>
                    <tr *ngIf="activityEntityRelations?.length < 1">
                      <td colspan="6" class="text-align-center" translate>Operative.NoRelations</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="schedulerSection">
      <h3 class="float-left" translate>Operative.Scheduling</h3>
      <div class="scheduler-section-div">
        <mbsc-eventcalendar [data]="myEvents" [resources]="myResources" [options]="eventcalendarOptions" [firstDay]="1"
          [colors]="colors" [selectedDate]="selectedDate" [view]="{
            schedule: { type: scheduleType, timeCellStep: slotDuration, timeLabelStep: slotDuration }
          }" [headerTemplate]="TimeSlots" (onPageLoading)="onPageLoading($event)">
          <ng-template #TimeSlots>
            <div class="cal-nav">
              <mbsc-calendar-nav class="md-timezone-nav"></mbsc-calendar-nav>
            </div>
            <div class="slot-section">
              <app-mbsc-calendar-slot-selection (slotTime)="getSlotDuration($event)"></app-mbsc-calendar-slot-selection>
              <mbsc-calendar-prev class="md-work-week-prev"></mbsc-calendar-prev>
              <mbsc-calendar-today class="md-work-week-today"></mbsc-calendar-today>
              <mbsc-calendar-next class="md-work-week-next"></mbsc-calendar-next>
            </div>
          </ng-template>
        </mbsc-eventcalendar>
      </div>
    </div>
  </div>
</div>

<kendo-dialog-actions layout="end">
  <button kendoButton themeColor="primary" (click)="onUnlockRelations()">
    {{ 'Operative.UnlockRelations' | translate }}
  </button>

  <div class="flex-grow"></div>

  <button kendoButton themeColor="error" type="button" class="closebtn" (click)="close()">
    {{ 'App.Close' | translate }}
  </button>

  <button kendoButton themeColor="primary" type="submit" (click)="onCreateChain()" [disabled]="!form.valid || !checkChainingExists">
    {{ 'App.CreateChain' | translate }}
  </button>

  <button kendoButton themeColor="primary" type="submit" [disabled]="!form.valid" (click)="onQuickCreate()">
    {{ 'App.QuickCreate' | translate }}
  </button>

  <button kendoButton themeColor="primary" type="submit" [disabled]="!form.valid" (click)="onCreate()">
    {{ 'App.Create' | translate }}
  </button>
</kendo-dialog-actions>

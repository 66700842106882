import { HttpClient, HttpParams } from '@angular/common/http';

import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { IBeamMaterialType } from './glue-set.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable()
export class BeamMaterialTypeService {
  constructor(
    private conf: ConfigurationService,
    private http: HttpClient
  ) {
  }

  get(id: number): Observable<IBeamMaterialType> {
    return this.http.get<IBeamMaterialType>(`${this.conf.baseApiUrl}BeamMaterialType/${id}`);
  }

  query(): Observable<IBeamMaterialType[]> {
    return this.http.get<IBeamMaterialType[]>(`${this.conf.baseApiUrl}BeamMaterialType`).pipe(share());
  }
}
